import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useNotificationStore = defineStore({
    id: 'notification',
    state: () => ({
      allNotifications: [],
      notifyList: [],
      unreadCount: 0,
      notifyLastPage: 1,
      notifyCurrentPage: 1,
      notifyPerPage: 10,
      isLoading: false,
      isConectNotifications: false
    }),
    getters: {
      preparedNotification: (state) => state.allNotifications.map(notify => {
        return {
          id: notify.id.toString(),
          read: notify.read,
          type: notify.kind,
          updatedAt: notify.updated_at, 
          body: notify.message
        }
      })
    },
    actions: {
      setNotificationDefault() {
        this.allNotifications = []
        this.unreadCount = 0

      },
      async getNotification(currentPerPage, currentPage, isSocket) {
        let perPage = currentPerPage || this.notifyPerPage;
        let page = currentPage || this.notifyCurrentPage
        this.isLoading = true;
        if (page > this.notifyLastPage) {
          this.isLoading = false;
          return;
        }
        axios
          .get('api/v1/notifications', { params: { page: page, per: perPage }})
          .then((response) => {
            if (response.status === 200 && response.data) {
              if(isSocket) {
                this.allNotifications = response.data.data.concat(this.allNotifications) 
                isSocket = false
              }else {
                this.allNotifications = this.allNotifications.concat(response.data.data)
              }
              this.notifyCurrentPage = response.data.current_page
              this.notifyLastPage = response.data.last_page;
            } else {
              console.log(response);
              toast.error('Произошла ошибка');
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      async getNotificationUnreadCount() {
        this.isLoading = true
        axios
          .get('api/v1/notifications/unread_count')
          .then((response) => {
            if (response.status === 200 && response.data) {
              this.unreadCount = response.data.unread_count || 0
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      },
      async readNotification(notifyIds) {
        let ids = notifyIds
        this.isLoading = true
        axios
          .post('api/v1/notifications/read', { ids: ids})
          .then((response) => {
            if (response.status === 200) {
              this.allNotifications.filter(element => ids.includes(element.id.toString())).forEach(element => {
                element.read = true
              this.getNotificationUnreadCount()
              })   
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
})