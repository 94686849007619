import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({ 
    login: '',
    email: '',
    password: '',
    newPassword: '',
    newRepeatPassword: '',
    codeTwoFactorAuth: '',
    twoFactorProfileId: '',
    isNeedSendCode: false,
    resetToken: '',
    isLoading: false
  }),
  getters: {
  },
  actions: {
    setAuthDefault() {
      this.login = ''
      this.password = ''
      this.newPassword = ''
      this.newRepeatPassword = ''
      this.codeTwoFactorAuth = ''
      this.twoFactorProfileId = ''
      this.isNeedSendCode = false
    },
    async signIn() {
      this.isLoading = true
      const user = useUserStore()
      axios
        .post('api/v1/sessions', {
          email: this.login,
          password: this.password
        })
				.then((response) => {
					if (response.status === 200 && response.data) {
            if (response.data.access_token) {
              user.token = response.data.access_token
              axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
              user.checkAuthenticated('success')
              this.isNeedSendCode = false
            } else {
              toast.success('На номер телефона указанный в профиле отправлено SMS-сообщение с кодом подтверждения')
              this.twoFactorProfileId = response.data.id
              this.isNeedSendCode = true
            }
          } else {
            toast.error('Некорректный логин и пароль')
          }
				})
				.catch((error) => {
          if (error.response?.data?.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('Некорректный логин и пароль')
          }
          
				})
        .finally((response) => {
          this.isLoading = false
        })
    },
    async verifuCode2FA() {
      this.isLoading = true
      const user = useUserStore()
      axios
        .post('/api/v1/sessions/validate_otp', { code: this.codeTwoFactorAuth, id: this.twoFactorProfileId })
        .then((response) => {
          if (response.status === 200 && response.data.access_token) {
            user.token = response.data.access_token
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
            user.checkAuthenticated('success')
            this.isNeedSendCode = false
          } else {
            console.log(response);
            toast.error('Произошла ошибка');
          }
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            toast.error(error.response.data.message);
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async resetPassword() {
      axios
        .get('/mockResetToken.json')
        .then((response) => {
          console.log(response)
          if (response.status === 200 && response.data.status === "ok") {
            let resetToken = response.data.reset_tokens.find(user => user.email === state.email);
            if (resetToken !== undefined) {
              router.push({name: 'new_password', query: {password_reset: resetToken.token}})
            } else {
              toast.error('Неверный токен')
            }
          }
        })
        .catch((error) => {
          console.error(error)
          toast.error('Ошибка запроса')
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updatePassword() {
      alert('Пароль обновлён')
      router.push({ name: 'sign_in'})
    },
    async logOut() {
      const user = useUserStore()
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-permissions')
      user.token = ''
      user.permissions = ''
      router.push({name: 'sign_in'})
    }
  }
})