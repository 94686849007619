import { defineStore } from 'pinia'
import { useCompaniesStore } from '@/stores/companies'
import { usePixelsStore } from '@/stores/pixels'
import { useProfilesStore } from '@/stores/profiles'
import { useKeysStore } from '@/stores/keys'
import { useCampaignsEmailStore } from '@/stores/campaignsEmail'
import { useCampaignsSmsStore } from '@/stores/campaignsSms'
import { useCampaignsSmsPushStore } from '@/stores/campaignsSmsPush'
import { useCustomFieldsStore } from '@/stores/customFields'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useUsersStore = defineStore({
  id: 'users',
  state: () => ({
    users: [],
    userCount: 0,
    currentUser: '',
    currentUserCompany: '',
    currentUserName: '',
    currentCompanyBalance: null,
    companyName: '',
    role: '',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phone: '',
    password: '',
    repeatPassword: '',
    responsibleUsers: [],
    companyId: '',
    codeTwoFactorAuth: '',
    isEnabledTwoFactorAuth: false,
    currentPage: 1,
    perPage: 20,
    lastPage: 1,
    isLoading: false,
    isUsersLoading: false
  }),
  getters: {
    userData: (state) => {
      let userData = {}
      if(state.firstName){
        userData['first_name'] = state.firstName
      }
      if(state.lastName){
        userData['last_name'] = state.lastName
      }
      if(state.middleName){
        userData['middle_name'] = state.middleName
      }
      if(state.email){
        userData['email'] = state.email
      }
      if(state.phone){
        userData['phone'] = state.phone
      }
      if(state.password){
        userData['password'] = state.password
      }
      if(state.repeatPassword){
        userData['password_confirmation'] = state.repeatPassword
      }
      if(state.companyId != '0' && state.companyId){
        userData['company_id'] = parseInt(state.companyId)
      }
      if(state.role){
        userData['role'] = state.role
      }
        
      return userData
    },
    userDataCurrentProfile() {
      let userData = {}
      for(var key in this.userData) {
        if (key == 'company_id' || key == 'role') {
          continue
        }
        userData[key] = this.userData[key]
      }

      return userData
    },
    beatyRole() { 
      return (role) => {
        switch(role) {
          case 'admin':
            return "Администратор"
          case 'account_manager':
            return "Аккаунт менеджер"
          case 'company_admin':
            return "Администратор компании"
          case 'company_manager':
            return "Менеджер компании"
          default:
            return "Пользователь"
        }
      }
    },
    defaultUsers: (state) => state.users,
    usersScope() { 
      return this.defaultUsers.map(user => {
        return {
          id: user.id,
          fullname: [user.last_name, user.first_name, user.middle_name].join(' ').trim(),
          email: user.email,
          phone: user.phone,
          company: user.company === undefined || user.company === null ? '' : user.company.name,
          role: this.beatyRole(user.role)
        }
      })
    },
    responsibles() {
      let userScope = this.responsibleUsers.filter(user => user.role == 'account_manager').map(user => {
        return {
          value: user.id.toString(),
          text: [user.last_name, user.first_name].join(' ').trim()
        }
      })
      userScope.unshift({
        value: null,
        text: ' '
      })

      return userScope
    }
    /*responsibles: (state) => state.users.filter(user => user.role == 'account_manager').map(user => {
        return {
          value: user.id.toString(),
          text: [user.last_name, user.first_name].join(' ').trim()
        }
      })*/
  },
  actions: {
    setUsersDefault() {
      this.users = []
      this.responsibleUsers = []
    },
    setUserDefault() {
      this.role = ''
      this.firstName = ''
      this.lastName = ''
      this.middleName = ''
      this.email = ''
      this.phone = ''
      this.password = ''
      this.repeatPassword = ''
      this.companyId = ''
    },
    setUsersPaginationDefault() {
      this.currentPage = 1
      this.perPage = 20
      this.lastPage = 1
    },
    async getAllUsers(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isUsersLoading = true
      axios
        .get('api/v1/users', { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.users = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isUsersLoading = false
        })
    },
    async getResponsibleUsers(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      axios
        .get('api/v1/users', { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.responsibleUsers = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCurrentProfile(callback = {}) {
      this.isLoading = true
      axios
        .get('api/v1/profile')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.currentUser = response.data.id
            this.email = response.data.email
            this.firstName = response.data.first_name
            this.lastName = response.data.last_name
            this.middleName = response.data.middle_name
            this.phone = response.data.phone
            this.role = response.data.role
            this.isEnabledTwoFactorAuth = response.data.two_factor_auth_enabled || false
            this.currentUserName = response.data.first_name
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
            if (response.data.company_id !== null) {
              const profiles = useProfilesStore()
              const pixels = usePixelsStore()
              const keys = useKeysStore()
              const campaignsEmail = useCampaignsEmailStore()
              const campaignsSms = useCampaignsSmsStore()
              const campaignsSmsPush = useCampaignsSmsPushStore()
              const customFields = useCustomFieldsStore()
              this.companyId = response.data.company_id.toString()
              pixels.companyId = response.data.company_id.toString()
              profiles.segmentCompanyId = response.data.company_id.toString()
              profiles.exportCompanyId = response.data.company_id.toString()
              keys.companyId = response.data.company_id.toString()
              campaignsEmail.campaignCompanyToken = response.data?.company?.email_builder_secret
              campaignsSms.campaignCompanyId = response.data.company_id.toString()
              campaignsSmsPush.campaignCompanyId = response.data.company_id.toString()
              customFields.customFieldCompanyId = response.data.company_id.toString()
              this.currentCompanyBalance = response.data.company.balance
              this.companyName = response.data.company.name
            } else {
              this.currentCompanyBalance = null
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', {})
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCurrentProfile() {
      axios
        .post('api/v1/profile', this.userDataCurrentProfile)
        .then((response) => {
          if (response.status === 200 && response.data.user) {
            this.email = response.data.user.email
            this.firstName = response.data.user.first_name
            this.lastName = response.data.user.last_name
            this.middleName = response.data.user.middle_name
            this.phone = response.data.user.phone
            this.role = response.data.user.role
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async sendCode2FA() {
      this.isLoading = true
      axios
        .post('/api/v1/profile/send_otp')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.code = response.data.message
            toast.success('На номер телефона указанный в профиле отправлено SMS-сообщение с кодом подтверждения')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            toast.error(error.response.data.message)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async activate2FA() {
      this.isLoading = true
      axios
        .post('/api/v1/profile/activate_2fa', { code: this.codeTwoFactorAuth })
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.isEnabledTwoFactorAuth = true
            toast.success('Вы включили двухфакторную аутентификация')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            toast.error(error.response.data.message)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deactivate2FA() {
      this.isLoading = true
      axios
        .post('/api/v1/profile/deactivate_2fa', { code: this.codeTwoFactorAuth })
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.isEnabledTwoFactorAuth = false
            toast.error('Вы отключили двухфакторную аутентификация')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            toast.error(error.response.data.message)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyAllUsers(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isUsersLoading = true
      axios
        .get('api/v1/my_company/users', { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.users = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isUsersLoading = false
        })
    },
    async getMyCompanyUserProfile() {
      axios
        .get(`api/v1/my_company/users/${this.currentUser}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.email = response.data.email
            this.firstName = response.data.first_name
            this.lastName = response.data.last_name
            this.middleName = response.data.middle_name
            this.phone = response.data.phone
            this.role = response.data.role
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    async createMyCompanyUser() {
      this.isLoading = true
      axios
        .post('api/v1/my_company/users', this.userData)
          .then((response) => {
            if (response.status === 201 && response.data.user) {
              this.setUserDefault()
              this.getMyCompanyAllUsers()
              toast.success('Пользователь успешно создан')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async updateMyCompanyUserProfile(){
      axios
        .post(`api/v1/my_company/users/${this.currentUser}`, this.userData)
          .then((response) => {
            if (response.status === 200 && response.data.user) {
              this.email = response.data.user.email
              this.firstName = response.data.user.first_name
              this.lastName = response.data.user.last_name
              this.middleName = response.data.user.middle_name
              this.phone = response.data.user.phone
              this.role = response.data.user.role
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
    },
    async deleteMyCompanyUser(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let userId = ''
        if (typeof payload == 'object' || payload === undefined) {
          userId = parseInt(this.currentUser)
        } else {
          userId = parseInt(payload)
        }
        axios
          .delete(`api/v1/my_company/users/${userId}`)
          .then((response) => {
            if (response.status === 200 && response.data.user) {
              this.getMyCompanyAllUsers()
              toast.success('Пользователь успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getCompanyUserProfile() {
      axios
        .get(`api/v1/companies/${this.currentUserCompany}/users/${this.currentUser}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              this.email = response.data.email
              this.firstName = response.data.first_name
              this.lastName = response.data.last_name
              this.middleName = response.data.middle_name
              this.phone = response.data.phone
              this.role = response.data.role
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    async createCompanyUser() {
      this.isLoading = true
      axios
        .post(`api/v1/companies/${this.currentUserCompany}/users`, this.userData)
        .then((response) => {
          if (response.status === 201 && response.data.user) {
            this.setUserDefault()
            toast.success('Пользователь успешно создан')
            const company = useCompaniesStore()
            company.getCompanyUsers()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCompanyUserProfile() {
      axios
        .post(`api/v1/companies/${this.currentUserCompany}/users/${this.currentUser}`, this.userData)
        .then((response) => {
          if (response.status === 200 && response.data.user) {
            this.email = response.data.user.email
            this.firstName = response.data.user.first_name
            this.lastName = response.data.user.last_name
            this.middleName = response.data.user.middle_name
            this.phone = response.data.user.phone
            this.role = response.data.user.role
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async deleteCompanyUser(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let userId = ''
        if (typeof payload == 'object' || payload === undefined) {
          userId = parseInt(this.currentUser)
        } else {
          userId = parseInt(payload)
        }
        axios
          .delete(`api/v1/companies/${this.currentUserCompany}/users/${userId}`)
          .then((response) => {
            if (response.status === 200 && response.data.user) {
              const company = useCompaniesStore()
              company.getCompanyUsers()
              toast.success('Пользователь успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getUserProfile() {
      axios
        .get(`api/v1/users/${this.currentUser}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.email = response.data.email
            this.firstName = response.data.first_name
            this.lastName = response.data.last_name
            this.middleName = response.data.middle_name
            this.phone = response.data.phone
            this.companyId = response.data.company_id
            this.role = response.data.role
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async createUser(){
      this.isLoading = true
      axios
        .post('api/v1/users', this.userData)
        .then((response) => {
          if (response.status === 201 && response.data.user) {
            this.setUserDefault()
            this.getAllUsers()
            toast.success('Пользователь успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateUserProfile() {
      axios
        .post(`api/v1/users/${this.currentUser}`, this.userData)
        .then((response) => {
          if (response.status === 200 && response.data.user) {
            this.email = response.data.user.email
            this.firstName = response.data.user.first_name
            this.lastName = response.data.user.last_name
            this.middleName = response.data.user.middle_name
            this.phone = response.data.user.phone
            this.role = response.data.user.role
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async deleteUser(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let userId = ''
        if (typeof payload == 'object' || payload === undefined) {
          userId = parseInt(this.currentUser)
        } else {
          userId = parseInt(payload)
        }
        axios
          .delete(`api/v1/users/${userId}`)
          .then((response) => {
            if (response.status === 200 && response.data.user) {
              this.getAllUsers()
              toast.success('Пользователь успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
  }
})