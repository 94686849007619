import { defineStore } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import axios from 'axios'
import router from "@/router/index"

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    token: '',
    permissions: '',
    isMenuOpened: false
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    permissionsArray: (state) => state.permissions == '' ? [] : state.permissions.split(','),
    can() {
      return (rule) => {
        if (!rule) {
          return false
        }

        for (let permission of this.permissionsArray) {
          if (rule.search(permission.split('.*')[0]) != -1) {
            return true
          }
        }
        return false
      }
    }
  },
  actions: {
    async checkAuthenticated(payload) {
      const auth = useAuthStore()
      axios
        .get('api/v1/profile/permissions')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.permissions = response.data.join(',')
            if (payload == 'success') {
              auth.isLoading = false
              router.push({name: 'home'})
            }
          } else {
            auth.logOut()
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
  },
  persist: true
})