import IMask from 'imask'

const phoneMask = {
  mask: '+000000000000000000',
  prepare: (str) => str.replace(/^\+/, '')
}
const dateMask = {
  mask: Date, 
  pattern: 'd`{.}`m`{.}`Y',
  blocks: {
    d: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
    m: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    Y: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: 9999,
      maxLength: 4,
    }
  },
}
const dateTimeMask = {
  mask: Date,
  pattern: 'd{.}`m{.}`Y{ }`H{:}`sS',
  blocks: {
    d: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
    m: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    Y: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: 9999,
    },
    H: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
      maxLength: 2,
    },
    s: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 5,
      maxLength: 1,
    },
    S: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 9,
      maxLength: 2,
    }
  },
}
const timerMask = {
  mask: Date,
  pattern: 'd`{:}`h`{:}`m`{:}`sS',
  blocks: {
    d: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 31,
      maxLength: 2,
    },
    h: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
      maxLength: 2,
    },
    m: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
    },
    s: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 5,
      maxLength: 1,
    },
    S: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 9,
      maxLength: 2,
    }
  }
}
const listMask = {
  phone: phoneMask,
  date: dateMask,
  dateTime: dateTimeMask,
  timer: timerMask
}

export {
  phoneMask,
  dateMask,
  dateTimeMask,
  timerMask,
  listMask
}