import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const usePixelsStore = defineStore({
  id: 'pixels',
  state: () => ({
    pixels: [],
    pixelEvents: [],
    pixelId: 0,
    name: '',
    description: '',
    domain: '',
    companyId: '',
    code: '',
    createdAt: '',
    updatedAt: '',
    pixelEventId: '',
    pixelEventName: '',
    pixelEventCompanyId: '',
    pixelEventType: 'PageView',
    pixelEventPath: '',
    pixelEventOneTime: 'false',
    pixelEventTag: '',
    pixelEventValue: '',
    pixelCollectFormData: 'false',
    pixelCurrentPage: 1,
    pixelPerPage: 20,
    pixelLastPage: 1,
    pixelEventsCurrentPage: 1,
    pixelEventsPerPage: 20,
    pixelEventsLastPage: 1,
    pixelPredefinedEvents: [],
    pixelPredefinedEventsShowCompanyId: '',
    pixelPredefinedEventsCurrentPage: 1,
    pixelPredefinedEventsPerPage: 20,
    pixelPredefinedEventsLastPage: 1,
    isLoading: false,
    isPixelsLoading: false
  }),
  getters: {
    pixelData: (state) => {
      let pixelData = {}
      if(state.name){
        pixelData['name'] = state.name
      }
      if(state.description){
        pixelData['description'] = state.description
      }
      if(state.domain){
        pixelData['domain'] = state.domain
      }
      if(state.companyId){
        pixelData['company_id'] = parseInt(state.companyId)
      }
      pixelData['collect_form_data'] = state.pixelCollectFormData  == 'true' ? true : false
      
      return pixelData
    },
    eventData: state => {
      let eventData = {}

      if (state.pixelEventName) {
        eventData['name'] = state.pixelEventName
      }
      if(state.pixelEventType) {
        eventData['kind'] = state.pixelEventType
      }
      if(state.pixelEventPath) {
        eventData['path'] = state.pixelEventPath
      }
      eventData['one_time'] = state.pixelEventOneTime == 'true' ? true : false
      if(state.pixelEventTag) {
        eventData['pixel_tag'] = state.pixelEventTag
      }
      if(state.pixelEventValue) {
        eventData['pixel_value'] = state.pixelEventValue
      }
      
      return eventData
    },
    predefinedEventData: state => {
      let eventData = {}

      if (state.pixelEventName) {
        eventData['name'] = state.pixelEventName
      }
      if (state.pixelEventCompanyId) {
        eventData['company_id'] = state.pixelEventCompanyId
      }
      if (state.pixelEventType) {
        eventData['kind'] = state.pixelEventType
      }
      if (state.pixelEventPath) {
        eventData['path'] = state.pixelEventPath
      }
      eventData['one_time'] = state.pixelEventOneTime == 'true' ? true : false
      if (state.pixelEventTag) {
        eventData['pixel_tag'] = state.pixelEventTag
      }
      if (state.pixelEventValue) {
        eventData['pixel_value'] = state.pixelEventValue
      }
      
      return eventData
    },
    pixelsForSelect: (state) => state.pixels.map(pixel => {
      return {
        value: pixel.id.toString(),
        text: pixel.name,
        companyId: pixel.company_id
      }
    }),
    partitionPixels: (state) => {
      let size = 3
      let pixels = state.pixels
      pixels = Array.from({ length: Math.ceil(state.pixels.length / size) }, (v, i) =>
        pixels.slice(i * size, i * size + size)
      )
      pixels = pixels.map((arrayPixels, index) => {
        return {
          id: (index + 1),
          array: arrayPixels.map(pixel => {
            return {
              id: pixel.id,
              name: pixel.name,
              domain: pixel.domain,
              description: pixel.description,
              code: pixel.code,
              companyId: pixel.company_id,
              createdAt: pixel.created_at,
              updatedAt: pixel.updated_at,
              collectFormData: pixel.collect_form_data
            }
          })
        }
      })

      return pixels
    },
    preparedPixelEvents: (state) => state.pixelEvents.map(pixelEvent => {
      return {
        id: pixelEvent.id,
        name: pixelEvent.name,
        type: pixelEvent.kind,
        path: pixelEvent.path,
        oneTime: pixelEvent.one_time != true ? '' : 'true',
        tag: pixelEvent.pixel_tag == null || pixelEvent.pixel_tag == undefined ? '' : pixelEvent.pixel_tag,
        meaning: pixelEvent.pixel_value == null || pixelEvent.pixel_value == undefined ? '' : pixelEvent.pixel_value,
        editable: pixelEvent.editable,
        description: pixelEvent.description
      }
    }),
    preparedPixelPredefinedEvents() { 
      return this.pixelPredefinedEvents.map(pixelEvent => {
        return {
          id: pixelEvent.id,
          name: pixelEvent.name,
          type: pixelEvent.kind,
          path: pixelEvent.path,
          oneTime: pixelEvent.one_time != true ? '' : 'true',
          tag: pixelEvent.pixel_tag == null || pixelEvent.pixel_tag == undefined ? '' : pixelEvent.pixel_tag, 
          meaning: pixelEvent.pixel_value == null || pixelEvent.pixel_value == undefined ? '' : pixelEvent.pixel_value,
          editable: pixelEvent.editable,
          description: pixelEvent.description,
          companyId: pixelEvent.company_id || ''
        }
      })
    },
  },
  actions: {
    setPixelDefault() {
      this.pixelId = 0,
      this.name = ''
      this.description = ''
      this.domain = ''
      this.companyId = ''
      this.code = ''
      this.createdAt = ''
      this.updatedAt = ''
      this.pixelCollectFormData = 'false'
    },
    setPixelPaginationDefault() {
      this.pixelCurrentPage = 1
      this.pixelPerPage = 20
      this.pixelLastPage = 1
    },
    setPixelEventsPaginationDefault() {
      this.pixelEventsCurrentPage = 1
      this.pixelEventsPerPage = 20
      this.pixelEventsLastPage = 1
    },
    setPixelEventDefault() {
      this.pixelEventName = ''
      this.pixelEventType = 'PageView'
      this.pixelEventCompanyId = ''
      this.pixelEventPath = ''
      this.pixelEventOneTime= ''
      this.pixelEventTag = ''
      this.pixelEventValue = ''
    },
    setPixelPredefinedEventPaginationDefault() {
      this.pixelPredefinedEventsCurrentPage = 1
      this.pixelPredefinedEventsPerPage = 20
      this.pixelPredefinedEventsLastPage = 1
    },
    async getPixels(currentPerPage, callback = {}) {
      let perPage = currentPerPage || this.pixelPerPage
      this.isPixelsLoading = true
      axios
        .get('api/v1/pixels', { params: { page: this.pixelCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.pixels = response.data.data
            this.pixelCurrentPage = response.data.current_page
            this.pixelLastPage = response.data.last_page
            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isPixelsLoading = false
        })
    },
    async getPixelsWithCompany(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }
      
      this.isPixelsLoading = true
      axios
        .get('api/v1/pixels', params)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.pixels = response.data.data
            this.pixelCurrentPage = response.data.current_page
            this.pixelLastPage = response.data.last_page
            
            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isPixelsLoading = false
        })
    },
    async getPixel() {
      this.isLoading = true
      axios
        .get(`api/v1/pixels/${this.pixelId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.name = response.data.name
            this.description = response.data.description
            this.domain = response.data.domain
            this.companyId = response.data.company_id
            this.pixelPredefinedEventsShowCompanyId = response.data.company_id
            this.code = response.data.code
            this.createdAt = response.data.created_at
            this.updatedAt = response.data.updated_at
            this.pixelCollectFormData = response.data.collect_form_data
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createPixel() {
      this.isLoading = true
      axios
        .post('api/v1/pixels', this.pixelData)
        .then((response) => {
          if (response.status === 201 && response.data.pixel) {
            this.setPixelDefault()
            this.getPixels()
            toast.success('Пиксель успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updatePixel() {
      axios
        .post(`api/v1/pixels/${this.pixelId}`, this.pixelData)
        .then((response) => {
          if (response.status === 200 && response.data.pixel) {
            this.name = response.data.pixel.name
            this.description = response.data.pixel.description
            this.domain = response.data.pixel.domain
            this.companyId = response.data.pixel.company_id
            this.code = response.data.pixel.code
            this.createdAt = response.data.pixel.created_at
            this.updatedAt = response.data.pixel.updated_at
            this.pixelCollectFormData = response.data.pixel.collect_form_data
            toast.success('Пиксель успешно обновлён')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async deletePixel(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let pixelId = ''
        if (payload == '') {
          pixelId = parseInt(this.pixelId)
        } else {
          pixelId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/pixels/${pixelId}`)
          .then((response) => {
            if (response.status === 200 && response.data.pixel) {
              this.getPixels()
              toast.success('Пиксель успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getPixelEvents(currentPerPage) {
      let perPage = currentPerPage || this.pixelEventsPerPage
      this.isLoading = true
      axios
        .get(`api/v1/pixels/${this.pixelId}/pixel_events`, { params: { page: this.pixelEventsCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.pixelEvents = response.data.data
            this.pixelEventsCurrentPage = response.data.current_page
            this.pixelEventsLastPage = response.data.last_page
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async createEvent() {
      this.isLoading = true
      axios
        .post(`api/v1/pixels/${this.pixelId}/pixel_events`, this.eventData)
        .then((response) => {
          if (response.status === 201 && response.data.pixel_event) {
            this.setPixelEventDefault()
            this.getPixelEvents()
            toast.success('Событие успешно создано')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createEventFromPredefined(eventId) {
      this.isLoading = true
      axios
        .post(`api/v1/pixels/${this.pixelId}/pixel_events?predefined_pixel_event_id=${eventId}`)
        .then((response) => {
          if (response.status === 201 && response.data.pixel_event) {
            this.getPixelEvents()
            toast.success('Событие успешно создано')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateEvent() {
      this.isLoading = true
      axios
        .post(`api/v1/pixels/${this.pixelId}/pixel_events/${this.pixelEventId}`,  this.eventData)
        .then((response) => {
          if (response.status === 200 && response.data.pixel_event) {
            this.pixelEventName = response.data.pixel_event.name
            this.pixelEventType = response.data.pixel_event.kind
            this.pixelEventPath = response.data.pixel_event.path
            this.pixelEventOneTime = response.data.pixel_event.one_time == true ? 'true' : ''
            this.pixelEventTag = response.data.pixel_event.pixel_tag || ''
            this.pixelEventValue = response.data.pixel_event.pixel_value || ''
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteEvent(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let eventId = ''
        if (typeof payload == 'object' || payload === undefined) {
          eventId = parseInt(this.pixelEventId)
        } else {
          eventId = parseInt(payload)
        }
        axios
          .delete(`api/v1/pixels/${this.pixelId}/pixel_events/${eventId}`)
          .then((response) => {
            if (response.status === 200 && response.data.pixel_event) {
              this.pixelValueName = ''
              this.getPixelEvents()
              toast.success('Событие успешно удалено')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getPixelPredefinedEvents(currentPerPage) {
      let perPage = currentPerPage || this.pixelPredefinedEventsPerPage
      let params = {}
      if (this.pixelPredefinedEventsShowCompanyId) {
        params = { params: { company_id: this.pixelPredefinedEventsShowCompanyId , page: this.pixelPredefinedEventsCurrentPage, per: perPage }}
      }
      this.isLoading = true
      axios
        .get('/api/v1/predefined_pixel_events', params)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.pixelPredefinedEvents = response.data.data
            this.pixelPredefinedEventsCurrentPage = response.data.current_page
            this.pixelPredefinedEventsLastPage = response.data.last_page
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async createPredefinedEvent() {
      this.isLoading = true
      axios
        .post('/api/v1/predefined_pixel_events', this.predefinedEventData)
        .then((response) => {
          if (response.status === 201 && response.data.predefined_pixel_event) {
            this.setPixelEventDefault()
            this.getPixelPredefinedEvents()
            toast.success('Событие успешно создано')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updatePredefinedEvent() {
      this.isLoading = true
      axios
        .post(`api/v1/predefined_pixel_events/${this.pixelEventId}`,  this.predefinedEventData)
        .then((response) => {
          if (response.status === 200 && response.data.predefined_pixel_event) {
            this.setPixelEventDefault()
            this.getPixelPredefinedEvents()
            toast.success('Событие успешно обновлено')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deletePredefinedEvent(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let eventId = ''
        if (typeof payload == 'object' || payload === undefined) {
          eventId = parseInt(this.pixelEventId)
        } else {
          eventId = parseInt(payload)
        }
        axios
          .delete(`api/v1/predefined_pixel_events/${eventId}`)
          .then((response) => {
            if (response.status === 200 && response.data.predefined_pixel_event) {
              this.pixelValueName = ''
              this.getPixelPredefinedEvents()
              toast.success('Событие успешно удалено')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
  }
})