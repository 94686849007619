import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useKeysStore = defineStore({
  id: 'keys',
  state: () => ({
    apiKeys: [],
    apiKeyId: '',
    name: '',
    apiKeyValue: '',
    description: '',
    companyId: '',
    createdAt: '',
    updatedAt: '',
    currentPage: 1,
    perPage: 20,
    lastPage: 1,
    isLoading: false,
    isApiKeysLoading: false
  }),
  getters: {
    apiKeyData: (state) => {
      let apiKeyData = {}
      if(state.name){
        apiKeyData['name'] = state.name
      }
      if(state.description){
        apiKeyData['description'] = state.description
      }
      if(state.companyId){
        apiKeyData['company_id'] = parseInt(state.companyId)
      }
      
      return apiKeyData
    },
    partitionApiKeys: (state) => {
      let size = 3
      let apiKeys = state.apiKeys
      apiKeys = Array.from({ length: Math.ceil(state.apiKeys.length / size) }, (v, i) =>
        apiKeys.slice(i * size, i * size + size)
      )
      apiKeys = apiKeys.map((arrayApiKeys, index) => {
        return {
          id: (index + 1),
          array: arrayApiKeys.map(apiKey => {
            return {
              id: apiKey.id,
              name: apiKey.name,
              apiKeyValue: apiKey.api_key,
              description: apiKey.description,
              companyId: apiKey.company_id,
              createdAt: apiKey.created_at,
              updatedAt: apiKey.updated_at
            }
          })
        }
      })

      return apiKeys
    },
  },
  actions: {
    setApiKeyDefault() {
      this.apiKeyId = ''
      this.name = ''
      this.description = ''
      this.companyId = ''
      this.createdAt = ''
      this.updatedAt = ''
    },
    setPixelPaginationDefault() {
      this.currentPage = 1
      this.perPage = 20
      this.lastPage = 1
    },
    resetApiKey() {
      this.name = ''
      this.description = ''
    },
    async getApiKeys(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isApiKeysLoading = true
      axios
        .get('api/v1/api_keys', { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.apiKeys = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isApiKeysLoading = false
        })
    },
    async getApiKey() {
      this.isLoading = true
      axios
        .get(`api/v1/api_keys/${this.apiKeyId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.name = response.data.name
            this.description = response.data.description
            this.companyId = response.data.company_id
            this.apiKeyValue = response.data.api_key
            this.createdAt = response.data.created_at
            this.updatedAt = response.data.updated_at
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createApiKey() {
      this.isLoading = true
      axios
        .post('api/v1/api_keys', this.apiKeyData)
        .then((response) => {
          if (response.status === 201 && response.data.api_key) {
            this.setApiKeyDefault()
            this.getApiKeys()
            toast.success('API-ключ успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateApiKey() {
      axios
        .post(`api/v1/api_keys/${this.apiKeyId}`, this.apiKeyData)
        .then((response) => {
          if (response.status === 200 && response.data.api_key) {
            this.name = response.data.api_key.name
            this.description = response.data.api_key.description
            this.companyId = response.data.api_key.company_id
            this.apiKeyValue = response.data.api_key.api_key
            this.createdAt = response.data.api_key.created_at
            this.updatedAt = response.data.api_key.updated_at
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async deleteApiKey(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let apiKeyId = ''
        if (typeof payload == 'object' || payload === undefined) {
          apiKeyId  = parseInt(this.apiKeyId)
        } else {
          apiKeyId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/api_keys/${apiKeyId}`)
          .then((response) => {
            if (response.status === 200 && response.data.api_key) {
              this.getApiKeys()
              toast.success('API-ключ успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async refreshApiKey() {
      this.isLoading = true
      axios
        .post(`api/v1/api_keys/${this.apiKeyId}/refresh`)
        .then((response) => {
          if (response.status === 200 && response.data.api_key) {
            this.apiKeyValue = response.data.api_key.api_key
            this.updatedAt = response.data.api_key.updated_at
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
  },
})