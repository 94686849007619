import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"
import { systemName } from '@/plugins/vuelidateVerification'

const toast = useToast()

export const useCustomFieldsStore = defineStore ({
  id:'customFields',
  state: () => ({
    profileCustomFields: [],
    fieldId: '',
    fieldName: '',
    type: '',
    systemName: '',
    uniqueField: '',
    customFieldCompanyId: '',
    typeField: [],
    fieldsLastPage: 1,
    fieldsCurrentPage: 1,
    fieldsPerPage: 10,
    staticFields: [
      {name: 'Источник (ID пикселя)', type: 'Integer', systemName: 'pixel_id', unique: false},
      {name: 'Имя', type: 'String', systemName: 'personal_name', unique: false},
      {name: 'Фамилия', type: 'String', systemName: 'personal_surname', unique: false},
      {name: 'Пол', type: 'String', systemName: 'bio_gender', unique: false},
      {name: 'Email', type: 'String', systemName: 'personal_email', unique: false},
      {name: 'Телефон', type: 'String', systemName: 'personal_phone', unique: false},
      {name: 'Дата рождения', type: 'DateTime', systemName: 'bio_birth', unique: false},
      {name: 'Город', type: 'String', systemName: 'personal_city', unique: false},
      {name: 'Страна', type: 'String', systemName: 'personal_country', unique: false},
      {name: 'Интересы', type: 'jsonb', systemName: 'interests', unique: false},
      {name: 'Часовой пояс', type: 'String', systemName: 'current_time_utc', unique: false},
      {name: 'Согласие на обработку перс. данных', type: 'jsonb', systemName: 'personal_data_legals', unique: false},
      {name: 'Список ID ВКонтакте', type: 'jsonb', systemName: 'ids_socials_vk', unique: false},
      {name: 'Список ID Одноклассники', type: 'jsonb', systemName: 'ids_socials_odn', unique: false},
      {name: 'Список ID Яндекс', type: 'jsonb', systemName: 'ids_pixels_yandex', unique: false},
      {name: 'Список ID Mail.ru', type: 'jsonb', systemName: 'ids_pixels_mailru', unique: false},
      {name: 'Список ID Google', type: 'jsonb', systemName: 'ids_pixels_google', unique: false},
      {name: 'Количество визитов', type: 'Integer', systemName: 'visits_count', unique: false},
      {name: 'Посещенные страницы', type: 'jsonb', systemName: 'visits_urls', unique: false},
      {name: 'Среднее время интернет-серфинга в день', type: 'Float', systemName: 'visits_avg_surfing_times_of_day', unique: false},
      {name: 'Общее количество секунд на сайтах', type: 'Float', systemName: 'visits_avg_duration_sec', unique: false},
      {name: 'Дата создания', type: 'DateTime', systemName: 'created_at', unique: false},
      {name: 'Дата обновления', type: 'DateTime', systemName: 'updated_at', unique: false},
      {name: 'IP-адреса', type: 'jsonb', systemName: 'visits_ips', unique: false},
      {name: 'MAC-адрес', type: 'String', systemName: 'mac_address', unique: false},
      {name: 'Fingerprint IDs', type: 'jsonb', systemName: 'visits_fingerprint_ids', unique: false},
      {name: 'Согласие на sms-рассылки', type: 'Boolean', systemName: 'email_consent', unique: false},
      {name: 'Согласие на email-рассылки', type: 'Boolean', systemName: 'sms_consent', unique: false},
      {name: 'Cписок ID сессий', type: 'jsonb', systemName: 'visits_session_ids', unique: false},
      {name: 'Параметры из URL', type: 'jsonb', systemName: 'visits_url_keywords', unique: false},
      {name: 'UTM Метки', type: 'jsonb', systemName: 'visits_utm_keywords', unique: false},
      {name: 'Типы устройств', type: 'jsonb', systemName: 'visits_device_types', unique: false},
      {name: 'Useragents', type: 'jsonb', systemName: 'visits_useragents', unique: false},
      {name: 'Устройства', type: 'jsonb', systemName: 'visits_devices', unique: false},
      {name: 'Разрешение экранов', type: 'jsonb', systemName: 'visits_display_resolutions', unique: false}
    ],
    isLoading: false,
  }),
  getters: {
    customFieldData: (state) => {
     let customFieldData = {}

     if(state.fieldName) {
      customFieldData['name'] = state.fieldName
     }
     if(state.systemName) {
      customFieldData['system_name'] = state.systemName
     }
     if(state.customFieldCompanyId) {
      customFieldData['company_id'] = parseInt(state.customFieldCompanyId)
     }
     if(state.typeField) {
      customFieldData['kind'] = state.typeField
     }
     if(state.uniqueField) {
      customFieldData['unique'] = state.uniqueField === 'true' ? true : false
     }

     return customFieldData
    },
    profileCustomFieldsScope: (state) => state.profileCustomFields.map(customField =>{
      return {
        id: customField.id,
        name: customField.name,
        type: customField.kind,
        systemName: `${customField.prefix}${customField.system_name}`,
        uniqueField: customField.unique
      }
    }),
  },
  actions: {
    setCustomFieldsDefault() {
      this.fieldName = ''
      this.systemName = ''
      this.typeField = ''
      this.uniqueField = ''
    },
    setCustomFieldsPaginationDefault() {
      this.fieldsCurrentPage = 1
      this.fieldsPerPage = 20
      this.fieldsLastPage = 1
    },
    async getProfileCustomFields(currentPerPage) {
      let perPage = currentPerPage || this.fieldsPerPage
      let params = { params:{ page: this.fieldsCurrentPage, per: perPage} }

      if (this.customFieldCompanyId !== '') {
        params.params['company_id'] = parseInt(this.customFieldCompanyId)
      }
      this.isLoading = true
      axios
        .get('api/v1/custom_fields', params)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.profileCustomFields = response.data.data
            this.typeField = response.data.kind
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProfileCustomFieldsWithCompany(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }

      this.isLoading = true
      axios
        .get('api/v1/custom_fields', params)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProfileCustomField() {
      this.isLoading = true
      axios
        .get(`/api/v1/custom_fields/${this.fieldId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.fieldName = response.data.name
            this.customFieldCompanyId = response.data.company_id
            this.systemName = response.data.system_name
            this.typeField = response.data.kind
            this.uniqueField = response.data.unique === true ? 'true' : ''
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createCustomFields(callback = {}) {
      this.isLoading = true
      axios
        .post('api/v1/custom_fields', this.customFieldData)
        .then((response) => {
          if (response.status === 201 && response.data.custom_field) {
            this.getProfileCustomFields()
            toast.success('Пользовательское поле успешно создано')
            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCustomFields() {
      this.isLoading = true
      axios
        .post(`api/v1/custom_fields/${this.fieldId}`, this.customFieldData)
        .then((response) => {
          if (response.status === 200 && response.data.custom_field) {
            this.fieldName = response.data.custom_field.name
            this.customFieldCompanyId = response.data.custom_field.company_id
            this.systemName = response.data.custom_field.system_name
            this.typeField = response.data.custom_field.kind
            this.uniqueField = response.data.custom_field.unique === true ? 'true' : ''
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteCustomFields(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let customFieldId = ''

        if (typeof payload == 'object' || payload === undefined) {
          customFieldId  = parseInt(this.fieldId)
        } else {
          customFieldId = parseInt(payload)
        }

        this.isLoading = true
        axios
          .delete(`api/v1/custom_fields/${customFieldId}`)
          .then((response) => {
            if (response.status === 200 && response.data.custom_field) {
              toast.success('Пользовательское поле успешно удалено')
              this.getProfileCustomFields(100)
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
  },
})