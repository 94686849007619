import moment from 'moment-timezone'

function checkDateTime(value) {
  return moment(value, "DD.MM.YYYY H:mm", true).isValid()
}

function checkTimerTime(value) {
  return (/^(([0-9]+):([0-9]{2}):([0-9]{2}):([0-9]{2}))$/).test(value)
}

export {
  checkDateTime,
  checkTimerTime
}