import { required, email, minLength, maxLength, sameAs, helpers, requiredIf } from '@vuelidate/validators'
import { phone } from 'phone'
import moment from 'moment-timezone'
//require('moment/locale/ru')
import { declOfNum } from '@/helpers/prettyPrint'

moment.tz.setDefault("Europe/Moscow")
//moment.locale('ru')
const requiredRu = helpers.withMessage('Обязательное поле', required)
const emailRu = helpers.withMessage('Некорректный email', email)
const url = helpers.withMessage('Ссылка должна начинаться с https://', helpers.regex(/^((https:\/\/)(.*?))$/))
const money = helpers.withMessage('Некорректное значение', helpers.regex(/^((\d+)|(\d+(\.[0-9]{2})))$/))
const numbers = helpers.withMessage('Введено не число', helpers.regex(/^(\d+)$/))
const numbersWithEmpty = helpers.withMessage('Введено не число', helpers.regex(/^(\d*)$/))
const fractionalNumbersWithEmpty = helpers.withMessage('Введено не дробно число', helpers.regex(/^((0|[1-9]\d*)[.]\d+)*$/))
const phoneNumber = helpers.withMessage('Некорректный номер телефона', (value) => value === '' ? true : phone(value).isValid)
const onlyLatin = helpers.withMessage('Введены не латинские символы', helpers.regex(/^[A-Za-z]+$/))
const smsNameSender = helpers.withMessage('Введены не латинские символы', helpers.regex(/^[a-zA-z0-9.!?()_-]+$/))
const systemName = helpers.withMessage('Введены не латинские символы', helpers.regex(/^[a-zA-Z][a-zA-Z_-]+$/));
const date = helpers.withMessage('Некорректная дата (DD.MM.YYYY)', (value) => value === '' ? true : moment(value, "DD.MM.YYYY", true).isValid())
const dateTime = helpers.withMessage('Некорректная дата (DD.MM.YYYY HH:MM)', (value) => value === '' ? true : moment(value, "DD.MM.YYYY H:mm", true).isValid())
const timer = helpers.withMessage('Некорректный формат (DD:HH:MM:SS)', helpers.regex(/^(([0-9]+):([0-9]{2}):([0-9]{2}):([0-9]{2}))$/))
const dateRegex = helpers.withMessage('Некорректный формат (DD.MM.YYYY)', helpers.regex(/^(([0-9]){2}\.([0-9]){2}\.([0-9]){4})$/))
const dateTimeBeforeNow = helpers.withMessage('Не может быть указана дата раньше текущей', (value) => {
  if (moment(value, "DD.MM.YYYY H:mm", true).isValid()) {
    return moment(value, "DD.MM.YYYY H:mm", true) > moment()
  }

  return true
})
const smsTestSenders = helpers.withMessage('Некорректные отправители или число отправителей', (value) => {
  if (value === '') {
    return true
  }

  let isIncorrectPhone = false

  value.split(' ').forEach(currentPhone => {
    if(!phone(currentPhone).isValid) {
      isIncorrectPhone = true
    }
  })

  return value.split(' ').length <= 5 && !isIncorrectPhone
})
const phoneList = helpers.withMessage('Некорректный телефон в списке', (value) => {
  if (value === '') return true

  let isIncorrectPhone = false

  value.split(',').forEach(currentPhone => {
    if(!phone(currentPhone.trim()).isValid) isIncorrectPhone = true
  })

  return !isIncorrectPhone
})

const smsNotFullConfirmedSenders = (incorrectSenderIds = []) => helpers.withMessage(
  'Данное имя отправителя не согласовано', 
  (value) => {
    if (value === '') {
      return true
    }

    return !incorrectSenderIds.includes(value)
  }
)

const emailTestSenders = helpers.withMessage('Некорректные отправители или число отправителей', (value) => {
  if (value === '') {
    return true
  }

  let isIncorrectEmail = false

  value.split(' ').forEach(currentEmail => {
    if(!(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/).test(currentEmail)) {
      isIncorrectEmail  = true
    }
  })

  return value.split(' ').length <= 5 && !isIncorrectEmail
})

const emailList = helpers.withMessage('Некорректный email в списке', (value) => {
  if (value === '') return true

  let isIncorrectEmail = false

  value.split(',').forEach(currentEmail => {
    if(!(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/).test(currentEmail.trim())) isIncorrectEmail  = true
  })

  return !isIncorrectEmail
})

function compareDate(comparisonField, comparisonFieldName = '') {
  return helpers.withMessage(
    `Текущее поле не может быть меньше поля ${comparisonFieldName}`,
    (value) => {
      if (moment(value, "DD.MM.YYYY", true).isValid() && moment(comparisonField, "DD.MM.YYYY", true).isValid()) {
        return moment(value, "DD.MM.YYYY", true) >= moment(comparisonField, "DD.MM.YYYY", true)
      }
    
      return true
    }
  )
}

function minLengthRu(value = 6) {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => `Поле должно содержать хотя бы ${value} ${declOfNum(value, ['символ', 'символа', 'символов'])}`,
    minLength(value)
  )
}
function maxLengthRu(value = 12) {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => `Поле не должно содержать больше ${value} ${declOfNum(value, ['символ', 'символа', 'символов'])}`,
    maxLength(value)
  )
}
function sameAsRu(field, value = '') {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => `Не соотвествует полю ${value}`,
    sameAs(field)
  )
}

function requiredIfRu(conditionalFunction) {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => 'Обязательное поле',
    requiredIf(conditionalFunction)
  )
}

const fixCountSymbolsWithParam = (param) => helpers.withParams(
  { type: 'fixCountSymbols', value: param }, (value) => {
    if (value === '') {
      return true
    }

    return value.length === param
  }
)

function fixCountSymbols(value) {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => `Некорректное количество символов, ожидалось ${value}`,
    fixCountSymbolsWithParam(value)
  )
} 


export {
  requiredRu,
  requiredIfRu,
  emailRu,
  emailList,
  money,
  url,
  numbers,
  numbersWithEmpty,
  fractionalNumbersWithEmpty,
  phoneNumber,
  phoneList,
  onlyLatin,
  date,
  timer,
  dateRegex,
  dateTime,
  dateTimeBeforeNow,
  smsNameSender,
  smsTestSenders,
  emailTestSenders,
  systemName,
  smsNotFullConfirmedSenders,
  compareDate,
  minLengthRu,
  maxLengthRu,
  sameAsRu,
  fixCountSymbols
}