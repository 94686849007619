import BaseInput from '@/components/UI/BaseInput'
import BaseInputPassword from '@/components/UI/BaseInputPassword'
import BaseInputSeamless from '@/components/UI/BaseInputSeamless'
import BaseInputPasswordSeamless from '@/components/UI/BaseInputPasswordSeamless'
import BaseInputWithValidation from '@/components/UI/BaseInputWithValidation'
import BaseButton from '@/components/UI/BaseButton'
import BaseSelect from '@/components/UI/BaseSelect'
import BaseSelectSeamless from '@/components/UI/BaseSelectSeamless'
import BaseRadio from '@/components/UI/BaseRadio'
import BaseRadioSeamless from '@/components/UI/BaseRadioSeamless'
import BaseCheckBox from '@/components/UI/BaseCheckBox'
import BaseRange from '@/components/UI/BaseRange'
import BaseModal from '@/components/UI/BaseModal'
import BaseAccordion from '@/components/UI/BaseAccordion'
import BaseInputDatepicker from '@/components/UI/BaseInputDatepicker'
import BaseInputDatepickerSeamless from '@/components/UI/BaseInputDatepickerSeamless'

export default[
  BaseInput,
  BaseInputPassword,
  BaseInputSeamless,
  BaseInputPasswordSeamless,
  BaseInputWithValidation,
  BaseInputDatepicker,
  BaseInputDatepickerSeamless,
  BaseButton,
  BaseSelect,
  BaseSelectSeamless,
  BaseRadio,
  BaseRadioSeamless,
  BaseCheckBox,
  BaseRange,
  BaseModal,
  BaseAccordion
]