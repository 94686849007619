const queryFields = [
  {
    id: 'cdp_profiles',
    label: 'Поля профиля',
    optgroup: 'Поля профиля',
    fields: [
      {
        id: 'cdp_profiles.pixel_id',
        label: 'Источник (ID пикселя)',
        optgroup: "Профиль",
        type: 'integer',
        input: 'select',
        operators: ['equal', 'not_equal', 'in', 'not_in'],
        values: [],
        validations: ['requiredIf']
      },
      {
        id: 'cdp_profiles.personal_name',
        label: 'Имя',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty']
      }, {
        id: 'cdp_profiles.personal_surname',
        label: 'Фамилия',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty']
      },
      {
        id: 'cdp_profiles.bio_gender',
        label: 'Пол',
        optgroup: "Профиль",
        type: 'string',
        input: 'radio',
        operators: ['equal'],
        values: {
          'Male': 'Мужской',
          'Female': 'Женский'
        },
      },
      {
        id: 'cdp_profiles.personal_email',
        label: 'Email',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty'],
        validations: ['email']
      },
      {
        id: 'cdp_profiles.personal_phone',
        label: 'Телефон',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty'],
        validations: ['phone']
      },
      {
        id: 'cdp_profiles.bio_birth',
        label: 'Дата рождения',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
        validations: ['date']
      },
      {
        id: "cdp_profiles.age",
        field: "DATE_PART('YEAR', AGE(CURRENT_DATE, cdp_profiles.bio_birth))",
        label: 'Возраст',
        optgroup: "Профиль",
        type: 'integer',
        input: 'number',
        data: {
          field_type: 'raw'
        },
        operators: ['equal', 'not_equal', 'in', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'not_in', 'is_null', 'is_not_null'],
        validations: ['number']
      },
      {
        id: 'cdp_profiles.personal_city',
        label: 'Город',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty']
      },
      {
        id: 'cdp_profiles.personal_country',
        label: 'Страна',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty']
      },
      {
        id: 'cdp_profiles.interests',
        label: 'Интересы',
        optgroup: "Профиль",
        type: 'string',
        input: 'select',
        values: {
          'Электронная коммерция': 'Электронная коммерция',
          'Образование': 'Образование',
          'Игры': 'Игры',
          'Путешествия': 'Путешествия',
          'Еда': 'Еда',
          'Форумы': 'Форумы',
          'Компьютеры и технологии': 'Компьютеры и технологии',
          'Для взрослых': 'Для взрослых',
          'Новости': 'Новости',
          'Спорт': 'Спорт',
          'Здоровье': 'Здоровье',
          'Социальные сети': 'Социальные сети',
          'Стриминговые сервисы': 'Стриминговые сервисы',
          'Закон и правительство': 'Закон и правительство',
          'Бизнес': 'Бизнес'
        },
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.current_time_utc',
        label: 'Часовой пояс',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty']
      },
      {
        id: 'cdp_profiles.personal_data_legals',
        label: 'Согласие на обработку перс. данных',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.ids_socials_vk',
        label: 'Список ID ВКонтакте',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.ids_socials_odn',
        label: 'Список ID Одноклассники',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.ids_pixels_yandex',
        label: 'Список ID Яндекс',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.ids_pixels_mailru',
        label: 'Список ID Mail.ru',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.ids_pixels_google',
        label: 'Список ID Google',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_count',
        label: 'Количество визитов',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
        validations: ['number']
      },
      {
        id: 'cdp_profiles.visits_urls',
        label: 'Посещенные страницы',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_avg_surfing_times_of_day',
        label: 'Среднее время интернет-серфинга в день',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
        validations: ['number']
      },
      {
        id: 'cdp_profiles.visits_avg_duration_sec',
        label: 'Общее количество секунд на сайтах',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
        validations: ['number']
      },
      {
        id: 'cdp_profiles.created_at',
        label: 'Дата создания',
        optgroup: "Профиль",
        type: 'date',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
        validations: ['dateTime']
      },
      {
        id: 'cdp_profiles.updated_at',
        label: 'Дата обновления',
        optgroup: "Профиль",
        type: 'date',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
        validations: ['dateTime']
      },
      {
        id: 'cdp_profiles.visits_ips',
        label: 'IP-адреса',
        optgroup: "Профиль",
        type: 'string',
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.mac_address',
        label: 'MAC-адрес',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_fingerprint_ids',
        label: 'Fingerprint IDs',
        optgroup: "Профиль",
        type: 'string',
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.sms_consent',
        label: 'Согласие на sms-рассылки',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal'],
        values: {
          'true': 'Да',
          'false': 'Нет'
        },
      },
      {
        id: 'cdp_profiles.email_consent',
        label: 'Согласие на email-рассылки',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal'],
        values: {
          'true': 'Да',
          'false': 'Нет'
        },
      },
      {
        id: 'cdp_profiles.visits_session_ids',
        label: 'Cписок ID сессий',
        optgroup: "Профиль",
        type: 'string',
        operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_url_keywords',
        label: 'Параметры из URL',
        optgroup: "Профиль",
        type: 'string',
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_utm_keywords',
        label: 'UTM Метки',
        optgroup: "Профиль",
        type: 'string',
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_device_types',
        label: 'Типы устройств',
        optgroup: "Профиль",
        type: 'string',
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_useragents',
        label: 'Useragents',
        optgroup: "Профиль",
        type: 'string',
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_devices',
        label: 'Устройства',
        optgroup: "Профиль",
        type: 'string',
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      },
      {
        id: 'cdp_profiles.visits_display_resolutions',
        label: 'Разрешение экранов',
        optgroup: "Профиль",
        type: 'string',
        operators: ['contains', 'not_contains', 'in', 'not_in', 'is_null', 'is_not_null']
      }
    ]
  }
]

const eventConfigFields = {
  PageView: [
    {
      id: 'cdp_events.created_at',
      label: 'Дата и время',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
      validations: ['dateTime', 'required']
    },
    {
      id: 'cdp_events.url',
      label: 'URL',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'],
    },
    {
      id: 'cdp_events.pixel_id',
      label: 'Источник',
      optgroup: "События",
      type: 'integer',
      input: 'select',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: [],
      validations: ['requiredIf']
    }
  ],
  Click: [
    {
      id: 'cdp_events.created_at',
      label: 'Дата и время',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
      validations: ['dateTime', 'required']
    },
    {
      id: 'cdp_events.url',
      label: 'URL',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'],
    },
    {
      id: 'cdp_events.pixel_id',
      label: 'Источник',
      optgroup: "События",
      type: 'integer',
      input: 'select',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: [],
      validations: ['requiredIf']
    }
  ],
  Hover: [
    {
      id: 'cdp_events.url',
      label: 'URL',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'],
    },
    {
      id: 'cdp_events.pixel_id',
      label: 'Источник',
      optgroup: "События",
      type: 'integer',
      input: 'select',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: [],
      validations: ['requiredIf']
    }
  ],
  FormSubmit: [
    {
      id: 'cdp_events.created_at',
      label: 'Дата и время',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
      validations: ['dateTime', 'required']
    },
    {
      id: 'cdp_events.url',
      label: 'URL',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'],
    },
    {
      id: 'cdp_events.pixel_id',
      label: 'Источник',
      optgroup: "События",
      type: 'integer',
      input: 'select',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: [],
      validations: ['requiredIf']
    }
  ],
  EnteredSegment: [
    {
      id: 'cdp_events.created_at',
      label: 'Дата и время',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
      validations: ['dateTime', 'required']
    },
    {
      id: 'cdp_events.segment_id',
      label: 'Сегмент',
      field: "cdp_events.additional_event_data->>'id'",
      optgroup: "События",
      type: 'integer',
      input: 'select',
      data: {
        field_type: 'raw'
      },
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: [],
      validations: ['requiredIf']
    },
  ],
  ExitedSegment: [
    {
      id: 'cdp_events.created_at',
      label: 'Дата и время',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
      validations: ['dateTime', 'required']
    },
    {
      id: 'cdp_events.segment_id',
      label: 'Сегмент',
      field: "cdp_events.additional_event_data->>'id'",
      optgroup: "События",
      type: 'integer',
      input: 'select',
      data: {
        field_type: 'raw'
      },
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: [],
      validations: ['requiredIf']
    },
  ],
  ProfileFieldChanged: [
    {
      id: 'cdp_events.all_changed_fields',
      label: 'Любое поле',
      optgroup: "События",
      type: 'string',
      input: 'empty',
      operators: ['contains'],
      values: []
    },
    {
      id: 'cdp_events.created_at',
      label: 'Дата и время события',
      optgroup: "События",
      type: 'string',
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
      validations: ['dateTime', 'required']
    },
    {
      id: 'pixel_id',
      label: 'Источник (ID пикселя)',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'integer',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains'],
      values: 'pixel_id'
    },
    {
      id: 'personal_name',
      label: 'Имя',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    }, {
      id: 'personal_surname',
      label: 'Фамилия',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'bio_gender',
      label: 'Пол',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains'],
      values: {
        'Male': 'Мужской',
        'Female': 'Женский'
      },
    },
    {
      id: 'personal_email',
      label: 'Email',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains'],
      validations: ['email']
    },
    {
      id: 'personal_phone',
      label: 'Телефон',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains'],
      validations: ['phone']
    },
    {
      id: 'bio_birth',
      label: 'Дата рождения',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains'],
      validations: ['date']
    },
    {
      id: 'personal_city',
      label: 'Город',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'personal_country',
      label: 'Страна',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'interests',
      label: 'Интересы',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      values: {
        'Электронная коммерция': 'Электронная коммерция',
        'Образование': 'Образование',
        'Игры': 'Игры',
        'Путешествия': 'Путешествия',
        'Еда': 'Еда',
        'Форумы': 'Форумы',
        'Компьютеры и технологии': 'Компьютеры и технологии',
        'Для взрослых': 'Для взрослых',
        'Новости': 'Новости',
        'Спорт': 'Спорт',
        'Здоровье': 'Здоровье',
        'Социальные сети': 'Социальные сети',
        'Стриминговые сервисы': 'Стриминговые сервисы',
        'Закон и правительство': 'Закон и правительство',
        'Бизнес': 'Бизнес'
      },
      operators: ['contains']
    },
    {
      id: 'current_time_utc',
      label: 'Часовой пояс',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'personal_data_legals',
      label: 'Согласие на обработку перс. данных',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'ids_socials_vk',
      label: 'Список ID ВКонтакте',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'ids_socials_odn',
      label: 'Список ID Одноклассники',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'ids_pixels_yandex',
      label: 'Список ID Яндекс',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'ids_pixels_mailru',
      label: 'Список ID Mail.ru',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'ids_pixels_google',
      label: 'Список ID Google',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_count',
      label: 'Количество визитов',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_urls',
      label: 'Посещенные страницы',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_avg_surfing_times_of_day',
      label: 'Среднее время интернет-серфинга в день',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_avg_duration_sec',
      label: 'Общее количество секунд на сайтах',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'created_at',
      label: 'Дата создания',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'date',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains'],
      validations: ['date']
    },
    {
      id: 'updated_at',
      label: 'Дата обновления',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'date',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains'],
      validations: ['date']
    },
    {
      id: 'visits_ips',
      label: 'IP-адреса',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'mac_address',
      label: 'MAC-адрес',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_fingerprint_ids',
      label: 'Fingerprint IDs',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'mailing_consent',
      label: 'Согласие на рассылку',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_session_ids',
      label: 'Cписок ID сессий',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_url_keywords',
      label: 'Параметры из URL',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_utm_keywords',
      label: 'UTM Метки',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_device_types',
      label: 'Типы устройств',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_useragents',
      label: 'Useragents',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_devices',
      label: 'Устройства',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    },
    {
      id: 'visits_display_resolutions',
      label: 'Разрешение экранов',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "Профиль",
      type: 'string',
      input: 'empty',
      data: {
        field_type: 'raw'
      },
      operators: ['contains']
    }
  ],
  EmailStatusChanged: [
    {
      id: 'cdp_events.old_state',
      label: 'Старый статус',
      field: "cdp_events.additional_event_data->'data'->>'old_state'",
      optgroup: "Старый статус",
      type: 'string',
      input: 'select',
      data: {
        field_type: 'raw'
      },
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: {
        'Delivered': 'Доставлено',
        'Sent': 'Отправлено',
        'Unsubscribed': 'Отписался',
        'Opened': 'Открыто',
        'Clicked': 'Переход по ссылкам',
        'Pending': 'Ожидает',
        'Sending': 'Отправляется',
        'Error': 'Ошибка доставки',
        'Spam': 'Спам',
        'SoftBounced': 'SoftBounced',
        'HardBounced': 'HardBounced'
      }
    },
    {
      id: 'cdp_events.new_state',
      label: 'Новый статус',
      field: "cdp_events.additional_event_data->'data'->>'new_state'",
      optgroup: "События",
      type: 'string',
      input: 'select',
      data: {
        field_type: 'raw'
      },
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: {
        'Delivered': 'Доставлено',
        'Sent': 'Отправлено',
        'Unsubscribed': 'Отписался',
        'Opened': 'Открыто',
        'Clicked': 'Переход по ссылкам',
        'Pending': 'Ожидает',
        'Sending': 'Отправляется',
        'Error': 'Ошибка доставки',
        'Spam': 'Спам',
        'SoftBounced': 'SoftBounced',
        'HardBounced': 'HardBounced'
      }
    },
    {
      id: 'cdp_events.email_campaign_id',
      label: 'Кампания',
      field: "cdp_events.additional_event_data->'data'->>'emailable_id'",
      accompaniment: [{field: "cdp_events.additional_event_data->'data'->>'emailable_type'", value: 'App\\Models\\EmailCampaign'}],
      optgroup: "События",
      type: 'integer',
      input: 'select',
      data: {
        field_type: 'raw'
      },
      operators: ['equal', 'not_equal', 'in', 'not_in', 'is_null', 'is_not_null'],
      values: [],
      validations: ['requiredIf']
    },
  ]
}

export {
  queryFields,
  eventConfigFields
}