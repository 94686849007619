const profileParams = [
  { text: 'Имя', value: '{{personal_name}}',  },
  { text: 'Фамилия', value: '{{personal_surname}}' },
  { text: 'E-mail', value: '{{personal_email}}' },
  { text: 'Телeфон', value: '{{personal_phone}}' },
  { text: 'Пол', value: '{{bio_gender}}' },
  { text: 'Дата рождения', value: '{{bio_birth}}' },
  { text: 'Город', value: '{{personal_city}}' },
  { text: 'Страна', value: '{{personal_country}}' }
]
const exportProfileParams = [
  {
    section: 'ЛИЧНЫЕ ДАННЫЕ',
    data: [
      [
        {
          name: 'pixel_id',
          text: 'Источник',
          selected: 'false',
        },
        {
          name: 'personal_name',
          text: 'Имя',
          selected: 'false',
        },
      ],
      [
        {
          name: 'personal_surname',
          text: 'Фамилия',
          selected: 'false',
        },
        {
          name: 'bio_gender',
          text: 'Пол',
          selected: 'false',
        },
        /*{
          name: 'bio_gender_auto',
          text: 'Возможный пол',
          selected: 'false',
        }*/
      ],
      [
        {
          name: 'bio_birth',
          text: 'Дата рождения',
          selected: 'false',
        },
        {
          name: 'interests',
          text: 'Интересы',
          selected: 'false',
        },
        /*{
          name: 'bio_weight',
          text: 'Телосложение',
          selected: 'false',
        },
        {
          name: 'bio_height',
          text: 'Рост',
          selected: 'false',
        }*/
      ],
      /*[
        {
          name: 'has_children',
          text: 'Есть дети',
          selected: 'false',
        },
        {
          name: 'is_married',
          text: 'Женат/Замужем',
          selected: 'false',
        },
        {
          name: 'has_pet',
          text: 'Есть животные',
          selected: 'false',
        },
        {
          name: 'has_car',
          text: 'Есть машина',
          selected: 'false',
        }
      ]*/
    ]
  },
  {
    section: 'КОНТАКТНЫЕ ДАННЫЕ',
    data: [  
      [
        {
          name: 'personal_phone',
          text: 'Телефон',
          selected: 'false',
        },
        {
          name: 'personal_email',
          text: 'Email',
          selected: 'false',
        },
        {
          name: 'ids_socials_vk',
          text: 'Список ID ВКонтакте',
          selected: 'false',
        }
      ],
      [
        {
          name: 'ids_socials_odn',
          text: 'Список ID Одноклассники',
          selected: 'false',
        },
        {
          name: 'ids_pixels_yandex',
          text: 'Список ID Яндекс',
          selected: 'false',
        }
      ],
      [
        {
          name: 'ids_pixels_mailru',
          text: 'Список ID Mail.ru',
          selected: 'false',
        },
        {
          name: 'ids_pixels_google',
          text: 'Список ID Google',
          selected: 'false',
        }
      ]
    ]
  },
  {
    section: 'ГЕОГРАФИЯ',
    data: [
      [
        {
          name: 'personal_country',
          text: 'Страна',
          selected: 'false',
        },
      ],
      [
        {
          name: 'personal_city',
          text: 'Город',
          selected: 'false',
        },
      ],
      [
        {
          name: 'current_time_utc',
          text: 'Часовой пояс',
          selected: 'false',
        },
        /*{
          name: 'geo_positions',
          text: 'geo_positions',
          selected: 'false',
        }*/
      ],
      /*[
        {
          name: 'geo_position_auto',
          text: 'geo_position_auto',
          selected: 'false',
        },
        {
          name: 'geo_cities',
          text: 'geo_cities',
          selected: 'false',
        },
        {
          name: 'geo_countries',
          text: 'geo_countries',
          selected: 'false',
        }
      ],
      [
        {
          name: 'geo_position_by_ip',
          text: 'geo_position_by_ip',
          selected: 'false',
        },
        {
          name: 'geo_position_by_gps',
          text: 'geo_position_by_gps',
          selected: 'false',
        },
        {
          name: 'geo_position_receipts',
          text: 'geo_position_receipts',
          selected: 'false',
        }
      ]*/
    ]
  },
  {
    section: 'ДРУГОЕ',
    data: [
      [
        {
          name: 'created_at',
          text: 'Дата создания',
          selected: 'false',
        },
        {
          name: 'updated_at',
          text: 'Дата обновления',
          selected: 'false',
        },
        {
          name: 'visits_ips',
          text: 'IP-адреса',
          selected: 'false',
        },
        {
          name: 'visits_fingerprint_ids',
          text: 'Fingerprint IDs',
          selected: 'false',
        },
        {
          name: 'visits_display_resolutions',
          text: 'Разрешение экранов',
          selected: 'false',
        },
        {
          name: 'visits_useragents',
          text: 'Useragents',
          selected: 'false',
        },
        /*{
          name: 'mongo_profile_mold',
          text: 'mongo_profile_mold',
          selected: 'false',
        },
        {
          name: 'uid',
          text: 'uid',
          selected: 'false',
        },
        {
          name: 'personal_other_names',
          text: 'Возможные имена',
          selected: 'false',
        },
        {
          name: 'personal_other_languages',
          text: 'Возможные языки',
          selected: 'false',
        },*/
        /*{
          name: 'communication_legals',
          text: 'communication_legals',
          selected: 'false',
        },
        {
          name: 'ids_types',
          text: 'ids_types',
          selected: 'false',
        },
        {
          name: 'ids_phones',
          text: 'ids_phones',
          selected: 'false',
        },
        {
          name: 'ids_emails',
          text: 'ids_emails',
          selected: 'false',
        }*/
      ],
      [
        /*{
          name: 'activities',
          text: 'activities',
          selected: 'false',
        },
        {
          name: 'activity_dates',
          text: 'activity_dates',
          selected: 'false',
        },
        {
          name: 'visits',
          text: 'visits',
          selected: 'false',
        },*/
        {
          name: 'visits_urls',
          text: 'Посещенные страницы',
          selected: 'false',
        },
        {
          name: 'visits_session_ids',
          text: 'Cписок ID сессий',
          selected: 'false',
        },
        {
          name: 'visits_utm_keywords',
          text: 'UTM Метки',
          selected: 'false',
        },
        {
          name: 'visits_device_types',
          text: 'Типы устройств',
          selected: 'false',
        },
        {
          name: 'visits_devices',
          text: 'Устройства',
          selected: 'false',
        },
        {
          name: 'visits_url_keywords',
          text: 'Параметры из URL',
          selected: 'false',
        },
      ],
      [
        {
          name: 'visits_avg_surfing_times_of_day',
          text: 'Среднее время интернет-серфинга в день',
          selected: 'false',
        },
        {
          name: 'visits_count',
          text: 'Количество визитов',
          selected: 'false',
        },
        {
          name: 'personal_data_legals',
          text: 'Согласие на обработку перс. данных',
          selected: 'false',
        },
        {
          name: 'mailing_consent',
          text: 'Согласие на рассылку',
          selected: 'false',
        },
        {
          name: 'visits_avg_surfing_times_of_day',
          text: 'Общее количество секунд на сайтах',
          selected: 'false',
        },
        /*{
          name: 'visits_sent_forms',
          text: 'visits_sent_forms',
          selected: 'false',
        },*/
        /*{
          name: 'visits_form_personals',
          text: 'visits_form_personals',
          selected: 'false',
        }*/
      ],
    ]
  }
]

export {
  profileParams,
  exportProfileParams
}