import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'

const toast = useToast()

export const useStatisticsStore = defineStore({
  id: 'statistics',
  state: () => ({
    waveDataY: [],
    waveDataX: [],
    filterType: 'daily',
    companyId: 'all',
    pixelStats: [],
    filterPixelType: 'daily',
    pixelCurrentPage: 1,
    pixelPerPage: 6,
    pixelLastPage: 1,
    segmentStats: [],
    filterSegmentType: 'daily',
    segmentCurrentPage: 1,
    segmentPerPage: 6,
    segmentLastPage: 1,
    insightStats: [],
    filterInsightType: 'daily',
    insightCurrentPage: 1,
    insightPerPage: 6,
    insightLastPage: 1,
    campaignSmsStats: [],
    campaignSmsCurrentPage: 1,
    campaignSmsPerPage: 2,
    campaignSmsLastPage: 1,
    campaignEmailStats: [],
    campaignEmailCurrentPage: 1,
    campaignEmailPerPage: 2,
    campaignEmailLastPage: 1,
    campaignSmsPushStats: [],
    campaignSmsPushCurrentPage: 1,
    campaignSmsPushPerPage: 2,
    campaignSmsPushLastPage: 1,
    campaignViberStats: [],
    campaignViberCurrentPage: 1,
    campaignViberPerPage: 2,
    campaignViberLastPage: 1,
    isLoading: false,
    isFlowLoading: false,
    isPixelLoading: false,
    isSegmentLoading: false,
    isInsightLoading: false,
    isCampaignSmsLoading: false,
    isCampaignEmailLoading: false,
    isCampaignSmsPushLoading: false,
  }),
  getters: {
    pixelData: (state) => {
      let pixelData = {params: { page: state.pixelCurrentPage, per_page: state.pixelPerPage }}

      if (state.companyId !== '' && state.companyId !== 'all') {
        pixelData.params['company_id'] = parseInt(state.companyId)
      }

      return pixelData
    },
    segmentData: (state) => {
      let segmentData = {params: { page: state.segmentCurrentPage, per_page: state.segmentPerPage }}

      if (state.companyId !== '' && state.companyId !== 'all') {
        segmentData.params['company_id'] = parseInt(state.companyId)
      }

      return segmentData
    },
    insightData: (state) => {
      let insightData = {params: { page: state.insightCurrentPage, per_page: state.insightPerPage }}

      if (state.companyId !== '' && state.companyId !== 'all') {
        insightData.params['company_id'] = parseInt(state.companyId)
      }

      return insightData
    },
    сampaignsSmsData: (state) => {
      let сampaignsSmsData = {params: { page: state.campaignSmsCurrentPage, per_page: state.campaignSmsPerPage, states: ['Finished', 'Sending'] }}

      if (state.companyId !== '' && state.companyId !== 'all') {
        сampaignsSmsData.params['company_id'] = parseInt(state.companyId)
      }

      return сampaignsSmsData
    },
    сampaignsEmailData: (state) => {
      let сampaignsEmailData = {params: { page: state.campaignEmailCurrentPage, per_page: state.campaignEmailPerPage, states: ['Finished', 'Sending'] }}

      if (state.companyId !== '' && state.companyId !== 'all') {
        сampaignsEmailData.params['company_id'] = parseInt(state.companyId)
      }

      return сampaignsEmailData
    },
    сampaignsSmsPushData: (state) => {
      let сampaignsSmsPushData = {params: { page: state.campaignSmsPushCurrentPage, per_page: state.campaignSmsPushPerPage, states: ['Finished', 'Sending'] }}

      if (state.companyId !== '' && state.companyId !== 'all') {
        сampaignsSmsPushData.params['company_id'] = parseInt(state.companyId)
      }

      return сampaignsSmsPushData
    },
    сampaignsViberData: (state) => {
      let сampaignsViberData = {params: { page: state.campaignViberCurrentPage, per_page: state.campaignViberPerPage, states: ['Finished', 'Sending'] }}

      if (state.companyId !== '' && state.companyId !== 'all') {
        сampaignsViberData.params['company_id'] = parseInt(state.companyId)
      }

      return сampaignsViberData
    },
    preparedPixelStats: (state) => {
      let size = 3
      let stats = state.pixelStats

      stats = Array.from({ length: Math.ceil(state.pixelStats.length / size) }, (v, i) =>
        stats.slice(i * size, i * size + size)
      )

      stats = stats.map((arrayPixels, index) => {
        return {
          id: (index + 1),
          array: arrayPixels.map(pixel => {
            let tempPixel = {
              id: pixel.id,
              name: pixel.name,
              waveDataY: [],
              waveDataX: []
            }

            if (pixel.statistics !== null && pixel.statistics !== undefined) {
              tempPixel.waveDataY = [
                {
                  name: 'Все профили в базе',
                  data: pixel.statistics.total_profile_count.map(profileCount =>  parseInt(profileCount))
                }, 
                {
                  name: 'Уникальные профили',
                  data: pixel.statistics.total_unique_profile_count.map(profileCount =>  parseInt(profileCount))
                }
              ]
              tempPixel.waveDataX = pixel.statistics.labels
            }

            return tempPixel
          })
        }
      })

      return stats
    },
    preparedSegmentStats: (state) => {
      let size = 3
      let stats = state.segmentStats

      stats = Array.from({ length: Math.ceil(state.segmentStats.length / size) }, (v, i) =>
        stats.slice(i * size, i * size + size)
      )

      stats = stats.map((arraySegments, index) => {
        return {
          id: (index + 1),
          array: arraySegments.map(segment => {
            let tempSegment = {
              id: segment.id,
              name: segment.name,
              waveDataY: [],
              waveDataX: []
            }

            if (segment.statistics !== null && segment.statistics !== undefined) {
              tempSegment.waveDataY = [
                {
                  name: 'Все профили в базе',
                  data: segment.statistics.total_profile_count.map(profileCount =>  parseInt(profileCount))
                }, 
                {
                  name: 'Уникальные профили',
                  data: segment.statistics.total_unique_profile_count.map(profileCount =>  parseInt(profileCount))
                }
              ]
              tempSegment.waveDataX = segment.statistics.labels
            }

            return tempSegment
          })
        }
      })

      return stats
    },
    preparedInsightStats: (state) => {
      let size = 3
      let stats = state.insightStats

      stats = Array.from({ length: Math.ceil(state.insightStats.length / size) }, (v, i) =>
        stats.slice(i * size, i * size + size)
      )

      stats = stats.map((arrayInsights, index) => {
        return {
          id: (index + 1),
          array: arrayInsights.map(insight => {
            let tempInsight = {
              id: insight.id,
              name: insight.name,
              waveDataY: [],
              waveDataX: []
            }

            if (insight.statistics !== null && insight.statistics !== undefined) {
              tempInsight.waveDataY = [
                {
                  name: 'Все профили в базе',
                  data: insight.statistics.total_profile_count.map(profileCount =>  parseInt(profileCount))
                }, 
                {
                  name: 'Уникальные профили',
                  data: insight.statistics.total_unique_profile_count.map(profileCount =>  parseInt(profileCount))
                }
              ]
              tempInsight.waveDataX = insight.statistics.labels
            }

            return tempInsight
          })
        }
      })

      return stats
    },
    preparedCampaignSmsStats: (state) => state.campaignSmsStats.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        state: campaign.state,
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0
      }
    }),
    preparedCampaignEmailStats: (state) => state.campaignEmailStats.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        state: campaign.state,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0,
        opened: campaign?.stats_opened_count || 0,
        clicked: campaign?.stats_clicked_count || 0
      }
    }),
    preparedCampaignSmsPushStats: (state) => state.campaignSmsPushStats.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        state: campaign.state,
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0
      }
    }),
    preparedCampaignViberStats: (state) => state.campaignViberStats.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        state: campaign.state,
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0
      }
    }),
  },
  actions: {
    setChartsDefault() {
      this.waveDataY = []
      this.waveDataX = []
      this.companyId = 'all'
      this.filterPixelType = 'daily'
      this.filterSegmentType = 'daily'
      this.filterInsightType = 'daily'
      this.isLoading = false
    },
    setPixelPaginationDefault() {
      this.pixelCurrentPage = 1
      this.pixelPerPage = 6
      this.pixelLastPage = 1
      this.segmentCurrentPage = 1
      this.segmentPerPage = 6
      this.segmentLastPage = 1
      this.insightCurrentPage = 1
      this.insightPerPage = 6
      this.insightLastPage = 1
    },
    async getFlowStats() {
      this.isFlowLoading = true
      axios
        .get(`api/v1/stats/${this.filterType}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.waveDataY = [
              {
                name: 'Все профили в базе',
                data: response.data.total_profile_count.map(profileCount =>  parseInt(profileCount))
              }, 
              {
                name: 'Уникальные профили',
                data: response.data.total_unique_profile_count.map(profileCount =>  parseInt(profileCount))
              }
            ]
            this.waveDataX = response.data.labels
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isFlowLoading = false
        })
    },
    async getMyCompanyFlowStats() {
      this.isFlowLoading = true
      axios
        .get(`api/v1/my_company/stats/${this.filterType}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.waveDataY = [
              {
                name: 'Все профили в базе',
                data: response.data.total_profile_count.map(profileCount =>  parseInt(profileCount))
              }, 
              {
                name: 'Уникальные профили',
                data: response.data.total_unique_profile_count.map(profileCount =>  parseInt(profileCount))
              }
            ]
            this.waveDataX = response.data.labels
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isFlowLoading = false
        })
    },
    async getFlowCompanyStats() {
      this.isFlowLoading = true
      axios
        .get(`api/v1/companies/${this.companyId}/stats/${this.filterType}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.waveDataY = [
              {
                name: 'Все профили в базе',
                data: response.data.total_profile_count.map(profileCount =>  parseInt(profileCount))
              }, 
              {
                name: 'Уникальные профили',
                data: response.data.total_unique_profile_count.map(profileCount =>  parseInt(profileCount))
              }
            ]
            this.waveDataX = response.data.labels
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isFlowLoading = false
        })
    },
    async getPixelStats() {
      this.isPixelLoading = true
      axios
        .get(`/api/v1/pixels/stats/${this.filterPixelType}`, this.pixelData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.pixelStats = response.data.data
            this.pixelCurrentPage = response.data.current_page
            this.pixelLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isPixelLoading = false
        })
    },
    async getSegmentStats() {
      this.isSegmentLoading = true
      axios
        .get(`/api/v1/cdp_segments/stats/${this.filterSegmentType}`, this.segmentData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.segmentStats = response.data.data
            this.segmentCurrentPage = response.data.current_page
            this.segmentLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isSegmentLoading = false
        })
    },
    async getInsightStats() {
      this.isInsightLoading = true
      axios
        .get(`/api/v1/cdp_insights/stats/${this.filterInsightType}`, this.insightData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.insightStats = response.data.data
            this.insightCurrentPage = response.data.current_page
            this.insightLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isInsightLoading = false
        })
    },
    async getCampaignSmsStats() {
      this.isCampaignSmsLoading = true
      axios
        .get('api/v1/sms_campaigns', this.сampaignsSmsData)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaignSmsStats = response.data.data
            this.campaignSmsCurrentPage = response.data.current_page
            this.campaignSmsLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isCampaignSmsLoading = false
        })
    },
    async getCampaignEmailStats() {
      this.isCampaignEmailLoading = true
      axios
        .get('api/v1/email_campaigns', this.сampaignsEmailData)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaignEmailStats = response.data.data
            this.campaignEmailCurrentPage = response.data.current_page
            this.campaignEmailLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isCampaignEmailLoading = false
        })
    },
    async getCampaignSmsPushStats() {
      this.isCampaignSmsPushLoading = true
      axios
        .get('api/v1/mobile_push_campaigns', this.сampaignsSmsPushData)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaignSmsPushStats = response.data.data
            this.campaignSmsPushCurrentPage = response.data.current_page
            this.campaignSmsPushLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isCampaignSmsPushLoading = false
        })
    },
    async getCampaignViberStats() {
      this.isCampaignSmsPushLoading = true
      axios
        .get('api/v1/viber_campaigns', this.сampaignsViberData)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaignViberStats = response.data.data
            this.campaignSmsPushCurrentPage = response.data.current_page
            this.campaignSmsPushLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isCampaignSmsPushLoading = false
        })
    },
  },
})