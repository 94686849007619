import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useCampaignsViberStore = defineStore ({
  id: 'campaignsViber',
  state: () => ({
    campaignId: '',
    campaigns: [],
    campaignsCount: 0,
    campaignLastPage: 1,
    campaignCurrentPage: 1,
    campaignsActiveCount: 0,
    campaignsFinishedCount: 0,
    campaignAllRecipients: 0,
    campaignPerPage: 10,
    campaignSent: 0,
    campaignDelivered: 0,
    campaignErrors: 0,
    campaignOpened: 0,
    campaignClicked: 0,
    campaignType: 'Default',
    campaignPeriodType: "Daily",
    campaignDispatchType: 'Now',
    campaignDispatchDate: '',
    campaignName: '',
    campaignState: '',
    campaignSegmentId: '',
    campaignSegmentName: '',
    campaignExcludedSegment: [],
    campaignAddressees: [],
    campaignOwnerName: 'Admin',
    campaignStartAt: '',
    campaignViberBody: '',
    campaignViberImg: '',
    campaignBtnText: '',
    campaignBtnLink: '',
    campaignProgress: '',
    campaignCompanyId: '',
    campaignTestAddressees: '',
    campaignViberIDId: '',
    campaignViberIDName: '',
    viberTemplates: [],
    viberTemplatesCount: '',
    viberTemplateId: '',
    viberTemplateName: '',
    viberTemplateBody: '',
    viberTemplateCompanyId: '',
    viberTemplateImg: '',
    viberTemplateButtonText: '',
    viberTemplateButtonLink: '',
    viberIDs: [],
    viberIDsCount: '',
    viberIDId: '',
    viberIDCompanyId: '',
    viberIDName: '',
    viberIDApp: '',
    viberIDState: '',
    viberIdCreatedAt: '',
    filterFrom: '',
    filterTo: '',
    filterSample: '',
    filterNameCampaigns: '',
    filterPhone: '',
    isLoading: false,
  }),
  getters: {
    viberCampaignData: (state) => {
      let viberCampaignData = new FormData()
      if (state.campaignName) {
        viberCampaignData.append('name', state.campaignName)
      }
      if (state.campaignCompanyId) {
        viberCampaignData.append('company_id', parseInt(state.campaignCompanyId))
      }
      if (state.campaignViberIDId) {
        viberCampaignData.append('viber_id_id', parseInt(state.campaignViberIDId))
      }
      if (state.campaignViberBody) {
        viberCampaignData.append('message', state.campaignViberBody)
      }
      if (state.campaignViberImg) {
        viberCampaignData.append('image', state.campaignViberImg)
      }
      if (state.campaignBtnText) {
        viberCampaignData.append('btn_name', state.campaignBtnText)
      }
      if (state.campaignBtnLink) {
        viberCampaignData.append('btn_url', state.campaignBtnLink)
      }
      if(state.campaignDispatchType) {
        viberCampaignData.append('send_time_kind', state.campaignDispatchType)
        if(state.campaignDispatchType == 'Later') {
          viberCampaignData.append('send_at', state.campaignDispatchDate)
        }
      }
      if(state.campaignType) {
        viberCampaignData.append('send_type', state.campaignType)
        if(state.campaignType == 'Periodic') {
          iberCampaignData.append('send_period', state.campaignPeriodType)
        }
      }
      if(state.campaignType) {
        viberCampaignData.append('send_type', state.campaignType)
      }
      if (state.campaignSegmentId) {
        viberCampaignData.append('recipients_sourceable_id', state.campaignSegmentId)
      }
      if (state.campaignExcludedSegment.length > 0) {
        viberCampaignData.append('excluded_segment_ids', state.campaignExcludedSegment.map(segmentId => parseInt(segmentId)))
      }
      viberCampaignData.append('recipients_sourceable_type', '\\App\\Models\\CdpSegment')
      if (state.campaignState === 'Draft') {
        viberCampaignData.append('state', 'Draft')
      }

      return viberCampaignData
    },
    viberFilterCampaignData: (state) => {
      let viberFilterCampaignData = {}
      if(state.filterFrom) {
        viberFilterCampaignData['from'] = state.filterFrom
      }
      if(state.filterTo) {
        viberFilterCampaignData['to'] = state.filterTo
      }
      if(state.filterNameCampaigns) {
        viberFilterCampaignData['q'] = state.filterNameCampaigns
      }
      if(state.filterPhone) {
        viberFilterCampaignData['phone'] = state.filterPhone
      }
      viberFilterCampaignData['page'] = state.campaignCurrentPage
      viberFilterCampaignData['per_page'] = state.campaignPerPage

      return viberFilterCampaignData
    },
    preparedCalendarCampaigns: (state) => state.campaigns.filter(campaign => campaign.sent_at !== null).map(campaign => {
      return {
        id: campaign.id.toString(),
        customData: {
          id: campaign.id.toString(),
          title: campaign.name,
          class: 'is-has-text-default'
        },
        dates: campaign.sent_at
      }
    }),
    viberTemplateData: (state) => {
      let viberTemplateData = new FormData()
      if (state.viberTemplateName) {
        viberTemplateData.append('name', state.viberTemplateName)
      }
      if (state.viberTemplateBody) {
        viberTemplateData.append('message', state.viberTemplateBody)
      }
      if (state.viberTemplateCompanyId) {
        viberTemplateData.append('company_id', parseInt(state.viberTemplateCompanyId))
      }
      if (state.viberTemplateImg) {
        viberTemplateData.append('image', state.viberTemplateImg)
      }
      if (state.viberTemplateButtonText) {
        viberTemplateData.append('btn_name', state.viberTemplateButtonText)
      }
      if (state.viberTemplateButtonLink) {
        viberTemplateData.append('btn_url', state.viberTemplateButtonLink)
      }
      return viberTemplateData
    },
    viberIDData: (state) => {
      let viberIDData = {}
      if (state.viberIDName) {
        viberIDData['name'] = state.viberIDName
      }
      if (state.viberIDCompanyId) {
        viberIDData['company_id'] = parseInt(state.viberIDCompanyId)
      }
      if (state.viberIDApp) {
        viberIDData['viber_id'] = state.viberIDApp
      }
      if (state.viberIDState) {
        viberIDData['state'] = state.viberIDState
      }
      
      return viberIDData
    },
    viberTestCampaignData: (state) => {
      let viberTestCampaignData = {}

      if(state.campaignTestAddressees) {
        viberTestCampaignData['phones'] = state.campaignTestAddressees.split(' ').join('\n')
      }
      if(state.campaignViberBody) {
        viberTestCampaignData['message'] = state.campaignViberBody
      }
      if(state.campaignViberIDId) {
        viberTestCampaignData['viber_id_id'] = parseInt(state.campaignViberIDId)
      }
      if(state.campaignCompanyId) {
        viberTestCampaignData['company_id'] = parseInt(state.campaignCompanyId)
      }

      return viberTestCampaignData
    },
    preparedCampaigns: (state) => state.campaigns.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        startAt: campaign.send_at !== null ? campaign.send_at : '',
        createdAt: campaign.created_at !== null ? campaign.created_at : '',
        state: campaign.state,
        segmentName: campaign?.recipients_sourceable?.name || campaign?.segment_name || '-',
        ownerName: campaign?.author_name || '',
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0
      }
    }),
    preparedAddressees: (state) => state.campaignAddressees.map(campaignAddressee => {
      return {
        id: campaignAddressee.id.toString(),
        phone: campaignAddressee.phone,
        state: campaignAddressee.state,
        openedAt: campaignAddressee.read_at || ''
      }
    }),
    preparedViberTemplates: (state) => state.viberTemplates.map(viberTemplate => {
      return {
        id: viberTemplate.id.toString(),
        name: viberTemplate.name,
        body: viberTemplate.message,
        img: viberTemplate?.img || '',
        buttonText: viberTemplate?.btn_name || '-',
        buttonLink: viberTemplate?.btn_url || '-',
        createdAt: viberTemplate.created_at,
      }
    }),
    preparedViberTemplatesForSelect: (state) => state.viberTemplates.map(viberTemplate => {
      return {
        value: viberTemplate.id.toString(),
        text: viberTemplate.name,
        details: JSON.stringify({  message: viberTemplate.message, url: viberTemplate.image.original.url, btnName: viberTemplate.btn_name, btnUrl: viberTemplate.btn_url })
      }
    }),
    preparedListViberIDs: (state) => state.viberIDs.map(viberID => {
      return {
        id: viberID.id,
        name: viberID.name,
        companyId: viberID.company_id,
        app: viberID?.app || '-',
        state: viberID?.state || '',
        createdAt: viberID.created_at
      }
    }),
    preparedViberIDs: (state) => state.viberIDs.filter(element => element.state === 'Confirmed').map(viberID => {
      return {
        value: viberID.id.toString(),
        text: viberID.name
      }
    }),
  },
  actions: {
    setCampaignsViberDefault() {
      this.campaignId = ''
      this.campaignState = ''
      this.campaignName = ''
      this.campaignCompanyId = ''
      this.campaignViberIDId = ''
      this.campaignViberIDName = ''
      this.campaignSegmentId = ''
      this.campaignSegmentName = ''
      this.campaignExcludedSegment = []
      this.campaignOwnerId = ''
      this.campaignOwnerName = ''
      this.campaignStartAt = ''
      this.campaignProgress = 0
      this.campaignAllRecipients = 0
      this.campaignSent = 0
      this.campaignDelivered = 0
      this.campaignErrors = 0
      this.campaignOpened = 0
      this.campaignClicked = 0
      this.campaignSenderName = ''
      this.campaignDispatchType = 'Now'
      this.campaignType = 'Default'
      this.campaignPeriodType = "Daily"
      this.campaignDispatchDate = ''
      this.campaignViberBody = ''
      this.campaignViberImg = ''
      this.campaignBtnText = ''
      this.campaignBtnLink = ''
      this.campaignTestAddressees = ''
      this.campaignAddressees = []
    },
    setCampaignPaginationDefault() {
      this.campaignCurrentPage = 1
      this.campaignPerPage = 10
      this.campaignLastPage = 1
    },
    setFilterDefault() {
      this.filterFrom = ''
      this.filterTo = ''
      this.filterSample = ''
      this.filterNameCampaigns = ''
      this.filterPhone = ''
    },
    setViberTemplateDefault() {
      this.viberTemplateId = ''
      this.viberTemplateName = ''
      this.viberTemplateBody = ''
      this.viberTemplateCompanyId = ''
      this.viberTemplateCreatedAt = ''
      this.viberTemplateImg = ''
      this.viberTemplateButtonText = ''
      this.viberTemplateButtonLink = ''
    },
    setViberIDDefault() {
      this.viberIDName = ''
      this.viberIDApp = ''
      this.viberIDState = ''
      this.viberIDCompanyId = ''
      this.viberIdCreatedAt = ''
    },
    async getCampaignViberAllStatistics() {
      this.isLoading = true
      axios
        .get('api/v1/viber_campaigns/all_stats')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignsActiveCount = response.data.active_count || 0
            this.campaignsFinishedCount = response.data.finished_count || 0
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignsViber(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/viber_campaigns', { params: this.viberFilterCampaignData, page: this.campaignCurrentPage, per: perPage })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaigns = response.data.data
            this.campaignsCount = response.data.total
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createCampaignViber() {
      this.isLoading = true
      axios
        .post('api/v1/viber_campaigns', this.viberCampaignData)
        .then((response) => {
          if (response.status === 201 && response.data.viber_campaign) {
            if (response.data.viber_campaign.state !== 'Draft') {
              if (this.campaignDispatchType === 'Now') {
                this.startCampaignViber(response.data.viber_campaign.id)
              }  
            }
            router.push({name: 'newsletter_viber'})
            toast.success('Viber-кампания успешно создана')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async startCampaignViber(payload) {
      let campaignId = ''
      if (typeof payload == 'object' || payload === undefined) {
        campaignId  = parseInt(this.campaignId)
      } else {
        campaignId = parseInt(payload)
      }
      this.campaignId = campaignId
      this.isLoading = true
      axios
        .post(`api/v1/viber_campaigns/${campaignId}/start`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Viber-кампания успешно запущена')
            this.getCampaignViber()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async pauseCampaignViber() {
      this.isLoading = true
      axios
        .post(`api/v1/viber_campaigns/${this.campaignId}/pause`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Viber-кампания успешно остановлена')
            this.getCampaignViber()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async startDraftCampaignViber() {
      this.isLoading = true
      axios
        .post(`api/v1/viber_campaigns/${this.campaignId}`, {state: 'Pending'})
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            this.startCampaignViber()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignViber(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/viber_campaigns/${this.campaignId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignName = response.data.name
            this.campaignState = response.data.state
            this.campaignCompanyId = response.data.company_id
            this.campaignSegmentId = response.data.recipients_sourceable_id
            this.campaignSegmentName = response.data?.recipients_sourceable?.name || response.data?.segment_name || '-'
            this.campaignOwnerName = 'Admin'
            this.campaignViberImg = response.data?.image.original?.url || '' 
            this.campaignStartAt = response.data.sent_at || ''
            this.campaignViberBody = response.data.message || ''
            this.campaignProgress = response.data.progress || 0
            this.campaignViberIDId = response.data?.viber_id_id  
            this.campaignBtnText = response.data.btn_name
            this.campaignDispatchType = response.data.send_time_kind
            this.campaignDispatchDate = response.data.send_at || ''
            this.campaignType = response.data.send_type
            this.campaignPeriodType = response.data.send_period
            this.campaignBtnLink = response.data.btn_url
            this.campaignViberIDName = response.data?.viber_id_name || ''
            this.campaignExcludedSegment = response.data.excluded_segment_ids === null ? [] : response.data.excluded_segment_ids.map(segmentId => {return segmentId.toString()})
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', {})
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignViberAdressees(currentPerPage) {
      this.isLoading = true
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get(`api/v1/viber_campaigns/${this.campaignId}/addressees`, { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignAddressees = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignViberStatistics() {
      this.isLoading = true
      axios
        .get(`api/v1/viber_campaigns/${this.campaignId}/stats`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignAllRecipients = response.data.addressee_count || 0
            this.campaignSent = response.data.sent_count || 0
            this.campaignDelivered = response.data.delivered_count || 0
            this.campaignErrors = response.data.errors_count || 0
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCampaignViber() {
      this.isLoading = true
      axios
        .post(`api/v1/viber_campaigns/${this.campaignId}`, this.viberCampaignData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('Viber-кампания успешно обновлена')
            router.push({name: 'newsletter_viber'})
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteCampaignViber(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let campaignId = ''
        if (typeof payload == 'object' || payload === undefined) {
          campaignId  = parseInt(this.campaignId)
        } else {
          campaignId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/viber_campaigns/${campaignId}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              router.push({name: 'newsletter_viber'})
              toast.success('Viber-кампания успешно удалена')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async sendTestCampaignViber() {
      this.isLoading = true
      axios
        .post('api/v1/viber_campaigns/test_message', this.viberTestCampaignData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Тестовая рассылка успешно отправлена')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getViberTemplates(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/viber_templates', { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.viberTemplates = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.viberTemplatesCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getViberTemplatesWithCompanies(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }
      this.isLoading = true
      axios
        .get('api/v1/viber_templates', params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.viberTemplates = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.viberTemplatesCount = response.data.total

            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getViberTemplate() {
      this.isLoading = true
      axios
        .get(`api/v1/viber_templates/${this.viberTemplateId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.viberTemplateName = response.data.name
            this.viberTemplateImg = response.data.image.original.url
            console.log(this.viberTemplateImg)
            this.viberTemplateBody = response.data.message
            this.viberTemplateCompanyId = response.data.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createViberTemplate() {
      this.isLoading = true
      axios
        .post('api/v1/viber_templates', this.viberTemplateData)
        .then((response) => {
          if (response.status === 201 && response.data) {
            this.getViberTemplates()
            toast.success('Шаблон успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateViberTemplate() {
      axios
        .post(`api/v1/viber_templates/${this.viberTemplateId}`, this.viberTemplateData)
        .then((response) => {
          if (response.status === 200 && response.data.mobile_push_template) {
            this.viberTemplateName = response.data.mobile_push_template.name
            this.viberTemplateBody = response.data.mobile_push_template.message
            this.viberTemplateCompanyId = response.data.mobile_push_template.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteViberTemplate(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let viberTemplateId = ''
        if (typeof payload == 'object' || payload === undefined) {
          viberTemplateId  = parseInt(this.viberTemplateId)
        } else {
          viberTemplateId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/viber_templates/${viberTemplateId}`)
          .then((response) => {
            if ((response.status === 200 || response.status === 201)) {
              this.getViberTemplates()
              toast.success('Шаблон успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getViberIDs(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/viber_ids', { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.viberIDs = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.viberIDsCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getViberIDsWithCompanies(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }

      this.isLoading = true
      axios
        .get('api/v1/viber_ids', params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.viberIDs = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.viberIDsCount = response.data.total

            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createViberID() {
      this.isLoading = true
      axios
        .post('api/v1/viber_ids', this.viberIDData)
        .then((response) => {
          if (response.status === 201 && response.data) {
            this.getViberIDs()
            toast.success('ViberId успешно создано')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getViberID() {
      this.isLoading = true
      axios
        .get(`api/v1/viber_ids/${this.viberIDId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.viberIDName = response.data.name
            this.viberIDCompanyId = response.data.company_id
            this.viberIDApp = response.data?.app || ''
            this.viberIDState = response.data?.state || ''
            this.viberIdCreatedAt = response.data?.created_at || ''
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateViberID() {
      this.isLoading = true
      axios
        .post(`api/v1/viber_ids/${this.viberIDId}`, this.viberIDData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            this.getViberID()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteViberID(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let viberIDId = ''
        if (typeof payload == 'object' || payload === undefined) {
          viberIDId = parseInt(this.viberIDId)
        } else {
          viberIDId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/viber_ids/${viberIDId}`)
          .then((response) => {
            if ((response.status === 200 || response.status === 201)) {
              this.getViberIDs()
              toast.success('ViberId успешно удалёно')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
  },
})