import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useCampaignsSmsStore = defineStore({
  id: 'campaignsSms',
  state: () => ({
    campaignsActiveCount: 0,
    campaignsFinishedCount: 0,
    campaigns: [],
    campaignsCount: 0,
    campaignId: '',
    campaignCompanyId: '',
    campaignState: '',
    campaignStartAt: '',
    campaignName: '',
    campaignSmsSenderId: '',
    campaignSegmentId: '',
    campaignSegmentName: '',
    campaignOwnerId: '',
    campaignOwnerName: '',
    campaignExcludedSegment: [],
    campaignProgress: 0,
    campaignAllRecipients: 0,
    campaignSent: 0,
    campaignDelivered: 0,
    campaignErrors: 0,
    campaignSenderName: '',
    campaignSmsBody: '',
    campaignType: 'Default',
    campaignPeriodType: "Daily",
    campaignDispatchType: 'Now',
    campaignDispatchDate: '',
    campaignSmoothDispatch: 0,
    campaignTestAddressees: '',
    campaignAddressees: [],
    campaignCurrentPage: 1,
    campaignPerPage: 10,
    campaignLastPage: 1,
    filterFrom: '',
    filterTo: '',
    filterSample: '',
    filterNameCampaigns: '',
    filterPhone: '',
    smsTemplates: [],
    smsTemplateId: '',
    smsTemplateName: '',
    smsTemplateBody: '',
    smsTemplateCompanyId: '',
    smsTemplateCreatedAt: '',
    smsTemplatesCount: 0,
    smsSenders: [],
    smsSendersCount: 0,
    smsSenderId: '',
    smsSenderName: '',
    smsSenderCompanyId: '',
    smsSenderState: '',
    smsSenderCreatedAt: '',
    isLoading: false
  }),
  getters: {
    smsCampaignData: (state) => {
      let smsCampaignData = {}
      if(state.campaignName) {
        smsCampaignData['name'] = state.campaignName
      }
      if(state.campaignCompanyId) {
        smsCampaignData['company_id'] = parseInt(state.campaignCompanyId)
      }
      if(state.campaignSmsSenderId) {
        smsCampaignData['sms_sender_name_id'] = parseInt(state.campaignSmsSenderId)
      }
      if(state.campaignSmsBody) {
        smsCampaignData['message'] = state.campaignSmsBody
      }
      if(state.campaignType) {
        smsCampaignData['send_type'] = state.campaignType
      }
      if(state.campaignType) {
        smsCampaignData['send_type'] = state.campaignType
        if(state.campaignType == 'Periodic') {
          smsCampaignData['send_period'] = state.campaignPeriodType
        }
      }
      if(state.campaignSegmentId) {
        smsCampaignData['recipients_sourceable_id'] = state.campaignSegmentId
      }
      smsCampaignData['recipients_sourceable_type'] = '\\App\\Models\\CdpSegment'
      if(state.campaignDispatchType) {
        smsCampaignData['send_time_kind'] = state.campaignDispatchType
        if(state.campaignDispatchType == 'Later') {
          smsCampaignData['send_at'] = state.campaignDispatchDate
        }
      }
      if(state.campaignSmoothDispatch || state.campaignSmoothDispatch === 0) {
        smsCampaignData['smooth_send'] = parseInt(state.campaignSmoothDispatch)
      }
      if(state.campaignState === 'Draft') {
        smsCampaignData['state'] = 'Draft'
      }
      if(state.campaignExcludedSegment.length > 0) {
        smsCampaignData['excluded_segment_ids'] = state.campaignExcludedSegment.map(segmentId => parseInt(segmentId))
      }

      return smsCampaignData
    },
    smsTestCampaignData: (state) => {
      let smsTestCampaignData = {}

      if(state.campaignTestAddressees) {
        smsTestCampaignData['phones'] = state.campaignTestAddressees.split(' ').join('\n')
      }
      if(state.campaignSmsBody) {
        smsTestCampaignData['message'] = state.campaignSmsBody
      }
      if(state.campaignSmsSenderId) {
        smsTestCampaignData['sms_sender_name_id'] = parseInt(state.campaignSmsSenderId)
      }
      if(state.campaignCompanyId) {
        smsTestCampaignData['company_id'] = parseInt(state.campaignCompanyId)
      }

      return smsTestCampaignData
    },
    smsFilterCampaignData: (state) => {
      let smsFilterCampaignData = {}
      if(state.filterFrom) {
        smsFilterCampaignData['from'] = state.filterFrom
      }
      if(state.filterTo) {
        smsFilterCampaignData['to'] = state.filterTo
      }
      if(state.filterNameCampaigns) {
        smsFilterCampaignData['q'] = state.filterNameCampaigns
      }
      if(state.filterPhone) {
        smsFilterCampaignData['phone'] = state.filterPhone
      }
      smsFilterCampaignData['page'] = state.campaignCurrentPage
      smsFilterCampaignData['per_page'] = state.campaignPerPage

      return smsFilterCampaignData
    },
    smsTemplateData: (state) => {
      let smsTemplateData = {}
      if(state.smsTemplateName){
        smsTemplateData['name'] = state.smsTemplateName
      }
      if(state.smsTemplateBody){
        smsTemplateData['message'] = state.smsTemplateBody
      }
      if(state.smsTemplateCompanyId){
        smsTemplateData['company_id'] = parseInt(state.smsTemplateCompanyId)
      }
      
      return smsTemplateData
    },
    smsSenderData: (state) => {
      let smsSenderData = {}

      if(state.smsSenderName) {
        smsSenderData['name'] = state.smsSenderName
      }
      if(state.smsSenderCompanyId) {
        smsSenderData['company_id'] = parseInt(state.smsSenderCompanyId)
      }
      if (state.smsSenderState) {
        smsSenderData['state'] = state.smsSenderState
      }

      return smsSenderData
    },
    preparedCampaigns: (state) => state.campaigns.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        startAt: campaign.send_at !== null ? campaign.send_at : '',
        createdAt: campaign.created_at !== null ? campaign.created_at : '',
        state: campaign.state,
        segmentName: campaign?.segment_name || '-',
        ownerName: campaign?.author_name || '',
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0
      }
    }),
    preparedCalendarCampaigns: (state) => state.campaigns.filter(campaign => campaign.send_at !== null).map(campaign => {
      return {
        id: campaign.id.toString(),
        customData: {
          id: campaign.id.toString(),
          title: campaign.name,
          class: 'is-has-text-default'
        },
        dates: campaign.send_at
      }
    }),
    preparedAddressees: (state) => state.campaignAddressees.map(campaignAddressee => {
      return {
        id: campaignAddressee.id.toString(),
        phone: campaignAddressee.phone,
        state: campaignAddressee.state,
        openedAt: campaignAddressee.read_at || ''
      }
    }),
    preparedSmsTemplates: (state) => state.smsTemplates.map(smsTemplate => {
      return {
        id: smsTemplate.id.toString(),
        name: smsTemplate.name,
        body: smsTemplate.message,
        createdAt: smsTemplate.created_at,
      }
    }),
    preparedSmsTemplatesForSelect: (state) => state.smsTemplates.map(smsTemplate => {
      return {
        value: smsTemplate.id.toString(),
        text: smsTemplate.name,
        message: smsTemplate.message
      }
    }),
    preparedSmsSenders: (state) => state.smsSenders.map(smsSender => {
      return {
        value: smsSender.id.toString(),
        text: smsSender.name
      }
    }),
    preparedNotFullConfirmedSmsSenderIds: (state) => {
      let notFullConfirmedSmsSenderIds = []

      state.smsSenders.forEach((sender) => {
        if (sender?.state !== 'Confirmed') {
          notFullConfirmedSmsSenderIds.push(sender.id.toString())
        }
      })

      return notFullConfirmedSmsSenderIds
    },
    preparedListSmsSenders: (state) => state.smsSenders.map(smsSender => {
      return {
        id: smsSender.id,
        name: smsSender.name,
        state: smsSender.state,
        companyId: smsSender.company_id,
        operatorName: smsSender.operatorName || '',
        signature: smsSender.signature || '',
        createdAt: smsSender.created_at,
        agreedCount: (smsSender.selected_tariffs !== null && smsSender.selected_tariffs !== undefined) ? smsSender.selected_tariffs.filter(tariff => tariff.state === 'Confirmed').length : 0,
        pendingCount: (smsSender.selected_tariffs !== null && smsSender.selected_tariffs !== undefined) ? smsSender.selected_tariffs.filter(tariff => tariff.state === 'Pending').length : 0,
        inconsistentCount: (smsSender.selected_tariffs !== null && smsSender.selected_tariffs !== undefined) ? smsSender.selected_tariffs.filter(tariff => tariff.state === 'Rejected').length : 0,
        state: smsSender.state || '',
        price: smsSender.price || '',
      }
    }),
  },
  actions: {
    setCampaignsSmsDefault() {
      this.campaignId = ''
      this.campaignState = ''
      this.campaignName = ''
      this.campaignCompanyId = ''
      this.campaignSmsSenderId = ''
      this.campaignSegmentId = ''
      this.campaignSegmentName = ''
      this.campaignOwnerId = ''
      this.campaignOwnerName = ''
      this.campaignStartAt = ''
      this.campaignExcludedSegment = []
      this.campaignProgress = 0
      this.campaignAllRecipients = 0
      this.campaignSent = 0
      this.campaignDelivered = 0
      this.campaignErrors = 0
      this.campaignSenderName = ''
      this.campaignSmsBody = ''
      this.campaignType = 'Default'
      this.campaignPeriodType = "Daily"
      this.campaignDispatchType = 'Now'
      this.campaignDispatchDate = ''
      this.campaignSmoothDispatch = 0
      this.campaignTestAddressees = ''
      this.campaignAddressees = []
    },
    setCampaignPaginationDefault() {
      this.campaignCurrentPage = 1
      this.campaignPerPage = 10
      this.campaignLastPage = 1
    },
    setFilterDefault() {
      this.filterFrom = ''
      this.filterTo = ''
      this.filterSample = ''
      this.filterNameCampaigns = ''
      this.filterPhone = ''
    },
    setSmsTemplateDefault() {
      this.smsTemplateId = ''
      this.smsTemplateName = ''
      this.smsTemplateBody = ''
      this.smsTemplateCompanyId = ''
      this.smsTemplateCreatedAt = ''
    },
    resetSmsTemplate() {
      this.smsTemplateName = ''
      this.smsTemplateBody = ''
    },
    setSmsSenderDefault() {
      this.smsSenderId = ''
      this.smsSenderName = ''
      this.smsSenderCompanyId = ''
      this.smsSenderCreatedAt = ''
    },
    setSmsTariffDefault() {
      this.smsTariffId = ''
      this.smsTariffName = ''
      this.smsTariffMultisignaturePrice = 0
      this.smsTariffIndividualPrice = 0
    },
    resetSmsTariff() {
      this.smsTariffName = ''
      this.smsTariffMultisignaturePrice = 0
      this.smsTariffIndividualPrice = 0
    },
    async sendTestCampaign() {
      this.isLoading = true
      toast.success('Тестовая рассылка запущена')
      this.isLoading = false
    },
    async getCampaignSmsAllStatistics() {
      this.isLoading = true
      axios
        .get('api/v1/sms_campaigns/all_stats')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignsActiveCount = response.data.active_count || 0
            this.campaignsFinishedCount = response.data.finished_count || 0
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignsSms(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/sms_campaigns', { params: this.smsFilterCampaignData, page: this.campaignCurrentPage, per: perPage })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaigns = response.data.data
            this.campaignsCount = response.data.total
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createCampaignSms() {
      this.isLoading = true
      axios
        .post('api/v1/sms_campaigns', this.smsCampaignData)
        .then((response) => {
          if (response.status === 201 && response.data.sms_campaign) {
            if (response.data.sms_campaign.state !== 'Draft') {
              if (this.campaignDispatchType === 'Now') {
                this.startCampaignSms(response.data.sms_campaign.id)
              }
            }
            router.push({name: 'newsletter_sms'})
            toast.success('Смс-рассылка успешно создана')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignSms(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/sms_campaigns/${this.campaignId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignName = response.data.name
            this.campaignState = response.data.state
            this.campaignSegmentId = response.data.recipients_sourceable_id
            this.campaignSegmentName = response.data?.segment_name || '-'
            this.campaignOwnerName = 'Admin'
            this.campaignStartAt = response.data.send_at || ''
            this.campaignSmsBody = response.data.message || ''
            this.campaignProgress = response.data.progress || 0
            this.campaignCompanyId = response.data.company_id
            this.campaignSmsSenderId = response.data.sms_sender_name_id
            this.campaignType = response.data.send_type
            this.campaignPeriodType = response.data.send_period
            this.campaignDispatchType = response.data.send_time_kind
            this.campaignDispatchDate = response.data.send_at || ''
            this.campaignSmoothDispatch = response.data.smooth_send
            this.campaignExcludedSegment = response.data.excluded_segment_ids === null ? [] : response.data.excluded_segment_ids.map(segmentId => {return segmentId.toString()})
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', {})
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignSmsAddressees(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get(`api/v1/sms_campaigns/${this.campaignId}/addressees`, { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignAddressees = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignSmsStatistics() {
      this.isLoading = true
      axios
        .get(`api/v1/sms_campaigns/${this.campaignId}/stats`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignAllRecipients = response.data.addressee_count
            this.campaignSent = response.data.sent_count
            this.campaignDelivered = response.data.delivered_count
            this.campaignErrors = response.data.errors_count
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCampaignSms() {
      this.isLoading = true
      axios
        .post(`api/v1/sms_campaigns/${this.campaignId}`, this.smsCampaignData)
        .then((response) => {
          if (response.status === 200 && response.data.sms_campaign) {
            toast.success('Смс рассылка успешно обновлена')
            router.push({name: 'newsletter_sms'})
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        });
    },
    async startDraftCampaignSms() {
      this.isLoading = true
      axios
        .post(`api/v1/sms_campaigns/${this.campaignId}`, {state: 'Pending'})
        .then((response) => {
          if (response.status === 200 && response.data.sms_campaign) {
            this.startCampaignSms()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
            if (error?.response?.data?.error == 'sms_traffic_service_provider not found') {
              textError = 'Учётная запись сервис-провайдер не найдена'
            } else {
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async startCampaignSms(payload) {
      let campaignId = ''
      if (typeof payload == 'object' || payload === undefined) {
        campaignId  = parseInt(this.campaignId)
      } else {
        campaignId = parseInt(payload)
      }
      this.campaignId = campaignId
      this.isLoading = true
      axios
        .post(`api/v1/sms_campaigns/${campaignId}/start`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Смс рассылка успешно запущена')
            this.getCampaignSms()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
            if (error?.response?.data?.error == 'sms_traffic_service_provider not found') {
              textError = 'Учётная запись сервис-провайдер не найдена'
            } else {
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async pauseCampaignSms() {
      this.isLoading = true
      axios
        .post(`api/v1/sms_campaigns/${this.campaignId}/pause`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Смс рассылка успешно остановлена')
            this.getCampaignSms()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteCampaignSms(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let campaignId = ''
        if (typeof payload == 'object' || payload === undefined) {
          campaignId  = parseInt(this.campaignId)
        } else {
          campaignId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/sms_campaigns/${campaignId}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              router.push({name: 'newsletter_sms'})
              toast.success('Смс-кампания успешно удалена')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async sendTestCampaignSms() {
      this.isLoading = true
      axios
        .post('api/v1/sms_campaigns/test_message', this.smsTestCampaignData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Тестовая рассылка успешно отправлена')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
            if (error?.response?.data?.message == 'sms_traffic_service_provider not found') {
              textError = 'Учётная запись сервис-провайдер не найдена'
            } else {
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsTemplates(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/sms_templates', { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsTemplates = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.smsTemplatesCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsTemplatesWithCompany(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }

      this.isLoading = true
      axios
        .get('api/v1/sms_templates', params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsTemplates = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.smsTemplatesCount = response.data.total
            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsTemplate() {
      this.isLoading = true
      axios
        .get(`api/v1/sms_templates/${this.smsTemplateId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsTemplateName = response.data.name
            this.smsTemplateBody = response.data.message
            this.smsTemplateCompanyId = response.data.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createSmsTemplate() {
      this.isLoading = true
      axios
        .post('api/v1/sms_templates', this.smsTemplateData)
        .then((response) => {
          if (response.status === 201 && response.data) {
            this.getSmsTemplates()
            toast.success('Смс-шаблон успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateSmsTemplate() {
      axios
        .post(`api/v1/sms_templates/${this.smsTemplateId}`, this.smsTemplateData)
        .then((response) => {
          if (response.status === 200 && response.data.sms_template) {
            this.smsTemplateName = response.data.sms_template.name
            this.smsTemplateBody = response.data.sms_template.message
            this.smsTemplateCompanyId = response.data.sms_template.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async deleteSmsTemplate(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let smsTemplateId = ''
        if (typeof payload == 'object' || payload === undefined) {
          smsTemplateId  = parseInt(this.smsTemplateId)
        } else {
          smsTemplateId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/sms_templates/${smsTemplateId}`)
          .then((response) => {
            if (response.status === 200 && response.data.sms_template) {
              this.getSmsTemplates()
              toast.success('Смс-шаблон успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getSmsSenders(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/sms_sender_names', { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsSenders = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.smsSendersCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsSendersWithCompany(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }

      this.isLoading = true
      axios
        .get('api/v1/sms_sender_names', params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsSenders = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.smsSendersCount = response.data.total
            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsSender() {
      this.isLoading = true
      axios
        .get(`api/v1/sms_sender_names/${this.smsSenderId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsSenderName = response.data.name
            this.smsSenderState = response.data.state
            this.smsSenderCompanyId = response.data.company_id.toString()
            this.smsSenderCreatedAt = response.data.created_at
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createSmsSender() {
      this.isLoading = true
      axios({
        method: 'POST',
        url: 'api/v1/sms_sender_names',
        data: this.smsSenderData,
      }).then((response) => {
          if (response.status === 201 && response.data) {
            toast.success('Имя отправителя создано')
            this.getSmsSenders()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateSmsSender() {
      this.isLoading = true
      axios({
        method: 'POST',
        url: `api/v1/sms_sender_names/${this.smsSenderId}`,
        data: this.smsSenderData,
      }).then((response) => {
          if (response.status === 200 && response.data.sms_sender_name) {
            this.smsSenderName = response.data.sms_sender_name.name
            this.smsSenderCompanyId = response.data.sms_sender_name.company_id.toString()
            this.smsSenderState = response.data.sms_sender_name.state
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteSmsSender(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let smsSenderId = ''
        if (typeof payload == 'object' || payload === undefined) {
          smsSenderId  = parseInt(this.smsSenderId)
        } else {
          smsSenderId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/sms_sender_names/${smsSenderId}`)
          .then((response) => {
            if (response.status === 200 && response.data.sms_sender_name) {
              this.getSmsSenders()
              toast.success('Имя отправителя успешно удалено')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
  },
})