import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useImportProfilesStore = defineStore ({
    id: 'importProfiles',
    state: () => ({
      importId: '',
      companyId: '',
      importFileId: '',
      pixelId: '',
      selectFields: [],
      importFile: '',
      importFiles: [],
      importCount: '',
      hiddenId: '',
      fileName: '',
      columnsCount: '',
      matchedCount: '',
      skippedCount: '',
      compareCount: '',
      allRecordCount: '',
      csvSeparator: ',',
      columnExist: false,
      columnsId: '',
      mappedData: [],
      mapping: [],
      importCurrentPage: 1,
      importPerPage: 20,
      importLastPage: 1,
      successImport: false,
      consentTo: false,
      isImportLoading: false,
      isLoading: false,
      uploaded: false
    }),
    getters: {
      importFileData: (state) => {
        let importFileData = new FormData()

        if (state.companyId) {
          importFileData.append('company_id', parseInt(state.companyId))
        }
        if (state.importFile) {
          importFileData.append('file', state.importFile)
        }
        if (state.csvSeparator) {
          importFileData.append('csv_separator', state.csvSeparator)
        }
        if (state.columnExist) {
          importFileData.append('columns_exists', state.columnExist === 'true' ? 1 : 0)
        }

        return importFileData
      },
      importColumnsData: (state) => {
        let importColumnsData = {}

        if (state.companyId) {
          importColumnsData['company_id'] = parseInt(state.companyId)
        }

        if (state.pixelId) {
          importColumnsData['pixel_id'] = parseInt(state.pixelId)
        }

        if(state.importFileId) {
          importColumnsData['import_file_id'] = parseInt(state.importFileId)
        }

        if (state.mapping) {
          importColumnsData['mapping'] = state.mapping
        }

        return importColumnsData
      },
      preparedImportFiles: (state) => state.importFiles.map(file => {
        return {
          id: file.id.toString(),
          name: file.file_name,
          columns: file.columns_count,
          records: file.record_count,
          state: file.state,
          createdAt: file.created_at
        }
      }),
    },
    actions: {
      setImportDefault(){
        this.importFile = ''
        this.csvSeparator = ','
        this.columnExist = false
        this.uploaded = false
      },
      async getImportFiles(currentPerPage) {
        let perPage = currentPerPage || this.importPerPage
        this.isLoading = true
        axios
          .get('api/v1/cdp_profile_imports', { params: { page: this.importCurrentPage, per: perPage }})
          .then((response) => {
            if (response.status === 200 && response.data.data) {
              this.importFiles = response.data.data
              this.importCount = response.data.total
              this.importCurrentPage = response.data.current_page
              this.importLastPage = response.data.last_page
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      },
      async cancelImport() {
        this.isLoading = true
        axios
        .post(`api/v1/cdp_profile_imports/${this.importId}/cancel`)
        .then((response) => {
          if ((response.status === 201 || response.status === 200 )) {
            this.getImportFiles()
            toast.success('Импорт отменен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
      },
      async loadFile(callback = {}) {
        this.isLoading = true
        this.isImportLoading = true
        axios({
          method: 'POST',
          url: 'api/v1/import_files',
          data: this.importFileData,
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then((response) => {
            if((response.status === 201 || response.status === 200 )) {
              toast.success('Файл успешно загружен')
              if (typeof callback == 'function') {
                callback('success', response.data)
              }
            } else {
              this.uploaded = false
              if (typeof callback == 'function') {
                callback('failed', {})
              }
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(textError)
            this.uploaded = false
          })
          .finally((response) => {
            this.isImportLoading = false
            this.isLoading = false
          })
      },
      async createImport() {
        this.isLoading = true
        axios
        .post('api/v1/cdp_profile_imports')
        .then((response) => {
          if (response.status === 201 && response.data) {
            this.importFileId = response.data.import_file_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
      },
      async startImport(callback = {}) {
        this.isLoading = true
        axios
          .post('api/v1/cdp_profile_imports', this.importColumnsData)
          .then((response) => {
            if (response.status === 201 && response.data) {
              this.successImport = true
              toast.success('Импорт начался')
              if (typeof callback == 'function') {
                callback('success', response.data)
              }
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
              this.isLoading = false
              if (typeof callback == 'function') {
                callback('failed', {})
              }
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    }
})