import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useInvoicesStore = defineStore({
  id: 'invoices',
  state: () => ({
    companyId: '',
    transactions: [],
    currentPage: 1,
    perPage: 20,
    lastPage: 1,
    isLoading: false
  }),
  getters: {
    preparedTransactions: (state) => state.transactions.map(transaction => {
      return {
        id: transaction.id,
        kind: transaction.kind == 'Deposit' ? 'Пополнение' : 'Списание',
        amount: transaction.kind == 'Deposit' ? transaction.amount : transaction.amount*(-1),
        note: transaction.note,
        createdAt: transaction.created_at
      }
    }),
  },
  actions: {
    setInvoicesDefault() {
      this.companyId = ''
      this.transactions = []
    },
    setInvoicesPaginationDefault() {
      this.currentPage = 1
      this.perPage = 20
      this.lastPage = 1
    },
    async getInvoices(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get(`api/v1/companies/${this.companyId}/transactions`, { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.transactions = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyInvoices(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get('api/v1/my_company/transactions', { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.transactions = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    }
  },
})