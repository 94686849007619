import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useCampaignsSmsPushStore = defineStore ({
  id: 'campaignsSmsPush',
  state: () => ({
    campaignId: '',
    campaigns: [],
    campaignsCount: 0,
    campaignLastPage: 1,
    campaignCurrentPage: 1,
    campaignsActiveCount: 0,
    campaignsFinishedCount: 0,
    campaignAllRecipients: 0,
    campaignPerPage: 10,
    campaignSent: 0,
    campaignDelivered: 0,
    campaignErrors: 0,
    campaignType: 'Default',
    campaignPeriodType: "Daily",
    campaignDispatchType: 'Now',
    campaignDispatchDate: '',
    campaignName: '',
    campaignState: '',
    campaignSegmentId: '',
    campaignSegmentName: '',
    campaignExcludedSegment: [],
    campaignAddressees: [],
    campaignOwnerName: 'Admin',
    campaignStartAt: '',
    campaignSmsBody: '',
    campaignProgress: '',
    deepLink: '',
    campaignCompanyId: '',
    campaignTestAddressees: '',
    campaignSmsPushMobileAppId: '',
    campaignSmsPushMobileAppName: '',
    smsPushTemplates: [],
    smsPushTemplatesCount: '',
    smsPushTemplateId: '',
    smsPushTemplateName: '',
    smsPushTemplateBody: '',
    smsPushTemplateCompanyId: '',
    smsPushMobileApps: [],
    smsPushMobileAppsCount: '',
    smsPushMobileAppId: '',
    smsPushMobileAppCompanyId: '',
    smsPushMobileAppName: '',
    smsPushMobileAppLogin: '',
    smsPushMobileAppPassword: '',
    smsPushMobileAppRoute: '',
    filterFrom: '',
    filterTo: '',
    filterSample: '',
    filterNameCampaigns: '',
    filterPhone: '',
    isLoading: false,
  }),
  getters: {
    smsPushCampaignData: (state) => {
      let smsPushCampaignData = {}
      if(state.campaignName) {
        smsPushCampaignData['name'] = state.campaignName
      }
      if(state.campaignCompanyId) {
        smsPushCampaignData['company_id'] = parseInt(state.campaignCompanyId)
      }
      if(state.campaignSmsPushMobileAppId) {
        smsPushCampaignData['mobile_push_app_id'] = parseInt(state.campaignSmsPushMobileAppId)
      }
      if(state.campaignSmsBody) {
        smsPushCampaignData['message'] = state.campaignSmsBody
      }
      if(state.campaignType) {
        smsPushCampaignData['send_type'] = state.campaignType
        if(state.campaignType == 'Periodic') {
          smsPushCampaignData['send_period'] = state.campaignPeriodType
        }
      }
      if(state.campaignDispatchType) {
        smsPushCampaignData['send_time_kind'] = state.campaignDispatchType
        if(state.campaignDispatchType == 'Later') {
          smsPushCampaignData['send_at'] = state.campaignDispatchDate
        }
      }
      if (state.deepLink) {
        smsPushCampaignData['deeplink'] = state.deepLink
      }
      if(state.campaignSegmentId) {
        smsPushCampaignData['recipients_sourceable_id'] = state.campaignSegmentId
      }
      if(state.campaignExcludedSegment.length > 0) {
        smsPushCampaignData['excluded_segment_ids'] = state.campaignExcludedSegment.map(segmentId => parseInt(segmentId))
      }
      smsPushCampaignData['recipients_sourceable_type'] = '\\App\\Models\\CdpSegment'
      if(state.campaignState === 'Draft') {
        smsPushCampaignData['state'] = 'Draft'
      }

      return smsPushCampaignData
    },
    smsPushFilterCampaignData: (state) => {
      let smsPushFilterCampaignData = {}
      if(state.filterFrom) {
        smsPushFilterCampaignData['from'] = state.filterFrom
      }
      if(state.filterTo) {
        smsPushFilterCampaignData['to'] = state.filterTo
      }
      if(state.filterNameCampaigns) {
        smsPushFilterCampaignData['q'] = state.filterNameCampaigns
      }
      if(state.filterPhone) {
        smsPushFilterCampaignData['phone'] = state.filterPhone
      }
      smsPushFilterCampaignData['page'] = state.campaignCurrentPage
      smsPushFilterCampaignData['per_page'] = state.campaignPerPage

      return smsPushFilterCampaignData
    },
    smsPushTemplateData: (state) => {
      let smsPushTemplateData = {}
      if(state.smsPushTemplateName){
        smsPushTemplateData['name'] = state.smsPushTemplateName
      }
      if(state.smsPushTemplateBody){
        smsPushTemplateData['message'] = state.smsPushTemplateBody
      }
      if(state.smsPushTemplateCompanyId){
        smsPushTemplateData['company_id'] = parseInt(state.smsPushTemplateCompanyId)
      }
      
      return smsPushTemplateData
    },
    smsPushMobileAppData: (state) => {
      let smsPushMobileAppData = {}
      if(state.smsPushMobileAppName){
        smsPushMobileAppData['name'] = state.smsPushMobileAppName
      }
      if(state.smsPushMobileAppCompanyId){
        smsPushMobileAppData['company_id'] = parseInt(state.smsPushMobileAppCompanyId)
      }
      
      return smsPushMobileAppData
    },
    smsPushTestCampaignData: (state) => {
      let smsPushTestCampaignData = {}

      if(state.campaignTestAddressees) {
        smsPushTestCampaignData['phones'] = state.campaignTestAddressees.split(' ').join('\n')
      }
      if(state.campaignSmsBody) {
        smsPushTestCampaignData['message'] = state.campaignSmsBody
      }
      if(state.campaignSmsPushMobileAppId) {
        smsPushTestCampaignData['mobile_push_app_id'] = parseInt(campaignSmsPushMobileAppId)
      }
      if(state.campaignCompanyId) {
        smsPushTestCampaignData['company_id'] = parseInt(state.campaignCompanyId)
      }

      return smsPushTestCampaignData
    },
    preparedCampaigns: (state) => state.campaigns.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        startAt: campaign.send_at !== null ? campaign.send_at : '',
        createdAt: campaign.created_at !== null ? campaign.created_at : '',
        state: campaign.state,
        segmentName: campaign?.recipients_sourceable?.name || campaign?.segment_name || '-',
        ownerName: campaign?.author_name || '',
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0
      }
    }),
    preparedAddressees: (state) => state.campaignAddressees.map(campaignAddressee => {
      return {
        id: campaignAddressee.id.toString(),
        phone: campaignAddressee.phone,
        state: campaignAddressee.state,
        openedAt: campaignAddressee.read_at || ''
      }
    }),
    preparedSmsPushTemplates: (state) => state.smsPushTemplates.map(smsTemplate => {
      return {
        id: smsTemplate.id.toString(),
        name: smsTemplate.name,
        body: smsTemplate.message,
        createdAt: smsTemplate.created_at,
      }
    }),
    preparedCalendarCampaigns: (state) => state.campaigns.filter(campaign => campaign.send_at !== null).map(campaign => {
      return {
        id: campaign.id.toString(),
        customData: {
          id: campaign.id.toString(),
          title: campaign.name,
          class: 'is-has-text-default'
        },
        dates: campaign.send_at
      }
    }),
    preparedSmsPushTemplatesForSelect: (state) => state.smsPushTemplates.map(smsTemplate => {
      return {
        value: smsTemplate.id.toString(),
        text: smsTemplate.name,
        message: smsTemplate.message
      }
    }),
    preparedListSmsPushMobileApps: (state) => state.smsPushMobileApps.map(smsMobileApp => {
      return {
        id: smsMobileApp.id,
        name: smsMobileApp.name,
        companyId: smsMobileApp.company_id,
        createdAt: smsMobileApp.created_at
      }
    }),
    preparedSmsPushMobileApps: (state) => state.smsPushMobileApps.map(smsPushMobileApp => {
      return {
        value: smsPushMobileApp.id.toString(),
        text: smsPushMobileApp.name
      }
    }),
  },
  actions: {
    setCampaignsSmsDefault() {
      this.campaignId = ''
      this.campaignState = ''
      this.campaignName = ''
      this.campaignCompanyId = ''
      this.campaignSmsPushMobileAppId = ''
      this.campaignSmsPushMobileAppName = ''
      this.campaignSegmentId = ''
      this.campaignSegmentName = ''
      this.campaignDispatchType = 'Now'
      this.campaignType = 'Default'
      this.campaignPeriodType = "Daily"
      this.campaignDispatchDate = ''
      this.campaignExcludedSegment = []
      this.campaignOwnerId = ''
      this.campaignOwnerName = ''
      this.campaignStartAt = ''
      this.campaignProgress = 0
      this.campaignAllRecipients = 0
      this.campaignSent = 0
      this.campaignDelivered = 0
      this.campaignErrors = 0
      this.campaignSenderName = ''
      this.campaignSmsBody = ''
      this.campaignTestAddressees = ''
      this.campaignAddressees = []
    },
    setCampaignPaginationDefault() {
      this.campaignCurrentPage = 1
      this.campaignPerPage = 10
      this.campaignLastPage = 1
    },
    setFilterDefault() {
      this.filterFrom = ''
      this.filterTo = ''
      this.filterSample = ''
      this.filterNameCampaigns = ''
      this.filterPhone = ''
    },
    setSmsPushTemplateDefault() {
      this.smsPushTemplateId = ''
      this.smsPushTemplateName = ''
      this.smsPushTemplateBody = ''
      this.smsPushTemplateCompanyId = ''
      this.smsPushTemplateCreatedAt = ''
    },
    setSmsPushMobileAppDefault() {
      this.smsPushMobileAppName = ''
      this.smsPushMobileAppLogin = ''
      this.smsPushMobileAppPassword = ''
      this.smsPushMobileAppRoute = ''
      this.smsPushMobileAppCompanyId = ''
    },
    async getCampaignSmsPushAllStatistics() {
      this.isLoading = true
      axios
        .get('api/v1/mobile_push_campaigns/all_stats')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignsActiveCount = response.data.active_count || 0
            this.campaignsFinishedCount = response.data.finished_count || 0
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignsSmsPush(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/mobile_push_campaigns', { params: this.smsPushFilterCampaignData, page: this.campaignCurrentPage, per: perPage })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaigns = response.data.data
            this.campaignsCount = response.data.total
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createCampaignSmsPush() {
      this.isLoading = true
      axios
        .post('api/v1/mobile_push_campaigns', this.smsPushCampaignData)
        .then((response) => {
          if (response.status === 201 && response.data.mobile_push_campaign) {
            if (response.data.mobile_push_campaign.state !== 'Draft') {
              if (this.campaignDispatchType === 'Now') {
                this.startCampaignSmsPush(response.data.mobile_push_campaign.id)
              }  
            }
            router.push({name: 'newsletter_push'})
            toast.success('Mobile-push-рассылка успешно создана')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async sendTestCampaignSmsPush() {
      this.isLoading = true
      toast.success('Тестовая рассылка успешно отправлена')
      this.isLoading = false
    },
    async startCampaignSmsPush(payload) {
      let campaignId = ''
      if (typeof payload == 'object' || payload === undefined) {
        campaignId  = parseInt(this.campaignId)
      } else {
        campaignId = parseInt(payload)
      }
      this.campaignId = campaignId
      this.isLoading = true
      axios
        .post(`api/v1/mobile_push_campaigns/${campaignId}/start`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Mobile-push рассылка успешно запущена')
            this.getCampaignSmsPush()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async pauseCampaignSmsPush() {
      this.isLoading = true
      axios
        .post(`api/v1/mobile_push_campaigns/${this.campaignId}/pause`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Mobile-push рассылка успешно остановлена')
            this.getCampaignSmsPush()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async startDraftCampaignSmsPush() {
      this.isLoading = true
      axios
        .post(`api/v1/mobile_push_campaigns/${this.campaignId}`, {state: 'Pending'})
        .then((response) => {
          if (response.status === 200 && response.data.mobile_push_campaigns) {
            this.startCampaignSmsPush()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignSmsPush() {
      this.isLoading = true
      axios
        .get(`api/v1/mobile_push_campaigns/${this.campaignId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignName = response.data.name
            this.campaignState = response.data.state
            this.campaignSegmentId = response.data.recipients_sourceable_id
            this.campaignSegmentName = response.data?.recipients_sourceable?.name || response.data?.segment_name || '-'
            this.campaignOwnerName = 'Admin'
            this.campaignStartAt = response.data.sent_at || ''
            this.campaignSmsBody = response.data.message || ''
            this.campaignProgress = response.data.progress || 0
            this.campaignCompanyId = response.data.company_id
            this.campaignDispatchType = response.data.send_time_kind
            this.campaignDispatchDate = response.data.send_at || ''
            this.campaignType = response.data.send_type
            this.campaignPeriodType = response.data.send_period
            this.campaignSmsPushMobileAppId = response.data?.mobile_push_app_id
            this.campaignSmsPushMobileAppName = response.data?.mobile_push_app_name || ''
            this.campaignExcludedSegment = response.data.excluded_segment_ids === null ? [] : response.data.excluded_segment_ids.map(segmentId => {return segmentId.toString()})
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignSmsPushAdressees(currentPerPage) {
      this.isLoading = true
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get(`api/v1/mobile_push_campaigns/${this.campaignId}/addressees`, { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignAddressees = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignSmsPushStatistics() {
      this.isLoading = true
      axios
        .get(`api/v1/mobile_push_campaigns/${this.campaignId}/stats`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignAllRecipients = response.data.addressee_count || 0
            this.campaignSent = response.data.sent_count || 0
            this.campaignDelivered = response.data.delivered_count || 0
            this.campaignErrors = response.data.errors_count || 0
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCampaignSmsPush() {
      this.isLoading = true
      axios
        .post(`api/v1/mobile_push_campaigns/${this.campaignId}`, this.smsPushCampaignData)
        .then((response) => {
          if (response.status === 200 && response.data.mobile_push_campaign) {
            toast.success('Mobile-push рассылка успешно обновлена')
            router.push({name: 'newsletter_push'})
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteCampaignSmsPush(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let campaignId = ''
        if (typeof payload == 'object' || payload === undefined) {
          campaignId  = parseInt(this.campaignId)
        } else {
          campaignId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/mobile_push_campaigns/${campaignId}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              router.push({name: 'newsletter_push'})
              toast.success('Mobile-push-кампания успешно удалена')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async sendTestCampaignSms() {
      this.isLoading = true
      axios
        .post('api/v1/mobile_push_campaigns/test_message', this.smsPushTestCampaignData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Тестовая рассылка успешно отправлена')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsPushTemplates(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/mobile_push_templates', { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsPushTemplates = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.smsPushTemplatesCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsPushTemplatesWithCompanies(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }
      this.isLoading = true
      axios
        .get('api/v1/mobile_push_templates', params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsPushTemplates = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.smsPushTemplatesCount = response.data.total

            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsPushTemplate() {
      this.isLoading = true
      axios
        .get(`api/v1/mobile_push_templates/${this.smsPushTemplateId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsPushTemplateName = response.data.name
            this.smsPushTemplateBody = response.data.message
            this.smsPushTemplateCompanyId = response.data.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createSmsPushTemplate() {
      this.isLoading = true
      axios
        .post('api/v1/mobile_push_templates', this.smsPushTemplateData)
        .then((response) => {
          if (response.status === 201 && response.data) {
            this.getSmsPushTemplates()
            toast.success('Шаблон успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateSmsPushTemplate() {
      axios
        .post(`api/v1/mobile_push_templates/${this.smsPushTemplateId}`, this.smsPushTemplateData)
        .then((response) => {
          if (response.status === 200 && response.data.mobile_push_template) {
            this.smsPushTemplateName = response.data.mobile_push_template.name
            this.smsPushTemplateBody = response.data.mobile_push_template.message
            this.smsPushTemplateCompanyId = response.data.mobile_push_template.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteSmsPushTemplate(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let smsPushTemplateId = ''
        if (typeof payload == 'object' || payload === undefined) {
          smsPushTemplateId  = parseInt(this.smsPushTemplateId)
        } else {
          smsPushTemplateId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/mobile_push_templates/${smsPushTemplateId}`)
          .then((response) => {
            if (response.status === 200 && response.data.mobile_push_template) {
              this.getSmsPushTemplates()
              toast.success('Шаблон успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getSmsPushMobileApps(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      this.isLoading = true
      axios
        .get('api/v1/mobile_push_apps', { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsPushMobileApps = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.smsPushMobileAppsCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsPushMobileAppsWithCompanies(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }

      this.isLoading = true
      axios
        .get('api/v1/mobile_push_apps', params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsPushMobileApps = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.smsPushMobileAppsCount = response.data.total

            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createSmsPushMobileApp() {
      this.isLoading = true
      axios
        .post('api/v1/mobile_push_apps', this.smsPushMobileAppData)
        .then((response) => {
          if (response.status === 201 && response.data) {
            this.getSmsPushMobileApps()
            toast.success('Мобильное приложение успешно создано')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSmsPushMobileApp() {
      this.isLoading = true
      axios
        .get(`api/v1/mobile_push_apps/${this.smsPushMobileAppId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.smsPushMobileAppName = response.data.name
            this.smsPushMobileAppCompanyId = response.data.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateSmsPushMobileApp() {
      this.isLoading = true
      axios
        .post(`api/v1/mobile_push_apps/${this.smsPushMobileAppId}`, this.smsPushMobileAppData)
        .then((response) => {
          if (response.status === 200 && response.data.mobile_push_app) {
            this.smsPushMobileAppName = response.data.mobile_push_app.name
            this.smsPushMobileAppCompanyId = response.data.mobile_push_app.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteSmsPushMobileApp(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let smsPushMobileAppId = ''
        if (typeof payload == 'object' || payload === undefined) {
          smsPushMobileAppId = parseInt(this.smsPushMobileAppId)
        } else {
          smsPushMobileAppId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/mobile_push_apps/${smsPushMobileAppId}`)
          .then((response) => {
            if (response.status === 200 && response.data.mobile_push_app) {
              this.getSmsPushMobileApps()
              toast.success('Мобильное приложение успешно удалёно')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
  },
})