import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useCampaignsEmailStore = defineStore({
  id: 'campaignsEmail',
  state: () => ({
    campaigns: [],
    campaignsActiveCount: 0,
    campaignsFinishedCount: 0,
    campaignsCount: 0,
    campaignId: '',
    campaignName: '',
    campaignState: '',
    campaignPercent: '',
    campaignStartAt: '',
    campaignSenderAddressId: '',
    campaignHTMLbody: '',
    campaignSenderAddress: '',
    campaignSenderName: '',
    campaignSegmentId: '',
    campaignSegmentName: '',
    campaignExcludedSegment: [],
    campaignTheme: '',
    campaignTemplateId: '',
    campaignPreheader: '',
    campaignOwnerId: '',
    campaignOwnerName: '',
    campaignType: 'Default',
    campaignPeriodType: "Daily",
    campaignDispatchType: 'Now',
    campaignDispatchDate: '',
    campaignProgress: 0,
    campaignAllRecipients: 0,
    campaignSent: 0,
    campaignDelivered: 0,
    campaignErrors: 0,
    campaignOpened: 0,
    campaignClicked: 0,
    campaignTestSenders: '',
    campaignAddressees: [],
    campaignCompanyId: '',
    campaignCompanyToken: '',
    campaignTemplateHtmlCode: '',
    campaignClicks: [],
    campaignClickAddressees: [],
    campaignsCurrentPage: 1,
    campaignsPerPage: 20,
    campaignsLastPage: 1,
    filterFrom: '',
    filterTo: '',
    filterSample: 'last_7',
    filterNameCampaigns: '',
    filterEmail: '',
    templates: [],
    templateId: '',
    templateCover: '',
    templateName: '',
    templateCreatedAt: '',
    templateCount: 0,
    senders: [],
    senderId: '',
    senderAddress: '',
    senderName: '',
    senderCreatedAt: '',
    senderState: '',
    senderCompanyId: '',
    senderSkipState: false,
    sendersCount: 0,
    isLoading: false
  }),
  getters: {
    emailFilterCampaignData: (state) => {
      let emailFilterCampaignData = {}
      if(state.filterFrom) {
        emailFilterCampaignData['from'] = state.filterFrom
      }
      if(state.filterTo) {
        emailFilterCampaignData['to'] = state.filterTo
      }
      if(state.filterNameCampaigns) {
        emailFilterCampaignData['q'] = state.filterNameCampaigns
      }
      if(state.filterEmail) {
        emailFilterCampaignData['email'] = state.filterEmail
      }
      emailFilterCampaignData['page'] = state.campaignsCurrentPage
      emailFilterCampaignData['per'] = state.campaignsPerPage

      return emailFilterCampaignData
    },
    emailCampaignData: (state) => {
      let emailCampaignData = {}

      if (state.campaignName) {
        emailCampaignData['name'] = state.campaignName
      }
      if (state.campaignTheme) {
        emailCampaignData['subject'] = state.campaignTheme
      }
      if (state.campaignPreheader) {
        emailCampaignData['preheader'] = state.campaignPreheader
      }
      if (state.campaignSenderAddressId) {
        emailCampaignData['email_sender_name_id'] = parseInt(state.campaignSenderAddressId)
      }
      if (state.campaignSenderName){
        emailCampaignData['email_sender_name_name'] = state.campaignSenderName
      }
      if (state.campaignTemplateId) {
        emailCampaignData['email_template_id'] = parseInt(state.campaignTemplateId)
      }
      if (state.campaignHTMLbody) {
        emailCampaignData['email_template_body'] = state.campaignHTMLbody
      }
      if (state.campaignTemplateHtmlCode) {
        emailCampaignData['email_template_body'] = state.campaignTemplateHtmlCode
      }
      emailCampaignData['recipients_sourceable_type'] = '\\App\\Models\\CdpSegment'
      if (state.campaignSegmentId) {
        emailCampaignData['recipients_sourceable_id'] = state.campaignSegmentId
      }
      if(state.campaignType) {
        emailCampaignData['send_type'] = state.campaignType
        if(state.campaignType == 'Periodic') {
          emailCampaignData['send_period'] = state.campaignPeriodType
        }
      }
      if(state.campaignDispatchType) {
        emailCampaignData['send_time_kind'] = state.campaignDispatchType
        if(state.campaignDispatchType == 'Later') {
          emailCampaignData['send_at'] = state.campaignDispatchDate
        }
      }
      if (state.campaignExcludedSegment.length > 0) {
        emailCampaignData['excluded_segment_ids'] = state.campaignExcludedSegment.map(segmentId => parseInt(segmentId))
      }
      if (state.campaignCompanyId) {
        emailCampaignData['company_id'] = parseInt(state.campaignCompanyId)
      }
      if (state.campaignState === 'Draft') {
        emailCampaignData['state'] = 'Draft'
      }

      return emailCampaignData
    },
    emailTestCampaignData: (state) => {
      let emailCampaignData = {}

      if(state.campaignTestSenders) {
        emailCampaignData['emails'] = state.campaignTestSenders.split(' ').join('\n')
      }
      if (state.campaignSenderAddressId) {
        emailCampaignData['email_sender_name_id'] =  parseInt(state.campaignSenderAddressId)
      }
      if (state.campaignSenderName){
        emailCampaignData['email_sender_name_name'] = state.campaignSenderName
      }
      if (state.campaignTheme) {
        emailCampaignData['subject'] = state.campaignTheme
      }
      if (state.campaignPreheader) {
        emailCampaignData['preheader'] = state.campaignPreheader
      }
      if (state.campaignHTMLbody) {
        emailCampaignData['body'] = state.campaignHTMLbody
      }
      if (state.campaignTemplateHtmlCode) {
        emailCampaignData['body'] = state.campaignTemplateHtmlCode
      }
      if (state.campaignTemplateId) {
        emailCampaignData['email_template_id'] = parseInt(state.campaignTemplateId)
      }

      return emailCampaignData
    },
    emailSenderData: (state) => {
      let emailSenderData = {}

      if(state.senderName) {
        emailSenderData['name'] = state.senderName
      }
      if(state.senderAddress) {
        emailSenderData['address'] = state.senderAddress
      }
      if(state.senderState && !state.senderSkipState) {
        emailSenderData['state'] = state.senderState
      }
      if(state.senderCompanyId) {
        emailSenderData['company_id'] = state.senderCompanyId
      }

      return emailSenderData
    },
    preparedCampaigns: (state) => state.campaigns.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        startAt: campaign.send_at !== null ? campaign.send_at : '',
        createdAt: campaign.created_at !== null ? campaign.created_at : '',
        state: campaign.state,
        segmentName: campaign?.segment_name || '-',
        ownerName: campaign?.author_name || '',
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0
      }
    }),
    preparedCalendarCampaigns: (state) => state.campaigns.filter(campaign => campaign.sent_at !== null).map(campaign => {
      return {
        id: campaign.id.toString(),
        customData: {
          id: campaign.id.toString(),
          title: campaign.name,
          class: 'is-has-text-default'
        },
        dates: campaign.sent_at
      }
    }),
    preparedClickAddressees: (state) => state.campaignClickAddressees.map(address => {
      return {
        id: address.index,
        email: address.email,
        createdAt: address.created_at
      }
    }),
    preparedAddressees: (state) => state.campaignAddressees.map(address => {
      return {
        id: address.id.toString(),
        email: address.email,
        state: address.state,
        createdAt: address.updated_at,
      }
    }),
    preparedTemplates: (state) => state.templates.map(template => {
      return {
        id: template.id.toString(),
        name: template.name,
        createdAt: template.created_at,
        htmlCode: template.template,
      }
    }),
    preparedForSelectTemplates: (state) => state.templates.map(template => {
      return {
        value: template.id.toString(),
        text: template.name,
        htmlCode: template.template,
        createdAt: template.created_at
      }
    }),
    preparedSenders: (state) => state.senders.map(sender => {
      return {
        id: sender.id.toString(),
        name: sender.name,
        email: sender.address,
        createdAt: sender.created_at,
        state: sender.state,
      }
    }),
    preparedForSelectSenders: (state) => state.senders.filter(campaign => campaign.state === 'Confirmed').map(sender => {
      return {
        value: sender.id.toString(),
        text: sender.address,
        name: sender.name
      }
    }),
    preparedForSelectSendersName: (state) => state.senders.map(sender => {
      return {
        value: sender.id.toString(),
        text: sender.name,
      }
    }),
  },
  actions: {
    setCampaignsEmailDefault() {
      this.campaignId = ''
      this.campaignState = ''
      this.campaignPercent = ''
      this.campaignStartAt = ''
      this.campaignName = ''
      this.campaignSegmentId = ''
      this.campaignSegmentName = ''
      this.campaignExcludedSegment = []
      this.campaignSenderAddressId = ''
      this.campaignSenderAddress = ''
      this.campaignSenderName = ''
      this.campaignTheme = ''
      this.campaignTemplateId = ''
      this.campaignPreheader = ''
      this.campaignHTMLbody = ''
      this.campaignOwnerId = ''
      this.campaignOwnerName = ''
       this.campaignType = 'Default'
      this.campaignPeriodType = "Daily"
      this.campaignDispatchType = 'Now'
      this.campaignDispatchDate = ''
      this.campaignProgress = 0
      this.campaignAllRecipients = 0
      this.campaignSent = 0
      this.campaignDelivered = 0
      this.campaignErrors = 0
      this.campaignOpened = 0
      this.campaignClicked = 0
      this.campaignCompanyId = ''
      this.campaignTestSenders = ''
      this.campaignTemplateHtmlCode = ''
      this.campaignClicks = []
      this.campaignClickAddressees = []
    },
    setCampaignPaginationDefault() {
      this.campaignsCurrentPage = 1
      this.campaignsPerPage = 20
      this.campaignsLastPage = 1
    },
    setFilterDefault() {
      this.filterFrom = ''
      this.filterTo = ''
      this.filterSample = ''
      this.filterNameCampaigns = ''
      this.filterEmail = ''
    },
    setEmailSenderDefault() {
      this.senderId = ''
      this.senderAddress = ''
      this.senderName = ''
      this.senderCreatedAt = ''
      this.senderState = ''
      this.senderCompanyId = ''
    },
    async getCampaignEmailAllStatistics() {
      this.isLoading = true
      axios
        .get('api/v1/email_campaigns/all_stats')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignsActiveCount = response.data.active_count || 0
            this.campaignsFinishedCount = response.data.finished_count || 0
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignsEmail(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get('api/v1/email_campaigns', { params: this.emailFilterCampaignData, page: this.campaignsCurrentPage, per: perPage })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaigns = response.data.data
            this.campaignsCount = response.data.total
            this.campaignsCurrentPage = response.data.current_page
            this.campaignsLastPage = response.data.last_page
            console.log(response.data.data)
            console.log(this.preparedCalendarCampaigns)
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignsEmailWithCompany(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }

      this.isLoading = true
      axios
        .get('api/v1/email_campaigns', params)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaigns = response.data.data
            this.campaignsCount = response.data.total
            this.campaignsCurrentPage = response.data.current_page
            this.campaignsLastPage = response.data.last_page

            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmail(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/email_campaigns/${this.campaignId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignName = response.data.name
            this.campaignSenderAddress = response.data?.email_sender_name_address || ''
            this.campaignSenderName = response.data?.email_sender_name_name || ''
            this.campaignHTMLbody = response.data.email_template_body
            this.campaignDispatchType = response.data.send_time_kind
            this.campaignDispatchDate = response.data.send_at || ''
            this.campaignType = response.data.send_type
            this.campaignPeriodType = response.data.send_period
            this.campaignState = response.data.state
            this.campaignSegmentId = response.data.recipients_sourceable_id
            this.campaignExcludedSegment = response.data.excluded_segment_ids === null ? [] : response.data.excluded_segment_ids.map(segmentId => {return segmentId.toString()})
            this.campaignSegmentName = response.data.segment_name || '-'
            this.campaignStartAt = response.data.sent_at || ''
            this.campaignTheme = response.data.subject || ''
            this.campaignPreheader = response.data.preheader || ''
            this.campaignSenderAddressId = response.data.email_sender_name_id
            this.campaignCompanyId = response.data.company_id
            this.campaignTemplateId = (response.data.email_template_id === null || response.data.email_template_id === undefined) ? '' :  response.data.email_template_id.toString()
            this.campaignTemplateHtmlCode = response.data.email_template_body
            this.campaignCompanyToken = response.data?.company.email_builder_secret  
            if (this.campaignTemplateId) {
              this.getCampaignEmailTemplate(this.campaignTemplateId)
            }
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailClicks(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/email_campaigns/${this.campaignId}/links`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignClicks = response.data
            this.campaignClicks = this.campaignClicks.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailClickInfo(currentIndex) {
      this.isLoading = true
      axios
        .get(`api/v1/email_campaigns/${this.campaignId}/links/${currentIndex}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignClickAddressees = response.data.addressees_who_clicks
          } else {
            console.log(response)
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailAddressees(currentPerPage) {
      let perPage = currentPerPage || this.campaignsPerPage
      this.isLoading = true
      axios
        .get(`api/v1/email_campaigns/${this.campaignId}/addressees`, { params: {page: this.campaignsCurrentPage, per: perPage}})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignAddressees = response.data.data
            this.campaignsCurrentPage = response.data.current_page
            this.campaignsLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailStatistics() {
      this.isLoading = true
      axios
        .get(`api/v1/email_campaigns/${this.campaignId}/stats`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignAllRecipients = response.data.addressee_count
            this.campaignSent = response.data.sent_count
            this.campaignDelivered = response.data.delivered_count
            this.campaignOpened = response.data.opened_count
            this.campaignClicked = response.data.clicked_count
            this.campaignErrors = response.data.errors_count + response.data.hard_bounced_count + response.data.soft_bounced_count + response.data.spam_count
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createCampaignEmail() {
      this.isLoading = true
      axios
        .post('api/v1/email_campaigns', this.emailCampaignData)
        .then((response) => {
          if (response.status === 201 && response.data.email_campaign) {
            if (response.data.email_campaign.state !== 'Draft') {
              this.startCampaignEmail(response.data.email_campaign.id)
            }
            router.push({name: 'newsletter_email'})
            toast.success('Email-рассылка успешно создана')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCampaignEmail() {
      this.isLoading = true
      axios
        .post(`/api/v1/email_campaigns/${this.campaignId}`, this.emailCampaignData)
        .then((response) => {
          if (response.status === 200 && response.data.email_campaign) {
            toast.success('Email-рассылка успешно обновлена')
            router.push({name: 'newsletter_email'})
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async startCampaignEmail(payload) {
      let campaignId = ''
      if (typeof payload == 'object' || payload === undefined) {
        campaignId  = parseInt(this.campaignId)
      } else {
        campaignId = parseInt(payload)
      }
      this.campaignId = campaignId
      this.isLoading = true
      axios
        .post(`api/v1/email_campaigns/${campaignId}/start`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Email-рассылка успешно запущена')
            this.getCampaignEmail()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async pauseCampaignEmail() {
      this.isLoading = true
      axios
        .post(`api/v1/email_campaigns/${this.campaignId}/pause`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Email-рассылка успешно остановлена')
            this.getCampaignEmail()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteCampaignEmail(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let campaignId = ''
        if (typeof payload == 'object' || payload === undefined) {
          campaignId  = parseInt(this.campaignId)
        } else {
          campaignId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/email_campaigns/${campaignId}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              this.getCampaignsEmail()
              router.push({name: 'newsletter_email'})
              toast.success('Email-кампания успешно удалена')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async callTestCampaignEmail() {
      this.isLoading = true
      axios
        .post('api/v1/email_campaigns/test_message', this.emailTestCampaignData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Тестовая рассылка успешно отправлена')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailTemplates(currentPerPage) {
      let perPage = currentPerPage || this.campaignsPerPage
      this.isLoading = true
      axios
        .get('https://email-builder.prototypes.carbonfay.ru/api/v1/email_templates', 
        { 
          params: { page: this.campaignsCurrentPage, per: perPage},
          headers: {
            Authorization: `Bearer ${this.campaignCompanyToken}`
          } 
        })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaignsCurrentPage = response.data.pagination.current
            this.campaignsLastPage = response.data.pagination.total_pages
            this.templates = response.data.data
            this.templateCount = response.data.pagination.total_count
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailTemplatesWithCompany(currentCompanyToken, callback = {}) {
      let perPage = 100
      this.isLoading = true
      axios
        .get('https://email-builder.prototypes.carbonfay.ru/api/v1/email_templates', 
        { 
          params: { page: this.campaignsCurrentPage, per: perPage},
          headers: {
            Authorization: `Bearer ${currentCompanyToken}`
          } 
        })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.campaignsCurrentPage = response.data.pagination.current
            this.campaignsLastPage = response.data.pagination.total_pages
            this.templates = response.data.data
            this.templateCount = response.data.pagination.total_count
            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailTemplate(payload) {
      let templateId = ''
      if (typeof payload == 'object' || payload === undefined) {
        templateId  = parseInt(this.templateId)
      } else {
        templateId = parseInt(payload)
      }
      this.isLoading = true
      axios
        .get(`https://email-builder.prototypes.carbonfay.ru/api/v1/email_templates/${templateId}`, 
        { 
          headers: {
            Authorization: `Bearer ${this.campaignCompanyToken}`
          } 
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignTemplateHtmlCode = response.data.template
            this.templateCreatedAt = response.data.created_at
            this.templateName = response.data.name
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async duplicateCampaignEmailTemplate(payload) {
      let templateId = ''
      if (typeof payload == 'object' || payload === undefined) {
        templateId  = parseInt(this.templateId)
      } else {
        templateId = parseInt(payload)
      }
      this.isLoading = true
      axios
        .get(`https://email-builder.prototypes.carbonfay.ru/api/v1/email_templates/${templateId}/clone`, 
        { 
          headers: {
            Authorization: `Bearer ${this.campaignCompanyToken}`
          } 
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.getCampaignEmailTemplates()
            toast.success(`Email-шаблон успешно продублирован`)
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteCampaignEmailTemplate(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let templateId = ''
        if (typeof payload == 'object' || payload === undefined) {
          templateId  = parseInt(this.templateId)
        } else {
          templateId = parseInt(payload)
        }
        this.isLoading = true
        axios
        .delete(`https://email-builder.prototypes.carbonfay.ru/api/v1/email_templates/${templateId}`, 
        { 
          headers: {
            Authorization: `Bearer ${this.campaignCompanyToken}`
          } 
        })
        .then((response) => {
          if (response.status === 200 && response) {
            console.log(response)
            this.getCampaignEmailTemplates()
            toast.success(`Email-шаблон успешно удалён`)
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
      }
    },
    async getCampaignEmailSenders(currentPerPage, confirmedOnly = false) {
      let perPage = currentPerPage || this.campaignsPerPage
      this.isLoading = true
      let extraParams = confirmedOnly ? '?state=confirmed' : ''
      axios
        .get(`api/v1/email_sender_names${extraParams}`, { params: { page: this.campaignsCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.senders = response.data.data
            this.campaignsCurrentPage = response.data.current_page
            this.campaignsLastPage = response.data.last_page
            this.sendersCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailSendersWithCompany(companyId,  confirmedOnly = false, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}
      if (confirmedOnly) {
        params.state = true
      }

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }
      this.isLoading = true
      axios
        .get(`api/v1/email_sender_names`, params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.senders = response.data.data
            this.campaignsCurrentPage = response.data.current_page
            this.campaignsLastPage = response.data.last_page
            this.sendersCount = response.data.total

            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignEmailSender() {
      this.isLoading = true
      axios
        .get(`api/v1/email_sender_names/${this.senderId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.senderName = response.data.name
            this.senderAddress = response.data.address
            this.senderCreatedAt = response.data.created_at
            this.senderState = response.data.state
            this.senderCompanyId = response.data.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createCampaignEmailSender() {
      this.isLoading = true
      axios
        .post('/api/v1/email_sender_names', this.emailSenderData)
        .then((response) => {
          if (response.status === 201 && response.data) {
            this.getCampaignEmailSenders()
            toast.success('Email-отправителя успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCampaignEmailSender() {
      axios
        .post(`api/v1/email_sender_names/${this.senderId}`, this.emailSenderData)
        .then((response) => {
          if (response.status === 200 && response.data.email_sender_name) {
            this.senderName = response.data.email_sender_name.name
            this.senderAddress = response.data.email_sender_name.address
            this.senderCreatedAt = response.data.email_sender_name.created_at
            this.senderState = response.data.email_sender_name.state
            this.senderCompanyId = response.data.email_sender_name.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async deleteCampaignEmailSender(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let senderId = ''
        if (typeof payload == 'object' || payload === undefined) {
          senderId  = parseInt(this.senderId)
        } else {
          senderId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/email_sender_names/${senderId}`)
          .then((response) => {
            if (response.status === 200 && response.data.email_sender_name) {
              this.getCampaignEmailSenders()
              toast.success('Email-отправитель успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
  },
})