import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"
import { getFilterText, prettyDate } from '@/helpers/prettyPrint'
import { exportProfileParams } from '@/constants/profileConfigVariables'

const toast = useToast()

export const useProfilesStore = defineStore({
  id: 'profiles',
  state: () => ({
    profiles: [],
    profileId: '',
    profileEvents: [],
    filterEventsType: 'day',
    eventWaveDataY: [],
    eventWaveDataX: [],
    eventCurrentPage: 1,
    eventPerPage: 20,
    eventLastPage: 1,
    eventRules: '',
    profileRules: '',
    profile: {},
    similarProfiles: [],
    segments: [],
    segmentId: '',
    segmentName: '',
    segmentRules: '',
    segmentCompanyId: '',
    segmentProfiles: [],
    segmentUniqueProfileOnly: false,
    segmentUniqueProfileType: 'first',
    segmentProfilesCount: 0,
    segmentDescription: '',
    segmentAuthorId: '',
    segmentAuthorName: '',
    segmentLocked: false,
    segmentStatic: false,
    segmentProfileAllCount: 0,
    segmentProfilesCurrentPage: 1,
    segmentProfilesLastPage: 1,
    segmentProfileAllUniqCount: 0,
    segmentProfileAllWithEmailCount: 0,
    segmentProfileAllWithPhoneCount: 0,
    segmentTermsCount: 1,
    segmentCurrentPage: 1,
    segmentPerPage: 20,
    segmentLastPage: 1,
    segmentState: '',
    insights: [],
    insightId: '',
    insightName: '',
    insightRules: '',
    insightCompanyId: '',
    insightPrice: '',
    insightCurrentPage: 1,
    insightPerPage: 20,
    insightLastPage: 1,
    lookLikes: [],
    lookLikeId: '',
    lookLikeName: '',
    lookLikeSegmentId: '',
    lookLikeAccuracy: '50',
    lookLikeState: 0,
    lookLikeCompanyId: '',
    lookLikeExportFields: '',
    lookLikeExportType: '',
    lookLikeCurrentPage: 1,
    lookLikePerPage: 200,
    lookLikeLastPage: 1,
    profileCreatedAt: '',
    profileUpdatedAt: '',
    profileCompanyId: '',
    pixelId: '',
    firstName: '',
    lastName: '',
    birthdate: '',
    phone: '',
    email: '',
    city: '',
    mongoProfileMold: '',
    uid: '',
    country: '',
    otherNames: [],
    otherLanguages: [],
    interests: [],
    gender: 'Male',
    genderAuto: '',
    weight: 0,
    height: 0,
    time_utc: '',
    hasChildren: false,
    isMarried: false,
    hasPet: false,
    hasCar: false,
    dataLegals: [],
    communicationLegals: [],
    idsTypes: [],
    idsPhones: [],
    idsEmails: [],
    idsSocialsVk: [],
    idsSocialsOdn: [],
    idsPixelsYandex: [],
    idsPixelsMailru: [],
    idsPixelsGoogle: [], 
    geoPositions: [],
    geoPositionAuto: '',
    geoCities: [],
    geoCountries: [],
    geoPositionByIp: '',
    geoPositionByGps: '',
    geoPositionReceipts: '',
    activities: [],
    activityDates: [],
    visits: [],
    visitsCount: 0,
    visitsUrls: [],
    visitsAvgSurfingTimesOfDay: 0,
    visitsAvgDurationSec: 0,
    visitsIps: [],
    visitsFingerprintIds: [],
    visitsSessionIds: [],
    visitsUrlKeywords: [],
    visitsUtmKeywords: [],
    visitsDeviceTypes: [],
    visitsUseragents: [],
    visitsSentForms: [],
    visitsDevices: [],
    visitsDisplayResolutions: [],
    visitsFormPersonals: [],
    macAddress: '',
    consentToEmail: '',
    consentToSms: '',
    createdAt: '',
    updatedAt: '',
    customFields: [],
    profileMode: '',
    profileAllCount: 0,
    profileAllUniqCount: 0,
    profileAllWithEmailCount: 0,
    profileAllWithPhoneCount: 0,
    exportFiles: [],
    exportSelectionType: '-',
    exportSelection: '-',
    exportFormat: 'YandexMd5EmailAndMd5PhoneCSV',
    exportFormatType: 'csv',
    exportCompanyId: '',
    exportRules: '',
    exportCurrentPage: 1,
    exportPerPage: 20,
    exportLastPage: 1,
    exportFields: exportProfileParams,
    currentPage: 1,
    perPage: 200,
    lastPage: 1,
    isLoading: false,
    isProfilesLoading: false,
    isSegmentsLoading: false,
    isInsightsLoading: false,
    isLookAlikessLoading: false,
    isProfileCountLoading: false,
    isExportsLoading: false
  }),
  getters: {
    profileData: (state) => {
      let profileData = {}
      if(state.pixelId) {
        profileData['pixel_id'] = parseInt(state.pixelId)
      }
      if(state.firstName) {
        profileData['personal_name'] = state.firstName
      } 
      if(state.lastName){
        profileData['personal_surname'] = state.lastName
      }
      profileData['bio_gender'] = state.gender || ''
      if(state.birthdate){
         profileData['bio_birth'] = state.birthdate
      }
      if(state.email || state.profileMode == 'edit'){
        profileData['personal_email'] = state.email
      }
      if(state.phone || state.profileMode == 'edit'){
        profileData['personal_phone'] = state.phone
      }
      profileData['personal_city'] = state.city || ''
      state.customFields.forEach(field => {
        if (field.type == 'Boolean') {
          profileData[field.name] = field.value === 'true' ? true : false
        } else if (field.value && field.isValid) {
          profileData[field.name] = field.value
        }
      })

      return profileData
    },
    segmentData: (state) => {
      let segmentData = {}
      if(state.segmentCompanyId) {
        segmentData['company_id'] = parseInt(state.segmentCompanyId)
      }
      if(state.segmentName) {
        segmentData['name'] = state.segmentName
      } 
      if(state.segmentRules) {
        segmentData['filter'] = state.segmentRules
      }
      if(state.segmentDescription) {
        segmentData['description'] = state.segmentDescription
      }
      if(state.segmentStatic) {
        segmentData['static'] = state.segmentStatic === 'true' ? true : false
      }
      segmentData['locked'] = state.segmentLocked === 'true' ? true : false
      segmentData['unique_only'] = state.segmentUniqueProfileOnly === 'true' ? true : false

      if(state.segmentUniqueProfileType) {
        segmentData['unique_type'] = state.segmentUniqueProfileType
      }

      return segmentData
    },
    insightData: (state) => {
      let insightData = {}
      if(state.insightCompanyId) {
        insightData['company_id'] = parseInt(state.insightCompanyId)
      }
      if(state.insightName) {
        insightData['name'] = state.insightName
      } 
      if(state.insightPrice) {
        insightData['price'] = state.insightPrice
      }
      if(state.insightRules){
        insightData['filter'] = state.insightRules
      }

      return insightData
    },
    lookLikeData: (state) => {
      let lookLikeData = {}
      if(state.lookLikeName) {
        lookLikeData['name'] = state.lookLikeName
      }
      if(state.lookLikeSegmentId) {
        lookLikeData['cdp_segment_id'] = parseInt(state.lookLikeSegmentId)
      }
      /*if(state.lookLikeSegmentId) {
        lookLikeData['precision'] = parseInt(state.lookLikeAccuracy)
      }*/
      if(state.lookLikeCompanyId) {
        lookLikeData['company_id'] = parseInt(state.lookLikeCompanyId)
      }
      
      return lookLikeData
    },
    exportData: (state) => {
      let exportData = {}
      let selectedFields = []
      
      if(state.exportFormat) {
        exportData['kind'] = state.exportFormat
      }
      if(state.exportSelection) {
        if (state.exportSelectionType != '-') {
          exportData['sourceable_id'] = parseInt(state.exportSelection)
        }
      }
      if(state.exportSelectionType) {
        if (state.exportSelectionType != '-') {
          exportData['sourceable_type'] = state.exportSelectionType
        }
      }
      if (state.exportCompanyId) {
        exportData['company_id'] = parseInt(state.exportCompanyId)
      }
      if (state.exportSelectionType == '-' && state.exportRules) {
        exportData['filter'] = state.exportRules
      }

      state.exportFields.forEach((section) => {
        section.data.forEach((fields) => {
          fields.forEach((field) => {
              if (field.selected == 'true') {
                selectedFields.push(field.name)
              }
            }
          )
        })
      })
      if(selectedFields.length > 0) {
        exportData['fields'] = selectedFields
      }
      
      return exportData
    },
    profilesScope: (state) => state.profiles.map(profile => {
      return {
        id: profile.id,
        fullname: [profile.personal_surname, profile.personal_name].join(' ').trim(),
        email: profile.personal_email,
        phone: profile.personal_phone,
        createdAt: profile.created_at,
      }
    }),
    profilesSegmentsScope: (state) => state.segmentProfiles.map(profile => {
      return {
        id: profile.id,
        fullname: [profile.personal_surname, profile.personal_name].join(' ').trim(),
        email: profile.personal_email,
        phone: profile.personal_phone,
        createdAt: profile.created_at,
      }
    }),
    profilesSimilarScope: (state) => state.similarProfiles.map(profile => {
      return {
        id: profile.id,
        fullname: [profile.personal_surname, profile.personal_name].join(' ').trim(),
        email: profile.personal_email,
        phone: profile.personal_phone,
        createdAt: profile.created_at,
      }
    }),
    eventsScope: (state) => state.profileEvents.map(profileEvent => {
      return {
        id: profileEvent.id,
        data: (JSON.parse(profileEvent.additional_event_data) || null),
        date: profileEvent.created_at,
        name: profileEvent.title,
        desc: profileEvent.title,
        place: profileEvent.remote_address,
        channel: profileEvent.url,
        params: profileEvent.pixel_event_value,
        type: profileEvent.pixel_event_kind,
        mark: profileEvent.pixel_event_tag,
        sessionId: profileEvent.session_id,
        formData: profileEvent.form_data,
        referrer: profileEvent.referrer,
        utms: JSON.parse(profileEvent.utms) || [],
        language: profileEvent.language,
        languages: profileEvent.languages,
        browser: profileEvent.browser,
        browserEngine: profileEvent.browser_engine,
        browserVersion: profileEvent.browser_version,
        manufacturrer: profileEvent.manufacturer,
        product: profileEvent.product,
        userAgent: profileEvent.user_agent,
        os: profileEvent.os,
        timezone: profileEvent.timezone,
        cookiesEnabled: profileEvent.cookies_enabled,
        cookies: profileEvent.cookies,
        deviceType: profileEvent.device_type,
        javascriptEnabled: profileEvent.javascript_enabled,
        screenColors: profileEvent.screen_colors,
        screenFormat: profileEvent.screen_format,
        screenWidth: profileEvent.screen_width,
        screenHeight: profileEvent.screen_height,
        screenOrientation: profileEvent.screen_orientation,
        pageCharset: profileEvent.page_charset,
        isIframe: profileEvent.is_iframe,
        fingerprint: profileEvent.fingerprint,
        metaKeywords: profileEvent.meta_keywords,
        pixelId: profileEvent.pixel_id
      }
    }),
    preparedSegments: (state) => state.segments.map(segment => {
      return {
        value: segment.id.toString(),
        text: segment.name
      }
    }),
    partitionSegments: (state) => {
      let size = 3
      let segments = state.segments
      segments = Array.from({ length: Math.ceil(state.segments.length / size) }, (v, i) =>
        segments.slice(i * size, i * size + size)
      )
      segments = segments.map((arraySegments, index) => {
        return {
          id: (index + 1),
          array: arraySegments.map(segment => {
            return {
              id: segment.id,
              name: segment.name,
              state: segment.state,
              filters: getFilterText(segment.filter),
              companyId: segment.company_id,
              all: segment.all_count || 0,
              uniq: segment.unique_count || 0,
              uniqProfileOnly: segment.unique_only == true ? 'true' : '',
              description: segment.description || '',
              authorName: [segment.author?.first_name, segment.author?.last_name].join(' ')  || '',
              authorId: segment.author?.id || '',
              locked: segment.locked == true ? 'true' : '',
              static: segment.static == true ? 'true' : ''
            }
          })
        }
      })

      return segments
    },
    partitionInsights: (state) => {
      let size = 3
      let insights = state.insights
      insights = Array.from({ length: Math.ceil(state.insights.length / size) }, (v, i) =>
        insights.slice(i * size, i * size + size)
      )
      insights = insights.map((arrayInsights, index) => {
        return {
          id: (index + 1),
          array: arrayInsights.map(insight => {
            return {
              id: insight.id,
              name: insight.name,
              prettyFilters: getFilterText(insight.filter !== undefined ? insight.filter : ''),
              filters: insight.filter,
              price: insight.price,              
              companyId: insight.company_id,
              all: insight.all_count || 0,
              uniq: insight.unique_count || 0
            }
          })
        }
      })

      return insights
    },
    partitionLookLikes: (state) => {
      let lookLikes = []
      state.lookLikes.forEach(lookLike => {
        let currentSegment = lookLikes.find(l => l.segmentId == lookLike.cdp_segment_id)
        if (currentSegment === undefined || currentSegment === null) {
          lookLikes.push({
            segmentId: lookLike.cdp_segment_id,
            segmentName: lookLike.cdp_segment_name,
            segmentLookLikes: [{
              id: 1,
              array: [{
                id: lookLike.id,
                name: lookLike.name,
                state: lookLike.state,
                segmentId: lookLike.cdp_segment_id,
                companyId: lookLike.company_id,
                all: lookLike.all_count || 0,
                uniq: lookLike.unique_count || 0,
                createdAt: lookLike.created_at.split('T')[0]
              }]
            }]
          })
        } else {
          if (currentSegment.segmentLookLikes[currentSegment.segmentLookLikes.length - 1].array.length > 2) {
            currentSegment.segmentLookLikes.push({
              id: currentSegment.segmentLookLikes[currentSegment.segmentLookLikes.length - 1].id + 1,
              array: [{
                id: lookLike.id,
                name: lookLike.name,
                state: lookLike.state,
                segmentId: lookLike.cdp_segment_id,
                all: lookLike.all_count || 0,
                uniq: lookLike.unique_count || 0,
                createdAt: lookLike.created_at.split('T')[0]
              }]
            })
          } else {
            currentSegment.segmentLookLikes[currentSegment.segmentLookLikes.length - 1].array.push({
              id: lookLike.id,
              name: lookLike.name,
              state: lookLike.state,
              segmentId: lookLike.cdp_segment_id,
              all: lookLike.all_count || 0,
              uniq: lookLike.unique_count || 0,
              createdAt: lookLike.created_at.split('T')[0]
            })
          }
        }
      })

      return lookLikes
    },
    preparedLookLike: (state) => state.lookLikes.map(lookLike => {
      return {
        value: lookLike.id.toString(),
        text: lookLike.name
      }
    }),
    exportFilesScope: (state) => state.exportFiles.map(file => {
      return {
        id: file.id,
        name: file.name,
        format: file.format || '',
        fileUrl: file.file_url,
        state: file.state,
        progress: file.progress,
        createdAt: file.created_at,
      }
    }),
    isExportFieldsEmpty: (state) => {
      let selectedFields = []

      state.exportFields.forEach((section) => {
        section.data.forEach((fields) => {
          fields.forEach((field) => {
              if (field.selected == 'true') {
                selectedFields.push(field.name)
              }
            }
          )
        })
      })

      return selectedFields.length == 0
    }
  },
  actions: {
    setProfileDefault() {
      this.profileId = ''
      this.profileCreatedAt = ''
      this.profileUpdatedAt = ''
      this.profileCompanyId = ''
      this.firstName = ''
      this.lastName = ''
      this.birthdate = ''
      this.email = ''
      this.phone = ''
      this.mongoProfileMold = ''
      this.uid = ''
      this.country = ''
      this.otherNames = []
      this.otherLanguages = []
      this.interests = []
      this.gender = 'Male'
      this.genderAuto = ''
      this.weight = 0
      this.height = 0
      this.time_utc = ''
      this.hasChildren = false
      this.isMarried = false
      this.hasPet = false
      this.hasCar = false
      this.dataLegals = []
      this.communicationLegals = []
      this.idsTypes = []
      this.idsPhones = []
      this.idsEmails = []
      this.idsSocialsVk = []
      this.idsSocialsOdn = []
      this.idsPixelsYandex = []
      this.idsPixelsMailru = []
      this.idsPixelsGoogle = []
      this.geoPositions = []
      this.geoPositionAuto = ''
      this.geoCities = []
      this.geoCountries = []
      this.geoPositionByIp = ''
      this.geoPositionByGps = ''
      this.geoPositionReceipts = ''
      this.activities = []
      this.activityDates = []
      this.visits = []
      this.visitsCount = 0
      this.visitsUrls = []
      this.visitsAvgSurfingTimesOfDay = 0
      this.visitsAvgDurationSec = 0
      this.visitsIps = []
      this.visitsFingerprintIds = []
      this.visitsSessionIds = []
      this.visitsUrlKeywords = []
      this.visitsUtmKeywords = []
      this.visitsDeviceTypes = []
      this.visitsUseragents = []
      this.visitsSentForms = []
      this.visitsDevices = []
      this.visitsDisplayResolutions = []
      this.visitsFormPersonals = []
      this.macAddress = ''
      this.consentToEmail = ''
      this.consentToSms = ''
      this.city = ''
      this.createdAt = ''
      this.pixelId = ''
      this.customFields = []
      this.setProfileEventDefault()
    },
    setProfileEventDefault() {
      this.profileEvents = []
      this.eventCurrentPage = 1
      this.eventPerPage = 20
      this.eventLastPage = 1
    },
    setProfileCountDefault() {
      this.profileAllCount = 0
      this.profileAllUniqCount = 0
      this.profileAllWithEmailCount = 0
      this.profileAllWithPhoneCount = 0
    },
    setSegmentDefault() {
      this.segmentId = ''
      this.segmentName = ''
      this.segmentRules = ''
      this.segmentCompanyId = ''
      this.segmentProfilesCount = 0,
      this.segmentTermsCount = 1
      this.segmentUniqueProfileOnly = false
      this.segmentUniqueProfileType = 'first'
      this.segmentDescription = ''
      this.segmentAuthorId = ''
      this.segmentAuthorName = ''
      this.segmentLocked = false
      this.segmentStatic = false
    },
    setSegmentPaginationDefault() {
      this.segmentCurrentPage = 1
      this.segmentPerPage = 20
      this.segmentLastPage = 1
    },
    setSegmentProfilesPaginationDefault() {
      this.segmentProfilesCurrentPage = 1
      this.segmentProfilesLastPage = 1
    },
    setInsightDefault() {
      this.insightId = ''
      this.insightName = ''
      this.insightRules = ''
      this.insightCompanyId = ''
    },
    setInsightPaginationDefault() {
      this.insightCurrentPage = 1
      this.insightPerPage = 20
      this.insightLastPage = 1
    },
    setLookLikeDefault() {
      this.lookLikeId = ''
      this.lookLikeName = ''
      this.lookLikeSegmentId = ''
      this.lookLikeAccuracy = '50'
      this.lookLikeState = 0
      this.lookLikeCompanyId = ''
    },
    setLookLikePaginationDefault() {
      this.lookLikeCurrentPage = 1
      this.lookLikePerPage = 200
      this.lookLikeLastPage = 1
    },
    setExportDefault() {
      this.exportFiles = []
      this.exportSelectionType = '-'
      this.exportSelection = '-'
      this.exportFormat = 'YandexMd5EmailAndMd5PhoneCSV'
      this.exportFormatType = 'csv'
      this.exportCompanyId = ''
      this.exportRules = ''
      this.exportFields.forEach((section) => {
        section.data.forEach((field) => {
          field.selected = 'false'
        })
      })
    },
    setExportPaginationDefault() {
      this.exportCurrentPage = 1
      this.exportPerPage = 20
      this.exportLastPage = 1
    },
    setPaginationDefault() {
      this.currentPage = 1
      this.perPage = 20
      this.lastPage = 1
    },
    setEventsChartDefault() {
      this.filterEventsType = 'day'
      this.eventWaveDataY = []
      this.eventWaveDataX = []
    },
    getPrettyNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    async getProfiles() {
      this.isProfilesLoading = true
      axios
        .get('api/v1/cdp_profiles')
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.profiles = response.data.data
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfilesLoading = false
        })
    },
    async getRulesProfiles(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isProfilesLoading = true

      axios
        .get('api/v1/cdp_profiles', { params: { filter: this.profileRules, company_id: this.segmentCompanyId, page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.profiles = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
            this.getProfileAllCount()
            this.getProfileAllUniqCount()
            this.getProfileAllWithEmailCount()
            this.getProfileAllWithPhoneCount()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfilesLoading = false
        })
    },
    async getProfile(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/cdp_profiles/${this.profileId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profile = response.data
            this.profileCreatedAt = response.data.created_at || '' 
            this.profileUpdatedAt = response.data.updated_at || ''
            this.pixelId = response.data.pixel_id
            this.firstName = response.data.personal_name
            this.lastName = response.data.personal_surname
            this.gender = response.data.bio_gender
            this.phone = response.data.personal_phone
            this.email = response.data.personal_email
            this.city = response.data.personal_city
            this.birthdate = response.data.bio_birth === null ? '' : prettyDate(response.data.bio_birth)
            this.mongoProfileMold = response.data.mongo_profile_mold
            this.uid = response.data.uid
            this.country = response.data.personal_country
            this.otherNames = response.data.personal_other_names === null ? '' : response.data.personal_other_names?.other_personal_name.join(',') || ''
            this.otherLanguages = response.data.personal_other_languages === null ? '' : response.data.personal_other_languages.join(',')
            this.interests = response.data.interests === null ? '' : response.data.interests.join(',')
            this.genderAuto = response.data.bio_gender_auto
            this.weight = response.data.bio_weight
            this.height = response.data.bio_height
            this.time_utc = response.data.current_time_utc
            this.hasChildren = response.data.has_children
            this.isMarried = response.data.is_married
            this.hasPet = response.data.has_pet
            this.hasCar = response.data.has_car
            //this.dataLegals = response.data.personal_data_legals === null ? '' : response.data.personal_data_legals.join(',')
            //this.communicationLegals = response.data.communication_legals === null ? '' : response.data.communication_legals.join(',')
            this.idsTypes = response.data.ids_types === null ? '' : response.data.ids_types.join(',')
            this.idsPhones = response.data.ids_phones === null ? '' : response.data.ids_phones.join(',')
            this.idsEmails = response.data.ids_emails === null ? '' : response.data.ids_emails.join(',')
            this.idsSocialsVk = response.data.ids_socials_vk === null ? '' : response.data.ids_socials_vk.join(',')
            this.idsSocialsOdn = response.data.ids_socials_odn === null ? '' : response.data.ids_socials_odn.join(',')
            this.idsPixelsYandex = response.data.ids_pixels_yandex === null ? '' : response.data.ids_pixels_yandex.join(',')
            this.idsPixelsMailru = response.data.ids_pixels_mailru === null ? '' : response.data.ids_pixels_mailru.join(',')
            this.idsPixelsGoogle = response.data.ids_pixels_google === null ? '' : response.data.ids_pixels_google.join(',')
            this.geoPositions = response.data.geo_positions === null ? '' : response.data.geo_positions.join(',')
            this.geoPositionAuto = response.data.geo_position_auto
            this.geoCities = response.data.geo_cities === null ? '' : response.data.geo_cities.join(',')
            this.geoCountries = response.data.geo_countries === null ? '' : response.data.geo_countries.join(',')
            this.geoPositionByIp = response.data.geo_position_by_ip
            this.geoPositionByGps = response.data.geo_position_by_gps
            this.geoPositionReceipts = response.data.geo_position_receipts
            this.activities = response.data.activities === null ? '' : response.data.activities.join(',')
            this.activityDates = response.data.activity_dates === null ? '' : response.data.activity_dates.join(',')
            this.visits = response.data.visits === null ? '' : response.data.visits.join(',')
            this.visitsCount = response.data.visits_count
            this.visitsUrls = response.data.visits_urls === null ? '' : response.data.visits_urls.join(',')
            this.visitsAvgSurfingTimesOfDay = response.data.visits_avg_surfing_times_of_day
            this.visitsAvgDurationSec = response.data.visits_avg_duration_sec
            this.visitsIps = response.data.visits_ips === null ? '' : response.data.visits_ips.join(',')
            this.visitsFingerprintIds = response.data.visits_fingerprint_ids === null ? '' : response.data.visits_fingerprint_ids.join(',')
            this.visitsSessionIds = response.data.visits_session_ids === null ? '' : response.data.visits_session_ids.join(',')
            this.visitsUrlKeywords = response.data.visits_url_keywords === null ? '' : response.data.visits_url_keywords.map(keywords => { return JSON.stringify(keywords) }).join(',')
            this.visitsUtmKeywords = response.data.visits_utm_keywords === null ? '' : response.data.visits_utm_keywords.map(keywords => { return JSON.stringify(keywords) }).join(',')
            this.visitsDeviceTypes = response.data.visits_device_types === null ? '' : response.data.visits_device_types.join(',')
            this.visitsUseragents = response.data.visits_useragents === null ? '' : response.data.visits_useragents.join(',')
            this.visitsSentForms = response.data.visits_sent_forms === null ? '' : response.data.visits_sent_forms.join(',')
            this.visitsDevices = response.data.visits_devices === null ? '' : response.data.visits_devices.map(devices => { return JSON.stringify(devices) }).join(',')
            this.visitsDisplayResolutions = response.data.visits_display_resolutions === null ? '' : response.data.visits_display_resolutions.join(',')
            this.visitsFormPersonals = response.data.visits_form_personals === null ? '' : response.data.visits_form_personals.join(',')
            this.profileCompanyId = response.data.company?.id || ''
            this.macAddress = response.data.mac_address || ''
            this.consentToEmail = response.data.email_consent
            this.consentToSms = response.data.sms_consent
            this.getProfileEvents()
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', {})
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getAllEvents(perPage = 100, companyId = '', callback = {}) {
      this.isLoading = true
      let currentPerPage = perPage
      let params = { params: { page: 1, per: currentPerPage }}
      if (companyId !== '') {
        params.params.company_id = companyId
      }
      axios
        .get('/api/v1/pixels/events', params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            let events = []
            for(let key in response.data) {
              events = [...events, ...response.data[key].map(element => {
                return {
                  id: element.id,
                  name: (element.name === '' ? element.kind : element.name),
                  kind: element.kind,
                  pixelName: key
                }
              })]
            }

            if (typeof callback == 'function') {
              callback('success', events)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProfileEvents(currentPerPage) {
      let perPage = currentPerPage || this.eventPerPage
      this.isLoading = true
      axios
        .get(`api/v1/cdp_profiles/${this.profileId}/events`, { params: { filter: this.eventRules, page: this.eventCurrentPage, per: perPage }})
        .then((response) => {
          if ((response.status === 200 && response.data.data) || response.status === 200) {
            this.profileEvents = response.data.data || []
            this.eventCurrentPage = response.data.current_page || 1
            this.eventLastPage = response.data.last_page || 1
            this.event = response.data.event
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProfileEventsChart() {
      this.isLoading = true
      axios
        .get(`api/v1/cdp_profiles/${this.profileId}/stats?type=${this.filterEventsType}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.eventWaveDataY= [
              {
                name: 'События',
                data: response.data.events_count.map(profileCount =>  parseInt(profileCount))
              }
            ]
            this.eventWaveDataX = response.data.labels
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createProfile() {
      this.isLoading = true
      axios
        .post('api/v1/cdp_profiles', this.profileData)
          .then((response) => {
            if (response.status === 201 && response.data) {
              this.setProfileDefault()
              this.getRulesProfiles()
              toast.success('Профиль создан')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async updateProfile() {
      this.isLoading = true
      axios
        .post(`api/v1/cdp_profiles/${this.profileId}`, this.profileData)
        .then((response) => {
          if (response.status === 200 && response.data.cdp_profile) {
            this.pixelId = response.data.cdp_profile.pixel_id
            this.profileCreatedAt = response.data.cdp_profile.created_at || ''
            this.profileUpdatedAt = response.data.cdp_profile.updated_at || ''
            this.firstName = response.data.cdp_profile.personal_name 
            this.lastName = response.data.cdp_profile.personal_surname
            this.gender = response.data.cdp_profile.bio_gender
            this.phone = response.data.cdp_profile.personal_phone
            this.email = response.data.cdp_profile.personal_email
            this.city = response.data.cdp_profile.personal_city
            this.birthdate = response.data.cdp_profile.bio_birth === null ? '' : prettyDate(response.data.cdp_profile.bio_birth)
            this.mongoProfileMold = response.data.cdp_profile.mongo_profile_mold
            this.uid = response.data.cdp_profile.uid
            this.country = response.data.cdp_profile.personal_country
            this.otherNames = response.data.cdp_profile.personal_other_names === null ? '' : response.data.cdp_profile.personal_other_names?.other_personal_name.join(',')  || ''
            this.otherLanguages = response.data.cdp_profile.personal_other_languages === null ? '' : response.data.cdp_profile.personal_other_languages.join(',')
            this.interests = response.data.cdp_profile.interests === null ? '' : response.data.cdp_profile.interests.join(',')
            this.genderAuto = response.data.cdp_profile.bio_gender_auto
            this.weight = response.data.cdp_profile.bio_weight
            this.height = response.data.cdp_profile.bio_height
            this.time_utc = response.data.cdp_profile.current_time_utc
            this.hasChildren = response.data.cdp_profile.has_children
            this.isMarried = response.data.cdp_profile.is_married
            this.hasPet = response.data.cdp_profile.has_pet
            this.hasCar = response.data.cdp_profile.has_car
            this.dataLegals = response.data.cdp_profile.personal_data_legals === null ? '' : response.data.cdp_profile?.personal_data_legals.join(',')
            //this.communicationLegals = response.data.cdp_profile.communication_legals === null ? '' : response.data.cdp_profile.communication_legals.join(',')
            this.idsTypes = response.data.cdp_profile.ids_types === null ? '' : response.data.cdp_profile.ids_types.join(',')
            this.idsPhones = response.data.cdp_profile.ids_phones === null ? '' :  response.data.cdp_profile.ids_phones.join(',')
            this.idsEmails = response.data.cdp_profile.ids_emails === null ? '' : response.data.cdp_profile.ids_emails.join(',')
            this.idsSocialsVk = response.data.cdp_profile.ids_socials_vk === null ? '' : response.data.cdp_profile.ids_socials_vk.join(',')
            this.idsSocialsOdn = response.data.cdp_profile.ids_socials_odn === null ? '' : response.data.cdp_profile.ids_socials_odn.join(',')
            this.idsPixelsYandex = response.data.cdp_profile.ids_pixels_yandex === null ? '' : response.data.cdp_profile.ids_pixels_yandex.join(',')
            this.idsPixelsMailru = response.data.cdp_profile.ids_pixels_mailru === null ? '' : response.data.cdp_profile.ids_pixels_mailru.join(',')
            this.idsPixelsGoogle = response.data.cdp_profile.ids_pixels_google === null ? '' : response.data.cdp_profile.ids_pixels_google.join(',')
            this.geoPositions = response.data.cdp_profile.geo_positions === null ? '' : response.data.cdp_profile.geo_positions.join(',')
            this.geoPositionAuto = response.data.cdp_profile.geo_position_auto
            this.geoCities = response.data.cdp_profile.geo_cities === null ? '' : response.data.cdp_profile.geo_cities.join(',')
            this.geoCountries = response.data.cdp_profile.geo_countries === null ? '' : response.data.cdp_profile.geo_countries.join(',')
            this.geoPositionByIp = response.data.cdp_profile.geo_position_by_ip
            this.geoPositionByGps = response.data.cdp_profile.geo_position_by_gps
            this.geoPositionReceipts = response.data.cdp_profile.geo_position_receipts
            this.activities = response.data.cdp_profile.activities === null ? '' : response.data.cdp_profile.activities.join(',')
            this.activityDates = response.data.cdp_profile.activity_dates === null ? '' : response.data.cdp_profile.activity_dates.join(',')
            this.visits = response.data.cdp_profile.visits === null ? '' : response.data.cdp_profile.visits.join(',')
            this.visitsCount = response.data.cdp_profile.visits_count
            this.visitsUrls = response.data.cdp_profile.visits_urls === null ? '' : response.data.cdp_profile.visits_urls.join(',')
            this.visitsAvgSurfingTimesOfDay = response.data.cdp_profile.visits_avg_surfing_times_of_day
            this.visitsAvgDurationSec = response.data.cdp_profile.visits_avg_duration_sec
            this.visitsIps = response.data.cdp_profile.visits_ips === null ? '' : response.data.cdp_profile.visits_ips.join(',')
            this.visitsFingerprintIds = response.data.cdp_profile.visits_fingerprint_ids === null ? '' : response.data.cdp_profile.visits_fingerprint_ids.join(',')
            this.visitsSessionIds = response.data.cdp_profile.visits_session_ids === null ? '' : response.data.cdp_profile.visits_session_ids.join(',')
            this.visitsUrlKeywords = response.data.cdp_profile.visits_url_keywords === null ? '' : response.data.cdp_profile.visits_url_keywords.map(keywords => { return JSON.stringify(keywords) }).join(',')
            this.visitsUtmKeywords = response.data.cdp_profile.visits_utm_keywords === null ? '' : response.data.cdp_profile.visits_utm_keywords.map(keywords => { return JSON.stringify(keywords) }).join(',')
            this.visitsDeviceTypes = response.data.cdp_profile.visits_device_types === null ? '' : response.data.cdp_profile.visits_device_types.join(',')
            this.visitsUseragents = response.data.cdp_profile.visits_useragents === null ? '' : response.data.cdp_profile.visits_useragents.join(',')
            this.visitsSentForms = response.data.cdp_profile.visits_sent_forms === null ? '' : response.data.cdp_profile.visits_sent_forms.join(',')
            this.visitsDevices = response.data.cdp_profile.visits_devices === null ? '' : response.data.cdp_profile.visits_devices.map(devices => { return JSON.stringify(devices)}).join(',')
            this.visitsDisplayResolutions = response.data.cdp_profile.visits_display_resolutions === null ? '' : response.data.cdp_profile.visits_display_resolutions.join(',')
            this.visitsFormPersonals = response.data.cdp_profile.visits_form_personals === null ? '' : response.data.cdp_profile.visits_form_personals.join(',')
            this.profileCompanyId = response.data.cdp_profile.company?.id || ''
            this.macAddress = response.data.cdp_profile.mac_address || ''
            this.consentToEmail = response.data.email_consent
            this.consentToSms = response.data.sms_consent
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteProfile(payload, areaEvent) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let profileId = ''
        if (typeof payload == 'object') {
          profileId = parseInt(this.profileId)
        } else {
          profileId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/cdp_profiles/${profileId}`)
          .then((response) => {
            if (response.status === 200 && response.data.cdp_profile) {
              if (areaEvent == 'profile') {
                this.getRulesProfiles()
              } else if (areaEvent == 'segment') {
                this.getSegmentProfiles()
              } else if (areaEvent == 'insight') {
                this.getInsightProfiles()
              } else if (areaEvent == 'lookalike') {
              }
              toast.success('Профиль успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getSimilarProfile(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/cdp_profiles/${this.profileId}/similar?per=20`)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.similarProfiles = response.data.data

            if (typeof callback == 'function') callback('success', response.data.data)
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') callback('failed', {})
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') callback('failed', {})
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProfileAllCount() {
      this.isProfileCountLoading = true
      axios
        .get('api/v1/cdp_profiles/all_count', { params: { filter: this.profileRules }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllCount = this.getPrettyNumber(response.data.all_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getProfileAllUniqCount() {
      this.isProfileCountLoading = true
      axios
        .get('api/v1/cdp_profiles/unique_count', { params: { filter: this.profileRules }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllUniqCount = this.getPrettyNumber(response.data.unique_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getProfileAllWithEmailCount() {
      this.isProfileCountLoading = true
      axios
        .get('api/v1/cdp_profiles/with_email_count', { params: { filter: this.profileRules }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllWithEmailCount = this.getPrettyNumber(response.data.with_email_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getProfileAllWithPhoneCount() {
      this.isProfileCountLoading = true
      axios
        .get('api/v1/cdp_profiles/with_phone_count', { params: { filter: this.profileRules }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllWithPhoneCount = this.getPrettyNumber(response.data.with_phone_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getSegments(currentPerPage) {
      let perPage = currentPerPage || this.segmentPerPage
      this.isSegmentsLoading = true
      axios
        .get('api/v1/cdp_segments', { params: { page: this.segmentCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.segments = response.data.data
            this.segmentCurrentPage = response.data.current_page
            this.segmentLastPage = response.data.last_page
            
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isSegmentsLoading = false
        })
    },
    async getCompanySegments(companyId, currentPerPage) {
      let perPage = currentPerPage || this.segmentPerPage
      this.isSegmentsLoading = true
      axios
        .get(`api/v1/cdp_segments?company_id=${companyId}`, { params: { page: this.segmentCurrentPage, per: perPage}})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.segments = response.data.data
            this.segmentCurrentPage = response.data.current_page
            this.segmentLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isSegmentsLoading = false
        })
    },
    async getSegmentsWithCompany(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }
      
      //this.isSegmentsLoading = true
      axios
        .get('api/v1/cdp_segments', params)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            //this.segments = response.data.data
            //this.segmentCurrentPage = response.data.current_page
            //this.segmentLastPage = response.data.last_page

            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isSegmentsLoading = false
        })
    },
    async getSegment(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/cdp_segments/${this.segmentId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.segmentName = response.data.name
            this.segmentRules = response.data.filter
            this.segmentCompanyId = response.data.company_id
            this.segmentProfileAllCount = this.getPrettyNumber(response.data.all_count)
            this.segmentProfileAllUniqCount = this.getPrettyNumber(response.data.unique_count)
            this.segmentProfileAllWithEmailCount = this.getPrettyNumber(response.data.with_email_count)
            this.segmentProfileAllWithPhoneCount = this.getPrettyNumber(response.data.with_phone_count)
            this.segmentState = response.data?.state || 'Counted'
            this.segmentUniqueProfileOnly = response.data.unique_only == true ? 'true' : ''
            this.segmentUniqueProfileType = response.data.unique_type || 'first'
            this.segmentDescription = response.data.description || ''
            this.segmentLocked = response.data.locked == true ? 'true' : ''
            this.segmentStatic = response.data.static == true ? 'true' : ''
            this.segmentAuthorId = response.data?.author?.id || ''
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', {})
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSegmentFilterInfo() {
      this.isLoading = true
      axios
        .get(`api/v1/cdp_segments/${this.segmentId}/filter_info`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.segmentProfilesCount = response.data.profiles_count
            this.segmentTermsCount = response.data.filter_rules_count
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSegmentProfiles(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isProfilesLoading = true
      axios
        .get(`api/v1/cdp_segments/${this.segmentId}/profiles`, { params: { page: this.segmentProfilesCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.segmentProfiles = response.data.data
            this.segmentProfilesCurrentPage = response.data.current_page
            this.segmentProfilesLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfilesLoading = false
        })
    },
    async getSegmentProfileAllCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/cdp_segments/${this.segmentId}/profiles/all_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.segmentProfileAllCount = this.getPrettyNumber(response.data.all_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfilesLoading = false
        })
    },
    async getSegmentProfileAlUniqCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/cdp_segments/${this.segmentId}/profiles/unique_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.segmentProfileAllUniqCount = this.getPrettyNumber(response.data.unique_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getSegmentProfileAllWithEmailCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/cdp_segments/${this.segmentId}/profiles/with_email_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.segmentProfileAllWithEmailCount = this.getPrettyNumber(response.data.with_email_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getSegmentProfileAllWithPhoneCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/cdp_segments/${this.segmentId}/profiles/with_phone_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.segmentProfileAllWithPhoneCount = this.getPrettyNumber(response.data.with_phone_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async createSegment(payload) {
      this.isLoading = true
      axios
        .post('api/v1/cdp_segments', this.segmentData)
          .then((response) => {
            if (response.status === 201 && response.data) {
              this.getSegments()
              toast.success('Сегмент создан')
              if (payload == 'profile') {
                this.profileRules = this.segmentRules
                this.setSegmentDefault()
              } else {
                this.setSegmentDefault()
              }
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async createSegmentFromInsight(payload) {
      this.isLoading = true
      this.segmentName = payload.segmentName
      this.segmentCompanyId = payload.segmentCompanyId
      this.segmentRules = payload.segmentRules
      axios
        .post('api/v1/cdp_segments', this.segmentData)
          .then((response) => {
            if (response.status === 201 && response.data) {
              this.setSegmentDefault()
              router.push({name: 'clients_segments'})
              toast.success('Инсайт успешно скопирован в сегменты')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async updateSegment(callback = {}) {
      axios
        .post(`api/v1/cdp_segments/${this.segmentId}`, this.segmentData)
        .then((response) => {
          if (response.status === 200 && response.data.cdp_segment) {
            this.segmentName = response.data.cdp_segment.name
            this.segmentRules = response.data.cdp_segment.filter
            this.segmentCompanyId = response.data.cdp_segment.company_id
            this.segmentUniqueProfileOnly = response.data.cdp_segment.unique_only == true ? 'true' : ''
            this.segmentUniqueProfileType = response.data.cdp_segment.unique_type || 'first'
            this.segmentDescription = response.data.cdp_segment.description || ''
            this.segmentLocked = response.data.cdp_segment.locked == true ? 'true' : ''
            this.segmentStatic = response.data.cdp_segment.static == true ? 'true' : ''
            if (typeof callback == 'function') {
              callback('success')
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed')
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
          if (typeof callback == 'function') {
            callback('failed')
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteSegment(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let segmentId = ''
        if (typeof payload == 'object') {
          segmentId = parseInt(this.segmentId)
        } else {
          segmentId = parseInt(payload)
        }
        axios
          .delete(`api/v1/cdp_segments/${segmentId}`)
          .then((response) => {
            if (response.status === 200 && response.data.cdp_segment) {
              this.getSegments()
              toast.success('Сегмент успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getInsights(currentPerPage) {
      let perPage = currentPerPage || this.insightPerPage
      this.isInsightsLoading = true
      axios
        .get('api/v1/cdp_insights', { params: { page: this.insightCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.insights = response.data.data
            this.insightCurrentPage = response.data.current_page
            this.insightLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isInsightsLoading = false
        })
    },
    async getInsight() {
      this.isLoading = true
      axios
        .get(`api/v1/cdp_insights/${this.insightId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.insightName = response.data.name
            this.insightPrice = response.data.price
            this.insightRules = response.data.filter
            this.insightCompanyId = response.data.company_id
            this.profileAllCount = this.getPrettyNumber(response.data.all_count)
            this.profileAllUniqCount = this.getPrettyNumber(response.data.unique_count)
            this.profileAllWithEmailCount = this.getPrettyNumber(response.data.with_email_count)
            this.profileAllWithPhoneCount = this.getPrettyNumber(response.data.with_phone_count)
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getInsightProfiles(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isProfilesLoading = true
      axios
        .get(`api/v1/cdp_insights/${this.insightId}/profiles`, { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.profiles = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfilesLoading = false
        })
    },
    async getInsightProfileAllCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/cdp_insights/${this.insightId}/profiles/all_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllCount = this.getPrettyNumber(response.data.all_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getInsightProfileAlUniqCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/cdp_insights/${this.insightId}/profiles/unique_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllUniqCount = this.getPrettyNumber(response.data.unique_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getInsightProfileAllWithEmailCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/cdp_insights/${this.insightId}/profiles/with_email_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllWithEmailCount = this.getPrettyNumber(response.data.with_email_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getInsightProfileAllWithPhoneCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/cdp_insights/${this.insightId}/profiles/with_phone_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllWithPhoneCount = this.getPrettyNumber(response.data.with_phone_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async createInsight() {
      this.isLoading = true
      axios
        .post('api/v1/cdp_insights', this.insightData)
          .then((response) => {
            if (response.status === 201 && response.data) {
              this.getInsights()
              toast.success('Инсайт создан')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async updateInsight() {
      this.isLoading = true
      axios
        .post(`api/v1/cdp_insights/${this.insightId}`, this.insightData)
        .then((response) => {
          if (response.status === 200 && response.data.cdp_insight) {
            this.insightName = response.data.cdp_insight.name
            this.insightRules = response.data.cdp_insight.filter
            this.insightCompanyId = response.data.cdp_insight.company_id
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteInsight(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let insightId = ''
        if (typeof payload == 'object') {
          insightId = parseInt(this.insightId)
        } else {
          insightId = parseInt(payload)
        }
        axios
          .delete(`api/v1/cdp_insights/${insightId}`)
          .then((response) => {
            if (response.status === 200 && response.data.cdp_insight) {
              this.getInsights()
              toast.success('Инсайт успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async buyInsigt(id) {
      this.isLoading = true
      axios
        .post(`api/v1/cdp_insights/${id}/purchase`)
          .then((response) => {
            if ((response.status === 200 || response.status === 201)) {
              this.getInsights()
              toast.success('Сегмент из инсайта создан.')
            } else if(response.status === 402) {
             
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 402) {
              toast.error('На счёте компании недостаточно средств. Пополните баланс и попробуйте снова.') 
            } else {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async getLookLikes(currentPerPage) {
      let perPage = currentPerPage || this.lookLikePerPage
      this.isLookAlikessLoading = true
      axios
        .get('api/v1/look_a_likes', { params: { page: this.lookLikeCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.lookLikes = response.data.data
            this.lookLikeCurrentPage = response.data.current_page
            this.lookLikeLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLookAlikessLoading = false
        })
    },
    async getLookLike() {
      this.isLoading = true
      axios
        .get(`api/v1/look_a_likes/${this.lookLikeId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.lookLikeName = response.data.name
            this.lookLikeSegmentId = response.data.cdp_segment_id
            this.lookLikeCompanyId = response.data.company_id
            this.lookLikeAccuracy = response.data.precision
            this.lookLikeState = response.data.state
            this.profileAllCount = this.getPrettyNumber(response.data.all_count)
            this.profileAllUniqCount = this.getPrettyNumber(response.data.unique_count)
            this.profileAllWithEmailCount = this.getPrettyNumber(response.data.with_email_count)
            this.profileAllWithPhoneCount = this.getPrettyNumber(response.data.with_phone_count)
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getLookLikeProfiles(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isProfilesLoading = true
      axios
        .get(`api/v1/look_a_likes/${this.lookLikeId}/profiles`, { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.profiles = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfilesLoading = false
        })
    },
    async getLookLikeProfileAllCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/look_a_likes/${this.lookLikeId}/profiles/all_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllCount = this.getPrettyNumber(response.data.all_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getLookLikeProfileAlUniqCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/look_a_likes/${this.lookLikeId}/profiles/unique_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllUniqCount = this.getPrettyNumber(response.data.unique_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getLookLikeProfileAllWithEmailCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/look_a_likes/${this.lookLikeId}/profiles/with_email_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllWithEmailCount = this.getPrettyNumber(response.data.with_email_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async getLookLikeProfileAllWithPhoneCount() {
      this.isProfileCountLoading = true
      axios
        .get(`api/v1/look_a_likes/${this.lookLikeId}/profiles/with_phone_count`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.profileAllWithPhoneCount = this.getPrettyNumber(response.data.with_phone_count)
          } else {
            console.log(response)
            //toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isProfileCountLoading = false
        })
    },
    async createLookLike() {
      this.isLoading = true
      axios
        .post('api/v1/look_a_likes', this.lookLikeData)
          .then((response) => {
            if (response.status === 201 && response.data) {
              this.getLookLikes()
              toast.success('Look-alike создан')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async updateLookLike() {
      this.isLoading = true
      axios
        .post(`api/v1/look_a_likes/${this.lookLikeId}`, this.lookLikeData)
        .then((response) => {
          if (response.status === 200 && response.data.look_a_like) {
            /*this.lookLikeName = response.data.look_a_like.name
            this.lookLikeSegmentId = response.data.look_a_like.cdp_segment_id
            this.lookLikeCompanyId = response.data.look_a_like.company_id
            this.lookLikeAccuracy = response.data.look_a_like.precision
            this.lookLikeState = response.data.look_a_like.state*/
            this.getLookLikes()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteLookLike(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        let lookLikeId = ''
        if (typeof payload == 'object' || payload === undefined) {
          lookLikeId = parseInt(this.lookLikeId)
        } else {
          lookLikeId = parseInt(payload)
        }
        axios
          .delete(`api/v1/look_a_likes/${lookLikeId}`)
          .then((response) => {
            if (response.status === 200 && response.data.look_a_like) {
              this.getLookLikes()
              toast.success('Look-alike успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getExportFiles(currentPerPage) {
      let perPage = currentPerPage || this.exportPerPage
      this.isExportsLoading = true
      axios
        .get('/api/v1/exports', { params: { page: this.exportCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.exportFiles = response.data.data
            this.exportCurrentPage = response.data.current_page
            this.exportLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isExportsLoading = false
        })
    },
    async getMyCompanyExportFiles(currentPerPage) {
      let perPage = currentPerPage || this.exportPerPage
      this.isExportsLoading = true
      axios
        .get('/api/v1/my_company/exports', { params: { page: this.exportCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.exportFiles = response.data.data
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isExportsLoading = false
        })
    },
    async getExportUrlFile(payload) {
      this.isLoading = true
      axios({
        method: 'GET',
        url: `/api/v1/exports/${payload}/download`,
        responseType: 'blob'
      }).then((response) => {
          if (response.status === 200 && response.data) {
            let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            let linkUrl = document.createElement('a')
            linkUrl.href = fileUrl
            linkUrl.setAttribute('download', 'export.csv')
            document.body.appendChild(linkUrl)
            setTimeout(()=>{
              linkUrl.click()
              linkUrl.remove()
            }, 500)
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyExportUrlFile(payload) {
      this.isLoading = true
      axios
        .get(`/api/v1/my_company/exports/${payload}/download`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            console.log(response)
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createExportFile() {
      this.isLoading = true
      axios
        .post('/api/v1/exports', this.exportData)
          .then((response) => {
            if (response.status === 201 && response.data) {
              this.getExportFiles()
              toast.success('Экспорт создан')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async createMyCompanyExportFile() {
      this.isLoading = true
      axios
        .post('/api/v1/my_company/exports', this.exportData)
          .then((response) => {
            if (response.status === 201 && response.data) {
              this.getMyCompanyExportFiles()
              toast.success('Экспорт создан')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              console.log(error.response)
              let textError = ''
              for(let key in error.response.data){
                textError += (key + ': ')
                textError += error.response.data[key] + '\n\n'
              }
              toast.error(textError)
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
    },
    async deleteExportFile(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        axios
          .delete(`/api/v1/exports/${payload}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              this.getExportFiles()
              toast.success('Экспорт успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async deleteMyCompanyExportFile(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        axios
          .delete(`/api/v1/my_company/exports/${payload}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              this.getMyCompanyExportFiles()
              toast.success('Экспорт успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    }
  }
})